<admin-header></admin-header>
<body  *ngIf="ready" style="padding-top:5px;background-color:white; height:100%; width:100%; margin:0px; padding: 0px 0% 0px 0%;">
  <form method="post">
    <div  class="doNotPrint" style="margin-top:4px;background-color:whitesmoke;position:fixed;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a title="Mock Response" class="intLink" type="button" valign="middle" (click)="searchResponses('Response');" style="font-family:Helvetica,AppleGothic,sans-serif; width:80px;color:navy;background-color:#ffffff00;padding:5px 10px 5px 5px;font-weight:500;font-size:1em;height:22px;border: 2px solid ffffff00;-moz-border-radius: 30px;border-radius: 30px;"><i class="fa fa-edit"></i>Mock Response</a>
        <a title="Mock Result" class="intLink" type="button" valign="middle" (click)="searchResults('Result');" style="font-family:Helvetica,AppleGothic,sans-serif; width:80px;color:navy;background-color:#ffffff00;padding:5px 10px 5px 5px;font-weight:500;font-size:1em;height:22px;border: 2px solid grey;-moz-border-radius: 30px;border-radius: 30px;"><i class="fa fa-star-o"></i>Mock Score</a>
      </div>
    </div>

    <div *ngIf="!showAuto" style="width:100%;overflow:hidden;margin-top:50px; padding-top:60px;" class="doNotPrint">
      <div *ngIf="!showAuto" style="color:#617CB5;padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; color: navy; height:23px;text-align:center;" >
        <div *ngIf="topics && topics.length > 0" style="color:#617CB5;display: inline-block;">
          SCOREs({{totalCount | number:'1.0-0'}}+)
        </div>
        <div  *ngIf="topics === undefined" style="display: inline-block; text-align:center;">
          Working...
        </div>
        <div  *ngIf="topics && topics.length === 0" style="display: inline-block;color:orangered;">
          No SCOREs
        </div>
      </div>
    </div>

    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://isloq.com" >&nbsp;<img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a>&nbsp;<span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span></a></div>

    <h3 style="vertical-align: middle;padding:10px 0px 10px 5px;margin:0 auto;width:90%;text-align:center;">
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;color:#617CB5;font-size:.9em; font-weight:500;" >COMPLETED MOCKS</div>
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;color:#617CB5; font-size:.8em; font-weight:200;" >({{curbemail(theUser)}})</div>
    </h3>
    <br>
<admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>


<div id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">


  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
      <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
    </div>
      <div style="display: inline-block;font-weight:600;color:#617CB5;">
      Page {{currentPage}} of {{pageCount | number:0}}
    </div>
    <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
      <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
    </div>
  </div>

    <div *ngIf="(selectedTopics && selectedTopics.length > 0)" class="container2 siloqfont" style="margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;width:90%;">
      <div *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">
        <div *ngIf="topic.show">
          <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px;width:90%;margin:0 auto;">
            <div style="background-color:whitesmoke;width:100%;min-height:20px;height:auto;margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;">
              <div style="display:inline-block;float:right;width:100%;">
                <div style="display:table-cell; text-align:left;width:90%;">
                  <span contenteditable="false" style="float:left;display:table-cell;height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="View Topic {{topic.hashTag}}" (click)='docTopic(topic)'[innerHTML]="parseTitle(topic.hashTag) | truncate : 128 | safeHtml" ></span>
                </div>
                <div style="display:table-cell;width:10%;text-align:right; float:right;">
                  <input class="intLink" type="button" value="❐" valign="top" (click)='docTopic(topic)' title="View Topic {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 40:wpx 0px;font-weight:300;font-size:.75em;"/>
                </div>
              </div>
            </div>
            <div (click)='docTopic(topic)' style="border-bottom:1px solid lightgrey;text-align:left;display:inline-block;width:100%;cursor:pointer;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; valign: top; font-size:.85em;font-weight:400;color:black"><b>{{topic.value.split('/')[0]}}</b>/{{topic.value.split('/')[1]}}</div>

            <div style="vertical-align:top;margin:0px;text-align:left;padding:5px;" >
              <div style="display:inline-block;width:100%;">
                <span class="doNotPrint" (click)='docTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.users[0])}}</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
      <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
    </div>
      <div style="display: inline-block;font-weight:600;color:#617CB5;">
      Page {{currentPage}} of {{pageCount | number:0}}
    </div>
    <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
      <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
    </div>
  </div>
  <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://sisloq.com"  style="color:dodgerblue;" >&nbsp;Powered By&nbsp;<img src="/assets/sisloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:orangered;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;"></span></a> </div>
    <br>
    <br>
    <br>
    <br>
    <admin-footer *ngIf="ready"></admin-footer>
