<admin-header></admin-header>
<body *ngIf="ready" style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:53px;display:table;width:100%; height:48px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      <div  style="vertical-align: middle;padding:0px;margin:0px;width:5%;display:table-cell;">
      </div>
      <div *ngIf= "ready && topic.users" style="display:table-cell;text-align:center;margin:0 auto;color:mediumseagreen;padding:0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a [style.backgroundColor]="backgroundColor1"  (mouseover)="backgroundColor1 = 'orange'" (mouseout)="backgroundColor1 = 'gold'" *ngIf="theUser === topic.email && topic.users.indexOf('finalized.imoqr@siloq.com') >= 0" title="Finalize this Answers"  style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="branchTopic('finalized');" class="siloqbutton">Finalize Answers</a>
        <a [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'gold'"  *ngIf="theUser === topic.email && topic.users.indexOf('conduct.imoqr@siloq.com') >= 0" title="Publish Mock"  style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="mockExam();" class="siloqbutton">Publish Mock</a>
        <a [style.backgroundColor]="backgroundColor3"  (mouseover)="backgroundColor3 = 'orange'" (mouseout)="backgroundColor3 = 'gold'"  *ngIf="theUser === topic.email && topic.users && topic.users.indexOf('response.imoqr@siloq.com') >= 0 && nameCount>=totalCount" title="Submit your answer(s)'{{topic.hashTag}}'"  style="font-family:Helvetica,AppleGothic,sans-serif;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="branchTopic('response');"  class="siloqbutton">Submit Response</a>
        <a [style.backgroundColor]="backgroundColor4"  (mouseover)="backgroundColor4 = 'orange'" (mouseout)="backgroundColor4 = 'gold'"  *ngIf="theUser === topic.email && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') >= 0" title="Submit your answer(s)'{{topic.hashTag}}'"  style="font-family:Helvetica,AppleGothic,sans-serif;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="hold('response');"  class="siloqbutton">Exam Hold</a>
        <a [style.backgroundColor]="backgroundColor5"  (mouseover)="backgroundColor5 = 'orange'" (mouseout)="backgroundColor5 = 'gold'"  *ngIf="theUser === topic.email && topic.users && topic.users.indexOf('result.imoqr@siloq.com') >= 0" title="Submit your answer(s)'{{topic.hashTag}}'"  style="font-family:Helvetica,AppleGothic,sans-serif;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="results('response');"  class="siloqbutton">Mock Result</a>
        <a [style.backgroundColor]="backgroundColor6"  (mouseover)="backgroundColor6 = 'orange'" (mouseout)="backgroundColor6 = 'gold'"  *ngIf="theUser !== topic.email && topic.users && topic.users.indexOf('result.imoqr@siloq.com') >= 0" title="Submit your answer(s)'{{topic.hashTag}}'"  style="font-family:Helvetica,AppleGothic,sans-serif;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" (click)="scores('response');"  class="siloqbutton">Exam Result</a>
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:5%;margin:0px;text-align:right;padding:0px;font-size:1em;font-weight:200;">
        </div>
    </div>
    <div *ngIf="topic.users && (topic.users.indexOf('response.imoqr@siloq.com') > 0 || topic.users.indexOf('result.imoqr@siloq.com') > 0)" class="doNotPrint" style="font-family:Helvetica,AppleGothic,sans-serif;background-color:#eeeeee;position:fixed;margin-top:101px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      <div *ngIf="topic.users && topic.users.indexOf('result.imoqr@siloq.com') > 0" style="padding-top:10px;text-align:center;color:dodgerblue;width:90%;min-width:60px;font-weight:600;margin:0 auto;"><b>{{topic.value.split('/')[0]}}</b>/{{topic.value.split('/')[1]}}&nbsp;&nbsp;&nbsp;</div>
      <div *ngIf="topic.users && topic.users.indexOf('response.imoqr@siloq.com') > 0" title="Pause and Save" (click)="pauseResponse();" style="padding:12px 0px 12px 0px;text-align:center;color:orangered;width:90%;min-width:60px;font-weight:600;margin:0 auto;"><span style="background-color:#444444;color:gold;padding:6px 8px 6px 8px;font-weight:600;border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #888888;cursor:pointer;">{{timer}}</span></div>
    </div>

    <div *ngIf="topic.users && !(topic.users.indexOf('response.imoqr@siloq.com') > 0 || topic.users.indexOf('result.imoqr@siloq.com') > 0)" class="doNotPrint" style="font-family:Helvetica,AppleGothic,sans-serif;position:fixed;background-color:#ffffff;margin-top:101px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;text-align:center;">
      <div class="block_container" style="border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
        style="color:dodgerblue;">i</span><span
        style="color:mediumseagreen;">s</span><span
        style="color:orangered;">l</span><span
        style="color:orange;">o</span><span
        style="color:dodgerblue;">q</span><span
        class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Mock Instantly Runtime!</span> </a>
      </div>
    </div>

    <div  class="doNotPrint" style="padding:148px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">{{taxonomy}}</div>
    <admin-alert></admin-alert>
       <ng-template #modalTemplate>
    </ng-template>
    <div *ngIf="!model.hashTag" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 2% 0px 2%;padding-top:10px;z-index:-1px;">
      <h3 style="text-align:center;">No Item Available</h3>
    </div>
      <div  *ngIf="topic.users" id="doc" style="text-align:center;font-family:Helvetica,AppleGothic,sans-serif;width:96%;overflow:hidden;margin:4px 2% 40px 2%;padding-top:10px;z-index:-1px;">
      <!-- TOTO:
       (dblclick)="previous();" (click)="next();"
      <h2  *ngIf="topic.hashTag.indexOf('_exam_result') > 0 || topic.hashTag.indexOf('_mock_result') > 0" style="text-align:center;color:dodgerblue">{{parseFloat64(topic.value) | number : '1.0-0'}}%</h2>
      <h2  *ngIf="topic.hashTag.indexOf('_exam_response') > 0 || topic.hashTag.indexOf('_mock_response') > 0" style="text-align:center;color:orangered">{{timer}}</h2>
      -->
        <h2  *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0" style="text-align:center;color:navy">MOCK EXAM HALL</h2>
        <h3  *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0" style="text-align:center;color:mediumseagreen">Please start whenever you're ready!</h3>
        <div *ngIf="topic.hashTag" style="min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;font-size:1.2em;font-weight:600;color:#617CB5;">{{model.hashTag}}</div>
        <div *ngIf="topic.email" style="font-family: Arial, Helvetica, sans-serif;font-size;1em;font-weight:200;text-align:center;color:#617CB5;">(<b>{{topic.users[1].split(".")[0]}}: </b><i>{{curbemail(topic.email)}})</i></div>
        <br>
        <div *ngIf="topic.users.indexOf('conduct.imoqr@siloq.com') >= 0" style="text-align:left;min-width:300px;width:100%;margin:0 auto;">
            <div class="container2" style="padding:0px;margin-left:5%;overflow:auto;width:90%;height:100%;">
            <div  style="color:dodgerblue">Number Of Questions <span style="color:dark;"> <br>{{nameCount}}</span></div>
            <div  *ngIf="!editable" style="color:dodgerblue">Number Of Random Questions <span style="color:dark;"> <br>{{random}}</span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Time Duration in Minutes<span style="color:dark;"> <br>{{timeDuration}}</span></div>
            <div  *ngIf="taxonomy==='iexamr' && !editable" style="pointer-events:none;user-select:none;color:navy">Start Time<span style="color:dark;"> <br>{{startTime}} </span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Page Size<span style="color:dark;"> <br>{{pageSize}}</span></div>
            <div  *ngIf="!editable" style="color:dodgerblue">Right Question Reward<span style="color:dark;"> <br>{{rightQuestionReward}}</span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Wrong Question Penalty<span style="color:dark;"> <br>{{wrongQuestionPenalty}}</span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Audio Enabled<span style="color:dark;"> <br>{{audioEnabled}} </span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">Multi Column Enabled<span style="color:dark;"> <br>{{multiColEnabled}} </span></div>
            <div  *ngIf="!editable" style="pointer-events:none;user-select:none;color:navy">GoldiMembers Enabled<span style="color:dark;"> <br>{{GoldimembersEnabled}}</span></div>
            <div  *ngIf="editable" style="color:navy">Number Of Random Questions <br><input class="siloqinput" type="text" title="#Random Questions" [(ngModel)]='random' name="random" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Time Duration in Minutes<br><input class="siloqinput" type="text" title="Time Duration" [(ngModel)]='timeDuration' name="timeDuration" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="taxonomy==='iexamr' && editable" style="color:navy">Start Time <br><input class="siloqinput" type="text" title="Start Time" [(ngModel)]='startTime' name="startTime" style="color:dark;vertical-align: middle;height:20px; width:150px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Page Size <br><input class="siloqinput" type="text" title="Page Size" [(ngModel)]='pageSize' name="pageSize" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Right Question Reward <br><input class="siloqinput" type="text" title="Right Question Reward" [(ngModel)]='rightQuestionReward' name="startTime" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Wrong Question Penalty<br><input class="siloqinput" type="text" title="Wrong Question Penalty" [(ngModel)]='wrongQuestionPenalty' name="pageSize" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Audio Enabled<br><input class="siloqinput" type="checkbox" title="Audio Enabled" [(ngModel)]='audioEnabled' name="audioEnabled" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;margin-left:-15px;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">Multi Column Enabled<br><input class="siloqinput" type="checkbox" title="Multi Column Enabled" [(ngModel)]='multiColEnabled' name="multiColEnabled" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;margin-left:-15px;"  valign="middle"/> </div>
            <div  *ngIf="editable" style="color:navy">GoldiMembers Only<br><input class="siloqinput" type="checkbox" title="GoldiMembers Enabled" [(ngModel)]='goldimembersEnabled' name="goldimembers" style="color:dark;vertical-align: middle;height:20px; width:50px;font-weight:400;margin-left:-15px;"  valign="middle"/> </div>
          </div>
        </div>
      <div  *ngIf="topic.users.indexOf('result.imoqr@siloq.com') >= 0" class="container3 siloqfont">
        <div style="border: 3px solid dodgerblue; ">Expected</div>
        <div style="border: 3px solid orangered;">Provided</div>
        <div style="border: 3px solid mediumseagreen;">SpotOn</div>
        <div style="border: 3px solid orange;">Revisit</div>
      </div>
      <div  *ngIf="topic.users.indexOf('response.imoqr@siloq.com') >= 0" style="width:100%; text-align:center;padding-bottom:0px;">
        <br>
        <div style="background-color:#999999;color:gold;padding:6px 8px 6px 8px;font-weight:400;border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #cccccc;width:75%;margin:0 auto;">Click the Timer to Pause/Save</div>
        <div class="fa" style="font-family:Helvetica,AppleGothic,sans-serif;font-size:1em;font-weight:400;text-align:center; border: 4px solid orange;width:75%;">Click Question To Revisit</div>
        <div *ngIf="audioEnabled" style="width:100%;text-align:center;margin:0 auto;padding-top:10px;">
          <!--
          <a (click)="stop();" style="font-size:1em;padding-top:5px;" class="fa fa-stop"></a>
          -->
          <a (click)="fastBackward();" style="font-size:1em;padding-top:5px;" class="fa fa-fast-backward"></a>
          <a (click)="backward();" style="font-size:1em;padding-top:5px;" class="fa fa-backward"></a>
          <a *ngIf="!playing" (click)="play();" style="font-size:1em;padding-top:5px;" class="fa fa-play"></a>
          <a *ngIf="playing" (click)="pause();" style="font-size:1em;padding-top:5px;" class="fa fa-pause"></a>
          <a (click)="forward();" style="font-size:1em;padding-top:5px;" class="fa fa-forward"></a>
          <a (click)="fastForward();" style="font-size:1em;padding-top:5px;" class="fa fa-fast-forward"></a>
        </div>
        <div *ngIf="!audioEnabled" style="font-family:Helvetica,AppleGothic,sans-serif;font-size:1em;font-weight:400;width:75%;text-align:center;margin:0 auto; border: 3px solid dodgerblue;border-radius: 50px; -moz-border-radius: 50px;padding:10px;">Click to select/deselect your response</div>
      </div>
        <div  *ngIf="topic.users.indexOf('finalized.imoqr@siloq.com') >= 0" style="width:100%; text-align:center;padding-bottom:0px;">
          <div class="fa" style="font-family:Helvetica,AppleGothic,sans-serif;font-size:1em;font-weight:400;text-align:center; border: 4px solid orange;width:75%;">Click Question To Revisit</div>
          <div style="font-family:Helvetica,AppleGothic,sans-serif;font-size:1em;font-weight:400;width:75%;text-align:center;margin:0 auto; border: 3px solid dodgerblue;border-radius: 50px; -moz-border-radius: 50px;padding:10px;">Click to select/deselect your answer</div>
        </div>
      <br class="doNotPrint">

        <div *ngIf="subtitles && subtitles.length > 0" style="width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
          <div style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; color: navy; height:23px;text-align:center;" >
            <div *ngIf="subtitles && subtitles.length > 0" style="display: inline-block;">
              LOQs({{totalCount | number:'1.0-0'}}+)
            </div>
            <div  *ngIf="subtitles === undefined" style="display: inline-block; text-align:center;">
              Working...
            </div>
            <div  *ngIf="subtitles && subtitles.length === 0" style="display: inline-block;">
              No LOQ(s)
            </div>
          </div>
        </div>
        <br lass="doNotPrint">

          <div  *ngIf="!(topic.users && topic.users.indexOf('conduct.imoqr@siloq.com') >= 0) && subtitles && subtitles.length" class="col-md-12 page">
          <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
            <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
            <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
          </div>
          <div style="display: inline-block;font-weight:600;">
            Page <input type="text"  class="siloqinput" style="font-weight:300;font-size:1em;text-align:center;width:30px;height:20px;" name="currPage"  [(ngModel)]='currentPage' (blur)="goto();"/> of {{pageCount | number:0}}
          </div>
          <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
            <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
            <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
          </div>
        </div>

      <br class="doNotPrint">
        <div class="qcontainer" *ngIf="multiColEnabled && !(topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0)" style="overflow:hidden;min-width:300px; text-align:center; margin:0 auto;padding:1%; ">
          <div  class="siloqbox qb qbox" *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i"  [class.gap]="i > 0" style="padding:1%;">
            <div  *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:100%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 0px; height:20px;text-align:left;">
              <div *ngIf="(topic.users.indexOf('finalized.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0)" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top; text-align:left;margin:0px; ">
                <div *ngIf="j === 0" class="Row" style="padding:10px;border-bottom:0px solid grey; display: inline-block; grid-template-columns: max-content auto;">
                  <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;display: table-cell;padding-top:6px;font-weight:600;font-size:14px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}]&nbsp;</span>
                  <span *ngIf="slides[selectedSlide][j+1].tags" style="display: table-cell;">
                      <span class="fa" *ngIf="slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align:left; width: 100%;display:table-cell; border: 4px solid orange; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                    </span>
                  <span *ngIf="!slides[selectedSlide][j+1].tags" style="display: table-cell;">
                      <span class="fa" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                    </span>
                </div>
                <hr *ngIf="j === 0" />
                <div *ngIf="audioEnabled && j > 0" class="Row" style="display: inline-block;width:96%;padding:0px 2% 0px 2%;">
                  <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                  <span class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></span>
                  <!--                    <span (click)="speak(tag.value);" style="display:table-cell;color:mediumseagreen;font-size:1em;padding-top:5px;" class="fa fa-volume-up"></span>-->
                </div>
                <div *ngIf= "!audioEnabled && j > 0" class="Row" style="width:96%;padding:0px 2% 0px 2%;display: inline-block;">
                  <!--TODO: If teacher doesn't answer-->
                  <span *ngIf="topic.users.indexOf('result.imoqr@siloq.com') >= 0">
                      <span style="display:table-cell;padding:12px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.ping && !tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="!tag.ping && tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid orangered;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="tag.ping && tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid mediumseagreen;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="!tag.ping && !tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                    </span>

                  <span *ngIf="topic.users.indexOf('finalized.imoqr@siloq.com') >= 0">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.ping" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (click)="addAnswer($event, tag)"></span>
                      <span *ngIf="!tag.ping" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"  (click)="addAnswer($event, tag)"></span>
                    </span>

                  <span *ngIf="topic.users.indexOf('response.imoqr@siloq.com') >= 0">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (click)="addAnswer($event, tag)"></span>
                      <span *ngIf="!tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"  (click)="addAnswer($event, tag)"></span>
                    </span>

                </div>
              </div>

              <div *ngIf="!((topic.users.indexOf('finalized.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0))" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top; text-align:left;margin:0px; ">
                <div *ngIf="j === 0" class="Row" style="padding:10px;border-bottom:0px solid grey; display: inline-block; grid-template-columns: max-content auto;">
                  <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;display: table-cell;padding-top:6px;font-weight:600;font-size:14px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}]&nbsp;</span>
                  <span class="fa" style=" text-align:left; width: 100%;display:table-cell; border: 1px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                </div>
                <hr *ngIf="j === 0" />
                <div *ngIf= "j > 0" class="Row" style="width:96%;padding:0px 2% 0px 2%;display: inline-block;">
                  <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                  <span class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        -->
        <div class="qcontainer1" *ngIf="!multiColEnabled && !(topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 || topic.users.indexOf('request.imoqr@siloq.com') >= 0)" style="overflow:hidden;min-width:300px; text-align:center; margin:0 auto;padding:1%; ">
          <div  class="siloqbox qb qbox" *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i"  [class.gap]="i > 0" style="padding:1%;">
            <div  *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:100%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 0px; height:20px;text-align:left;">
                <div *ngIf="(topic.users.indexOf('finalized.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0)" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top; text-align:left;margin:0px; ">
                  <div *ngIf="j === 0" class="Row" style="padding:10px;border-bottom:0px solid grey; display: inline-block; grid-template-columns: max-content auto;">
                    <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;display: table-cell;padding-top:6px;font-weight:600;font-size:14px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}]&nbsp;</span>
                    <span *ngIf="slides[selectedSlide][j+1].tags" style="display: table-cell;">
                      <span class="fa" *ngIf="slides[selectedSlide][j+1].tags.indexOf('self-note') < 0" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                      <span class="fa" *ngIf="slides[selectedSlide][j+1].tags.indexOf('self-note') >= 0" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align:left; width: 100%;display:table-cell; border: 4px solid orange; padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                    </span>
                    <span *ngIf="!slides[selectedSlide][j+1].tags" style="display: table-cell;">
                      <span class="fa" style="display: table-cell;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;text-align:left; width: 100%;display:table-cell; border: 4px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml" (click)="selfNote($event, tag)"></span>
                    </span>
                  </div>
                  <hr *ngIf="j === 0" />
                  <div *ngIf="audioEnabled && j > 0" class="Row" style="display: inline-block;width:96%;padding:0px 2% 0px 2%;">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                    <span class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></span>
<!--                    <span (click)="speak(tag.value);" style="display:table-cell;color:mediumseagreen;font-size:1em;padding-top:5px;" class="fa fa-volume-up"></span>-->
                  </div>
                  <div *ngIf= "!audioEnabled && j > 0" class="Row" style="width:96%;padding:0px 2% 0px 2%;display: inline-block;">
                    <!--TODO: If teacher doesn't answer-->
                    <span *ngIf="topic.users.indexOf('result.imoqr@siloq.com') >= 0">
                      <span style="display:table-cell;padding:12px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.ping && !tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="!tag.ping && tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid orangered;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="tag.ping && tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid mediumseagreen;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                      <span *ngIf="!tag.ping && !tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></span>
                    </span>

                    <span *ngIf="topic.users.indexOf('finalized.imoqr@siloq.com') >= 0">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.ping" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (click)="addAnswer($event, tag)"></span>
                      <span *ngIf="!tag.ping" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"  (click)="addAnswer($event, tag)"></span>
                    </span>

                    <span *ngIf="topic.users.indexOf('response.imoqr@siloq.com') >= 0">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;float:left;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                      <span *ngIf="tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid dodgerblue;cursor:pointer;display:table-cell;padding:10px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (click)="addAnswer($event, tag)"></span>
                      <span *ngIf="!tag.pong" class="editTag" tabindex="{{i+j+1}}"  style="border-radius: 50px; -moz-border-radius: 50px;border: 3px solid #ffffff00;cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"  (click)="addAnswer($event, tag)"></span>
                    </span>

                  </div>
                </div>

                <div *ngIf="!((topic.users.indexOf('finalized.imoqr@siloq.com') >= 0 || topic.users.indexOf('response.imoqr@siloq.com') >= 0 || topic.users.indexOf('result.imoqr@siloq.com') >= 0))" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top; text-align:left;margin:0px; ">
                  <div *ngIf="j === 0" class="Row" style="padding:10px;border-bottom:0px solid grey; display: inline-block; grid-template-columns: max-content auto;">
                    <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;display: table-cell;padding-top:6px;font-weight:600;font-size:14px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}]&nbsp;</span>
                    <span class="fa" style=" text-align:left; width: 100%;display:table-cell; border: 1px solid #ffffff00;padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></span>
                  </div>
                  <hr *ngIf="j === 0" />
                  <div *ngIf= "j > 0" class="Row" style="width:96%;padding:0px 2% 0px 2%;display: inline-block;">
                    <span style="display:table-cell;padding:6px 0px 0px 3px;font-weight:500;font-size:12px;">[{{(this.currentPage - 1) * this.pageSize+i+1}}.{{j}}]&nbsp;</span>
                    <span class="editTag" tabindex="{{i+j+1}}"  style="cursor:pointer;display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml" (ngModelChange)="tag.value = $event"></span>
                  </div>
                </div>
            </div>
        </div>
      </div>
        <br>

        <div  *ngIf="!(topic.users && topic.users.indexOf('conduct.imoqr@siloq.com') >= 0) && subtitles && subtitles.length" class="col-md-12 page">
          <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
            <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
            <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
          </div>
          <div style="display: inline-block;font-weight:600;">
            Page <input type="text"  class="siloqinput" style="font-weight:300;font-size:1em;text-align:center;width:30px;height:20px;" name="currPage"  [(ngModel)]='currentPage' (blur)="goto();"/> of {{pageCount | number:0}}
          </div>
          <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
            <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
            <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
          </div>
        </div>

        <br>
    </div>
<loq-footer *ngIf="ready" ></loq-footer>
