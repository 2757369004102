<header></header>
<div  style="text-align:center; width:100%; padding-top:70px;">

  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">GoldiAngels</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
  </div>
  <br>
  <alert></alert>
<form>
<div class="container2" style="width:90%;text-align:center;margin:0 auto;">
   <div class="small-12  columns" style="text-align:center;">
         <div style="font-family:sans-serif;font-size:16px;font-weight:400; color: dodgerblue;">
           New members, aim GoldiAngels
         </div>
     <br>
         <div style="font-family:sans-serif;font-size:14px;font-weight:500; color: mediumseagreen;">
            In addition to free mocks,
         </div>
     <br>
     <div style="font-family:sans-serif;font-size:12px;font-weight:600;color:#617CB5;">
       Access GoldiMembers mocks, award-winning exams, exclusive deals, and more
     </div>
     <br>
     <div style="font-family:sans-serif;font-size:16px;font-weight:500; color: navy;padding-bottom:6px;">
       Earn Return on GoldiInvestment!
     </div>
     <br>
     <div style="font-family:sans-serif;font-size:14px;font-weight:200; color: navy;">
       Be Bold, Strong and Steady. GoldiInvest  <b>${{goldiAngelsPremiumInvestment}}</b>. Earn ROI in 2-5 Years
     </div>
   </div>
</div>
  <br>
<div class="small-12  columns" style="text-align:left;">
    <div style="width:300px;text-align:center;margin:auto;" class="siloqfont">
      <button [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" (click)="signin()" type="button" class="siloqbutton" style="width:256px;background-color:orange;color:#383838;">Start your perfect investment</button>
    </div>
  </div>
</form>
</div>
<footer></footer>

