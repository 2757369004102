<admin-header></admin-header>
<body style="height:100%;width:100%; margin:0px;">
  <div style="text-align:center; width:100%; padding-top:5%;min-height: calc(100vh - 70px);">
    <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" >New</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
      <br>
      <br>
    <h3 class="block_container siloqlogofont" >Password</h3>
  </div>
    <br>
    <admin-alert></admin-alert>
    <form>
      <div class="row align-center">
        <div class="small-12 columns">
            <input style="margin-left:20px;" [type]="passwordType" autocomlete="new-password" type="password" placeholder="Enter new password"  class="slqinput"  [(ngModel)]="passwd">&nbsp;<a (click)="togglePasswordVisibility()" class="fa fa-eye" style="cursor:pointer;color: orangered;">&nbsp;</a>
        </div>
        <br>
        <div class="small-12  columns">
            <input autocomlete="new-password" type="password" placeholder="Confirm new password"  class="slqinput"  [(ngModel)]="password2">
        </div>
        <br>
        <br>
        <div class="small-12  columns">
          <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
            <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  (click)='doreset($event)' class="slqok">Reset</a>
            &nbsp;<a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  (click)='done()' class="slqcancel">Cancel</a>
            <br>
          </div>
        </div>
      </div>
    </form>
  </div>
  <br>
  <br>
<dummy-footer></dummy-footer>
