<div style="height:38px;width:100%;background-color:#ffffff00;" >
  <div style="left:0; top:0; position:fixed;width:100%;background-color:#ffffff00;height:38px;margin:0px;">

    <div (mouseenter)="menu=false;" style="height:1px;width:100%;background-color:whitesmoke;">
    </div>
    <div style="background-color:whitesmoke;font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif; display:table-cell;font-size:1em;font-weight:600px;height:38px; width:100%; padding:0px 5px 0px 5px; display:table;text-align:left;margin:0 auto;" >


      <div  style="display:table;text-align:center;padding:10px 1% 0px 0px;width:92%;font-size:1em;font-weight:600;margin:0 auto;" >

        <a (click)="home()" ><img src="assets/admin-GoldiMocks-gold.png" align="middle" title="HOME" alt="HOME" style="cursor:pointer;margin-bottom:11px;width:28px; height:28px;" /></a>
        <a (click)="menu=!menu;resetTimer();$event.stopPropagation();" ><img src="assets/sislr.png" align="middle" title="HOME" alt="HOME" style="cursor:pointer;margin-bottom:11px;width:28px; height:28px;" /></a>
        &nbsp;
        <a *ngIf="userSVC.authUser.email" href="/admin/topic?sislr=isloq"><img src="assets/isloq.png" align="middle" title="LOQ" alt="LOQ"  style="cursor:pointer;margin-bottom:12px;width:30px; height:30px;" /></a>
        <a  *ngIf="!userSVC.authUser.email" href="/profile"><img src="assets/isloq-dark.png" align="middle" title="LOQ" alt="LOQ"  style="cursor:pointer;margin-bottom:12px;width:30px; height:30px;" /></a>
        <!-- transform -->
        <a  *ngIf="userSVC.authUser.email" href="/admin/exams?sislr=sisloq"><img src="assets/sisloq-logo.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
        <a  *ngIf="!userSVC.authUser.email" href="/admin/exams?sislr=sisloq"><img src="assets/sisloq-logo-dark.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
        <!-- retrieve -->
        <a  *ngIf="userSVC.authUser.email" href="/admin/mocks?sislr=siloq"><img src="assets/siloq-logo.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
        <a  *ngIf="!userSVC.authUser.email" href="/admin/mocks?sislr=siloq"><img src="assets/siloq-logo-dark.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
        &nbsp;
        <span style="position:relative;width:28px; height:28px;cursor:pointer;">
          <img src="../../assets/gmz-gold-userX.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="goldimembers();">
          <a style="position:absolute;left:33%; top:12%;color:orangered;font-size:10px;font-weight:500;"  (click)="goldimembers();"></a>
        </span>

          <span *ngIf="userSVC.authUser.email && theGoldiUser && theGoldiUser !== 'null'" style="position:relative;width:28px; height:28px;cursor:pointer;">
            <img src="../../assets/gmz5.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="account();">
            <a style="position:absolute;left:39%; top:12%;color:orange;font-size:10px;font-weight:500;" (click)="account();">{{theUserInitials}}</a>
          </span>

        <span *ngIf="userSVC.authUser.email && (!theGoldiUser || theGoldiUser === 'null')" style="position:relative;width:28px; height:28px;cursor:pointer;">
            <img src="../../assets/gmz5-not.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="account();">
            <a style="position:absolute;left:39%; top:12%;color:dodgerbue;font-size:10px;font-weight:500;" (click)="account();">{{theUserInitials}}</a>
        </span>

        <span *ngIf="!userSVC.authUser.email" style="position:relative;width:28px; height:28px;cursor:pointer;">
              <a style="margin-bottom:11px;cursor:pointer;width:28px; height:28px;" (click)="login();"> <img src="assets/gmz-userX-dark.png" align="middle" title="{{taxonomy}}: Sign In" alt="{{taxonomy}}: Sign In" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;"  (click)="login();"/></a>
        </span>

      </div>
    </div>
    <div style="height:3px;padding:0px;margin:0px auto;border:0px;background-color:gold">
    </div>

    <div id="headerMenu" *ngIf="menu"  style="overflow: auto;background-color: #ffffff00; color:whitesmoke; max-height:360px;padding:0px; margin:0px 0px 0px 0px; solid dodgerblue;  text-align:left;width:100%;" >
      <div style="padding:0px;margin:0px;width:20px;background-color:#fffff00;display:table-cell;" (mouseenter)="menu=false;">
      </div>
      <div style="text-align:center;width:100%;padding:0px;margin:0px;">
        <ul class="container2" style="padding:0px;margin:0px;border:0px;overflow:auto;width:100%;height:100%;" (click)='toggleMenu($event);$event.stopPropagation();'>
          <li>
            <a href="https://chromewebstore.google.com/detail/{{taxonomy}}/{{chromeExtension}}" class="siloqbutton" target="_blank">Extension</a>
          </li>

          <li>
            <a [routerLink]="['/publish']" [queryParams]="{h:'siloq-apps'}"  class="siloqbutton" >Apps</a>
          </li>

          <li>
            <a (click)="goldimembers()" class="siloqbutton" >Pricing</a>
          </li>

          <li>
            <a (click)="goldiAngels()" class="siloqbutton" >Invest</a>
          </li>

          <li>
            <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-news'}" class="siloqbutton" >News</a>
          </li>

          <li>
            <a [routerLink]="['/careers']" class="siloqbutton" >Careers</a>
          </li>

          <li>
            <a href="https://siloq.com" class="siloqbutton" target="_blank">Siloq®</a>
          </li>

          <li>
            <a href="https://isloq.com" class="siloqbutton" target="_blank">isloq™</a>
          </li>

          <li>
            <a href="https://sisloq.com" class="siloqbutton" target="_blank">Sisloq™</a>
          </li>

          <li>
            <a href="https://sislr.com" class="siloqbutton" target="_blank">Sislr™</a>
          </li>

          <li>
            <a href="https://goldimocks.com" class="siloqbutton" target="_blank">GoldiMocks™</a>
          </li>

          <li>
            <a href="https://iexamr.siloq.com/" class="siloqbutton" target="_blank">GoldExams</a>
          </li>

          <li>
            <a href="https://siloq.in" class="siloqbutton" target="_blank">Siloq India Private Limited™</a>
          </li>

          <li *ngIf="!userSVC.authUser.email">
            <a title="Please contact us"  class="intLink" [routerLink]="['/contact']" class="siloqbutton">Contact</a>
          </li>

          <li *ngIf="userSVC.authUser.email">
            <a title="Please tell us what you want more better" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s: 'Feedback-Sis-Header'}" class="siloqbutton">Feedback</a>
          </li>

          <li>
            <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-help'}" class="siloqbutton" >Help</a>
          </li>

          <li>
            <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-about'}"  class="siloqbutton" >About</a>
          </li>

          <li>
            <a [routerLink]="['']"  class="siloqbutton">Home</a>
          </li>
        </ul>
      </div>
      <div style="padding:0px;margin:0px;width:20px;background-color:#fffff00;display:table-cell;" (mouseenter)="menu=false;">
      </div>
    </div>
    <div style="width:100%;height:1px;background-color:#fffff00;display:table-cell;" (mouseenter)="menu=false;">
    </div>
  </div>
