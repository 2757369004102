<admin-header></admin-header>
<div  style="text-align:center; width:100%; padding-top:80px;">
  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" > Generate Invoice</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
    <br>
  </div>
  <br>
  <admin-alert></admin-alert>
<form>
  <div class="row align-center">
    <div class="small-12 columns">
      <label>
        <input type="text" placeholder="Enter Invoice Description"  class="siloqinput" style="margin-left:10px;min-width:300px;" [(ngModel)]="desc">
      </label>
    </div>
    <br>
    <div class="small-12  columns">
      <label>
        <b style="color:mediumseagreen;">$</b>&nbsp;<input  placeholder="Enter amount in USD"   class="siloqinput" style="min-width:300px;" [(ngModel)]="price">
      </label>
    </div>
    <br>
  <div class="small-12  columns" style="width:300px;text-align:center;margin:10px  auto;">
    <div style="text-align:center;margin:auto;" class="siloqfont">
      <button (click)="invoice()" type="button" class="siloqbutton" style="width:180px;background-color:whitesmoke;border:4px solid gold;padding:3px 0px 2px 0px;"><div class="ms-text-center" style="width:180px;color:navy; font-weight:600;font-size:1.2em;"><img src="assets/sweat.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo" style="margin-bottom:14px;width:24px; height:24px;" /><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:orange;">o</span><span style="color:dodgerblue;">q</span><span  style="color:#617CB5;">Invoice</span><span class="sup" style="color:orangered;">&reg;</span></div></button>
    </div>
  </div>
    <div class="small-12  columns">
      <div style="width:25%;margin:auto;min-width:300px;text-align:center;" class="siloqfont">
        <a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:160px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
      </div>
    </div>
  </div>
    <br>
    <br>
</form>
</div>
<admin-footer></admin-footer>

