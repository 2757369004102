import { Component, OnInit } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import {ActivatedRoute, Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig, ITransactionItem, NgxPayPalModule } from 'ngx-paypal';
import { config } from '../../config';
import { FormsModule } from '@angular/forms';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import {RouterModule, RouterOutlet} from '@angular/router';
import {UserService} from '../../services/user.service';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {CookieService} from 'ng2-cookies';

@Component({
  selector: 'siloqpay',
  templateUrl: './siloqpay.component.html',
  styleUrls: ['./siloqpay.component.css'],
  imports: [NgxPayPalModule, AdminAlertComponent, FormsModule, RouterModule, AdminHeaderComponent, AdminFooterComponent, RouterModule, RouterOutlet],
  standalone: true
})

export class SiloqpayComponent implements OnInit {
      public payPalConfig?: IPayPalConfig;
      price="";
      title = "siloqpay";
      u = "-";
      t = "-";
      c = "USD";
      a = "4.99";
      l = "30";
      d = "Monthly Subscription";
      p = "gold";
      e = "";
      qrcode = "";
      intent = "CAPTURE";
      siloqpayUrl = "https://pay.siloq.com";
      siloqpayApiUrl = "https://api.pay.siloq.com";
      gmtUrl = "https://api.goldimocks.com";
      callbackUrl = "https://goldimocks.com";
      public taxonomy = "";
      public class = "";
      public subclass = "";
      public backgroundColor  = "orangered";

    constructor(private _cookieService:CookieService, private activatedRoute: ActivatedRoute, private router: Router, private alertService: AdminAlertService, public topicService: TopicService, public userSVC: UserService) {}

    showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

  getCookie(key: string){
    return this._cookieService.get(key);
  }
    ngOnInit(): void {
    this.userSVC.sessionVerify();
    this.e = this.getCookie("email");
    //this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.u = params['u'] || "-";
      this.t = params['t'] || "-";
      this.l = params['l'] || "30";
      this.c = params['c'] || "USD";
      this.a = params['a'] || "4.99";
      this.p = params['p'] || this.p;
      this.d = params['d'] || this.d;
      this.callbackUrl = params['cb'] || this.callbackUrl;
      });
      this.taxonomy = config.taxonomy;
      this.class = config.class;
      this.subclass = config.subclass;
      this.initConfig();
      var t = new Topic("", "", "");
      t.value = window.location.href;
      this.topicService.qrCode(t)
      .subscribe(
        (data: any) => {
          this.qrcode = "data:image/png;base64,"+data;
        },
         (err: any) => {
          console.log(err);
      });
    }

    private initConfig(): void {
        this.payPalConfig = {
            clientId: 'AV1q7cOXuelZBkFHNhaHag9R340aSByqGzquzJE_janPLxxdUl2ZxE7EGh9mOCPM2pe1Ti03skzygTch',
            createOrderOnServer: (data: any) => fetch(this.siloqpayApiUrl+'/order/'+this.intent+'/'+this.p+'/'+this.u+'/'+this.t+'/'+this.c+'/'+this.a+'/'+this.l,)
               .then((res) => res.json())
               .then((order) => order),
            onApprove: (data: any, actions: any) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then((details: any) => {
                fetch(this.siloqpayApiUrl+'/order/complete',
                 {
                    mode: 'no-cors',
                    method: 'get',
                    headers: {
                      "Content-type": "application/json"
                    },
                    body: JSON.stringify({"order_id": data})
                  } )
                  .then((res: any) => res.json());
                        console.log('onApprove - you can get full order details inside onApprove: ', details);
                    });
            },
            onClientAuthorization: (data: any) => {
                fetch(this.gmtUrl+'/gmt/'+data.id+'/'+this.p+'/'+this.u+'/'+this.t+'/'+this.c+'/'+this.a+'/'+this.l+'/'+this.d+'/'+this.e,
                 {
                    //TODO: CORS
                    mode: 'no-cors',
                    method: 'get',
                    headers: {
                      "Content-type": "application/json"
                    },
                  } )
                  .then((res: any) => {
                    if(this.p === "gold") {
                      this.currentUser(()=>{
                        alert("Thank You, and Welcome to Goldimocks™");
                        this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com" }});
                      });
                      } else {
                        alert("Thank You for the payment and we appreciate your business with Goldimocks™");
                        this.router.navigate(['/admin/account']);
                      }
                    });
                   console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            },
            onCancel: (data: any, actions: any) => {
                console.log('OnCancel', data, actions);

            },
            onError:  (err: any) => {
                console.log('OnError', err);
            },
            onClick: (data: any, actions: any) => {
                console.log('onClick', data, actions);
            },
        };
    }

/*
     goldimembers() {
        if(this.theGoldiUser && this.theGoldiUser !== 'null') {
          this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
        } else {
          this.router.navigate(['/admin/goldimembers']);
        }
      }
      */

      currentUser(callback: () => any) {
         this.userSVC.currentUser()
         .subscribe({ next: (session: any) => {
             this.userSVC.authUser.email = session.email;
             this.userSVC.authUser.userId = session.userId;
             this.userSVC.authUser.sessionId = session.sessionId;
             this.userSVC.authUser.goldimembersSessionId = session.goldimembersSessionId;
             //
             this.userSVC.loggedInUser.email = session.email;
             this.userSVC.loggedInUser.userId = session.userId;
             this.userSVC.loggedInUser.sessionId = session.sessionId;
             this.userSVC.loggedInUser.goldimembersSessionId = session.goldimembersSessionId;
             //
           if (this.userSVC.authUser.email && this.userSVC.authUser.sessionId) {
              this.userSVC.setCookie("email", this.userSVC.authUser.email);
              this.userSVC.setCookie("gwt", this.userSVC.authUser.sessionId);
              this.userSVC.setCookie("gmt", this.userSVC.authUser.goldimembersSessionId);
              this.userSVC.setCookie("uid", this.userSVC.authUser.userId);
            }
            callback();
          },
          error: (error: any) => {
           console.log(error);
          }
        });
    }

    refer() {
        this.copyTinyUrlToClipboard();
        this.showAlert(AlertTypeEnum['success'], this.taxonomy+" referral URL is copied to the clipboard, Paste anywhere!");
        return;
    }

    public copyTinyUrlToClipboard() {
      var dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.value = window.location.href;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }

  done() {
    this.router.navigate([this.callbackUrl]);
  }

}
