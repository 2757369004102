import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../user/model/user';
import {Login} from '../../services/user.service';
import { Router } from '@angular/router';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { SiloqpayComponent } from '../siloqpay/siloqpay.component';
import { FormsModule } from '@angular/forms';
import {CookieService} from 'ng2-cookies';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, SiloqpayComponent, CommonModule],
  standalone: true
})

export class InvoiceComponent {
  password2= "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public currency = "USD"
  public theUser = "";
  ttl = "30";
  price = "";
  public theGoldiUser = "";
  user = new User("", "", "");
  siloqpayUrl = "";
  callback = "";
  public p = "invoice";
  desc = "";
  public backgroundColor2  = "orangered";

  constructor( private alertService: AdminAlertService, public userSVC: UserService, private router: Router, public loginInfo: Login, private _cookieService:CookieService){
  	loginInfo = new Login();
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    if(this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
    }

    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.siloqpayUrl = config.siloqpayUrl;
    this.callback = config.siloqpayCallback;
    this.currency = config.currency;
	}

  showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

//TODO: Avoid this call.
invoice() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
            this.router.navigate(['/admin/siloqpay'],  { queryParams: { a : this.price, c : this.currency, u : user.id, l : this.ttl, p : this.p, g : this.callback, d : this.desc}});
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  done() {
    this.router.navigate(['/admin/account']);
  }
 }
