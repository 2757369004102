<admin-header></admin-header>
<body style="height:100%;width:100%; margin:0px;">
<div style="text-align:center; width:100%; padding-top:0px;">
  <div class="doNotPrint" style="margin-top:80px;display:table;width:100%; height:18px; valign:middle;border:0px solid lightgrey;padding:0px;">
      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:#617CB5;;font-size:1em;font-weight:500;vertical-align: middle;">SETTINGS</div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; display:table-cell;float:right;width:40%;margin-right:10px;text-align:right;padding:0px 5px 0px 12px;">
      </div>
    </div>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" >&nbsp;<img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
        style="color:dodgerblue;">{{taxonomy}}</span><span
        class="sup"
        style="color:mediumseagreen;">&trade;</span><span
        style="color:dodgerblue;">&nbsp;Runtime!</span> </a>
    </div>
     <ng-template id="modal" #modalTemplate>
    </ng-template>
<div style="padding:0px; text-align:center; width:100%; margin:0 auto;min-width:300px;">

  <div style="margin-top:20px;text-align:center;height:60px;min-width:300px;" >
    <h2 class="block_container siloqlogofont" style="text-align:center;min-width:300px;font-size:1.2em;font-weight:600px; color:#617CB5;">User Name</h2>
    <h3 class="block_container siloqlogofont" style="text-align:center;padding:10px 0px 10px 0px;font-size:1.2em; color:navy;min-width:300px;">{{theUser}}</h3>
    <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='logout()' class="siloqbutton">Sign Out</a>
    </div>
  <br>
  <br>
  <br>
  <br>
  <div class="container2" style="padding:0px;margin: 0 auto;overflow:auto;width:90%;height:100%;">
    <div (click)="profile();" style="color:dodgerblue;font-weight:500;">Profile</div>
    <div (click)='resetPwd()' >Reset Password</div>
    <div (click)='invite()' style="color:#383838;font-weight:500;">Invite Friends</div>
    <div (click)='career()' style="color:#383838;font-weight:500;">Upload Resume</div>
    <div (click)='roi()' style="color:#383838;font-weight:500;">Redeem Payment</div>
    <div (click)='goldiMembers()' style="color:#383838;font-weight:500;">GoldiMembers Status</div>
    <div (click)='goldiAngels()' style="color:#383838;font-weight:500;">GoldiAngels Investment</div>
    <div *ngIf="admin.indexOf(theUser) >= 0" (click)='invoice()' style="color:#383838;font-weight:500;">{{taxonomy}} Invoice</div>
    <div *ngIf="admin.indexOf(theUser) >= 0"  style="color:#383838;font-weight:500;"><a href="/report.html" target="_blank">{{taxonomy}} Report</a></div>
  </div>
  <br>
  <hr style="width:25%;min-width:300px;padding-left:0px;">
  <div class="row align-center siloqfont" style="margin-bottom:50px;font-weight:400;text-align:center;">
    <a title="Please send us your requests" style="text-decoration:none;color:dodgerblue;" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Support'}">Support&nbsp;&nbsp;</a>
    <a title="Please tell us what you want more better" style="text-decoration:none;color:mediumseagreen;" class="intLink" [routerLink]="['/admin/feedback']"[queryParams]="{s:'Feedback'}">Feedback&nbsp;&nbsp;</a>
    <a *ngIf="this.theGoldiUser && this.theGoldiUser != 'null'" title="Unsubscribe {{memberType}}" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Unsubscribe ' + memberType}"> <span style="color:orange;">Unsubscribe&nbsp;&nbsp;</span></a>
    <a title="Close {{taxonomy}} Account" class="intLink" [routerLink]="['/admin/feedback']" [queryParams]="{s:'Close Account'}"> <span style="color:orangered;">Close Account</span></a>&nbsp;&nbsp;
  </div>
  </div>
  <br>
</div>
    <admin-footer></admin-footer>
