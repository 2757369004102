import { Component } from '@angular/core';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css'],
  imports: [FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
 })
export class ErrorComponent {
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public backgroundColor  = "gold";

  constructor( private router: Router){}

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
	}

  done() {
      this.router.navigate(['']);
    }
}
