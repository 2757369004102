<header></header>
<body style="height:100%;width:100%;">
<div class="small-12 columns" style="padding-top:60px;text-align:center;margin: auto;">
  <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:256px;width:30%;">Thank you</h3>
  <br>
  <a href="/" target="_self">
    <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
      GoldiMocks™
    </button>
  </a>
</div>
<div style="margin:20px auto;">
  <div class="container2" style="margin:20px auto; auto;width:276px; padding: 20px;">
    <div class="small-12 columns">
    <span class="siloqfont">This experience could have been better. Requested page doesn't exist. Please go ahead for it again with correct url later or contact  24/7 <i><a [routerLink]="['/contact']" style="text-decoration:none;color:dodgerblue;">support</a></i>. Again, we appreciate your business.</span>
    </div>
  </div>
  <div style="min-width:256px;text-align:center;margin:0 auto;padding:0px;" class="siloqfont">
    <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='done()' class="siloqbutton">OK</a>
  </div>
</div>
<br>
<br>
<br>
<footer></footer>

