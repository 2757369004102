import { RouterModule, Routes, ActivatedRoute } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Component, NgModule } from '@angular/core';
//
import { AppComponent } from './app.component';
//
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { SignUpComponent } from './signUp/sign-up.component';
import { ForgotComponent } from './forgot/forgot.component';
import { GoldimembersComponent } from './goldimembers/goldimembers.component';
import { AdminGoldimembersComponent } from './admin/adminGoldimembers/adminGoldimembers.component';
import { GoldiAngelsComponent } from './goldiAngels/goldiAngels.component';
import { AdminGoldiAngelsComponent } from './admin/adminGoldiAngels/adminGoldiAngels.component';
import { InvoiceComponent } from './admin/invoice/invoice.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeFooterComponent } from './homeFooter/home-footer.component';
import { ContactComponent } from './contact/contact.component';
import { LoqComponent } from './admin/loq/loq.component';
import { DeloqComponent } from './admin/deloq/deloq.component';
import { ExamComponent } from './admin/exam/exam.component';
import { ExamsComponent } from './admin/exams/exams.component';
import { MockComponent } from './admin/mock/mock.component';
import { MocksComponent } from './admin/mocks/mocks.component';
import { ScoresComponent } from './admin/scores/scores.component';
import { ResultsComponent } from './admin/results/results.component';
import { EditComponent } from './admin/edit/edit.component';
import { TopicComponent } from './admin/topic/topic.component';
import { RawComponent } from './admin/raw/raw.component';
import { CareerComponent } from './admin/career/career.component';
import { ResetComponent } from './admin/reset/reset.component';
import { CareersComponent } from './careers/careers.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { AdminHeaderComponent }  from './admin/adminHeader/admin-header.component';
import { AdminFooterComponent }  from './admin/adminFooter/admin-footer.component';
import { LoqFooterComponent }  from './admin/loqFooter/loq-footer.component';
import { AccountComponent }    from './admin/account/account.component';
import { PublishComponent } from './publish/publish.component';
import { AdminPublishComponent } from './admin/adminPublish/admin-publish.component';
import { ProfileComponent } from './profile/profile.component';
import { ErrorComponent } from './error/error.component';
import { AlertComponent } from './alert/alert.component';
import { ModalComponent } from './modal/modal.component';
import { AdminModule }  from './admin/admin.module';
import { SiloqpayComponent } from './admin/siloqpay/siloqpay.component';
import { SiloqredeemComponent } from './admin/siloqredeem/siloqredeem.component';
//
import {CookieService} from 'ng2-cookies';
import {UserService} from './services/user.service';
import { TagService } from './services/tag-service.service';
import { TopicService } from './services/topic.service';
import { AuthGuardService } from './services/auth-guard.service';
import {Login} from './services/user.service';
import {Contact} from './services/user.service';
import {DataService} from './services/data.service';
import { AlertService } from './alert/services/alert.service';
import { ModalService } from './modal/services/modal.service';
//
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHeaderInterceptor } from './custom-header.interceptor';


const appRoutes: Routes = [
  { path: '', component: HomeComponent},
  //
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent},
  { path: 'signup', component: SignUpComponent},
  { path: 'forgot', component: ForgotComponent},
  { path: 'goldimembers', component: GoldimembersComponent},
  { path: 'goldiangels', component: GoldiAngelsComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'publish', component: PublishComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'careers', component: CareersComponent},
  { path: 'home', component: HomeComponent},
  { path: 'admin/publish', component: AdminPublishComponent, canActivate : [AuthGuardService]},
  { path: 'admin/goldimembers', component: AdminGoldimembersComponent, canActivate : [AuthGuardService]},
  { path: 'admin/goldiangels', component: AdminGoldiAngelsComponent, canActivate : [AuthGuardService]},
  { path: 'admin/invoice', component: InvoiceComponent, canActivate : [AuthGuardService]},
  { path: 'admin/topic', component: TopicComponent, canActivate : [AuthGuardService]},
  { path: 'admin/loq', component: LoqComponent, canActivate : [AuthGuardService]},
  { path: 'admin/deloq', component: DeloqComponent, canActivate : [AuthGuardService]},
  { path: 'admin/exam', component: ExamComponent, canActivate : [AuthGuardService]},
  { path: 'admin/exams', component: ExamsComponent, canActivate : [AuthGuardService]},
  { path: 'admin/mock', component: MockComponent, canActivate : [AuthGuardService]},
  { path: 'admin/mocks', component: MocksComponent, canActivate : [AuthGuardService]},
  { path: 'admin/scores', component: ScoresComponent, canActivate : [AuthGuardService]},
  { path: 'admin/results', component: ResultsComponent, canActivate : [AuthGuardService]},
  { path: 'admin/edit', component: EditComponent, canActivate : [AuthGuardService]},
  { path: 'admin/raw', component: RawComponent, canActivate : [AuthGuardService]},
  { path: 'admin/account', component: AccountComponent, canActivate : [AuthGuardService]},
  { path: 'admin/feedback', component: FeedbackComponent, canActivate : [AuthGuardService]},
  { path: 'admin/siloqpay', component: SiloqpayComponent, canActivate : [AuthGuardService]},
  { path: 'admin/siloqredeem', component: SiloqredeemComponent, canActivate : [AuthGuardService]},
  { path: 'admin/career', component: CareerComponent,  canActivate : [AuthGuardService]},
  { path: 'admin/reset', component: ResetComponent,  canActivate : [AuthGuardService]},
  //
  { path: '**', component: ErrorComponent }
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    AdminModule,
    HttpClientModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeaderInterceptor, multi: true },
    CookieService,
    UserService,
    AuthGuardService,
    TagService,
    TopicService,
    DataService,
    AlertService,
    ModalService,
    Login,
    Contact
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
