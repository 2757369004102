<admin-header></admin-header>
<body style="height:100%;width:100%; margin:0px;">
<div  style="text-align:center; width:100%; padding-top:80px;">
  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">GoldiAngels</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
    <br>
    <br>
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Zone</h3>
  </div>
  <br>
  <admin-alert></admin-alert>
<form>
  <div class="container2" style="padding:20px;width:84%;text-align:center;margin:0 auto;">
    <div class="small-12  columns" style="width:300px;text-align:center;margin:10px  auto;">
              <div style="font-family:sans-serif;font-size:16px;font-weight:400; color: dodgerblue;">
              New members, aim GoldiAngels
            </div>
     <br>
          <div style="font-family:sans-serif;font-size:14px;font-weight:500; color: mediumseagreen;">
            In addition to free mocks,
          </div>
     <br>
     <div style="font-family:sans-serif;font-size:12px;font-weight:600;color:#617CB5;margin:0 auto;width:80%;">
       Access goldimembers mocks, award winning exams, exclusive deals, and more
     </div>
     <br>
     <div style="font-family:sans-serif;font-size:16px;font-weight:500; color: navy;padding-bottom:6px;">
       Earn Return Of GoldiAngels Investment Options on this wonderful limited time opportunity.
     </div>
      <div style="font-family:sans-serif;font-size:14px;font-weight:200; color: navy;">
        Be Bold, Strong and Steady. GoldiInvest on GoldiAngels Premium Investment <b>${{goldiAngelsPremiumInvestment}}</b>. Earn ROI within 2-5 Years
      </div>

      <br>
      <a [routerLink]="['/admin/publish']" [queryParams]="{h:taxonomy+'-GoldiAngels-GoldiInvestment'}" [style.backgroundColor]="backgroundColor3" (mouseover)="backgroundColor3 = 'lightsteelblue'" (mouseout)="backgroundColor3 = 'dodgerblue'"   style="font-family:Helvetica,AppleGothic,sans-serif; width:160px;background-color:dodgerblue;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton">Show Me More</a>
      <br>
      <span  class="siloqfont" style="font-size:12px; font-weight:500; color:dodgerblue;">Please contact our <a  title="Please contact us for customized investments" class="siloqfont intLink" style="font-size:12px;font-weight:600;color:orangered" [routerLink]="['/admin/feedback']" [queryParams]="{s:'GoldiAngels Custom Hotline'}">GoldiAngels Custom Hotline</a>
      for customized investments</span>
   </div>
    <br *ngIf="otherOptions">
  <div *ngIf="otherOptions && ( goldiAngelsPremiumInvestment || goldiAngelsPrestigeInvestment || goldiAngelsGoldInvestment)" style="width:300px;text-align:center;margin:auto;" class="siloqfont">
    <div *ngIf="goldiAngelsPremiumInvestment" class="siloqbutton" (click)="subscribePremium($event);" style="height:5%; min-width:256px; color:#383838; border-radius: 8px; -moz-border-radius: 8px; ">
      <a  style="padding:2px;height:18px;background-color:gold;" *ngIf="checkedPremium" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:whitesmoke;" *ngIf="!checkedPremium" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <div style=" font-weight:500;font-size:14px;"> GoldiAngels Premium ${{goldiAngelsPremiumInvestment}}</div>
      <br>
    </div>
    <div *ngIf="goldiAngelsPremiumInvestment" style="height:5%; min-height:20px;">
    </div>
    <div *ngIf="goldiAngelsPrestigeInvestment"  class="siloqbutton"(click)="subscribePrestige($event);"  style="height:5%; min-width:256px; color:#383838; border-radius: 8px; -moz-border-radius: 8px; ">
      <a style="padding:2px;height:18px;background-color:gold;" *ngIf="checkedPrestige" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:;" *ngIf="!checkedPrestige" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
    <div style=" font-weight:500;font-size:14px;"> GoldiAngels Prestige ${{goldiAngelsPrestigeInvestment}}</div>
      <br>
    </div>
    <div *ngIf="goldiAngelsPrestigeInvestment" style="height:5%; min-height:20px;">
      </div>
    <div *ngIf="goldiAngelsGoldInvestment"  class="siloqbutton"(click)="subscribeGold($event);" style="height:5%; min-width:256px; color:#383838;border-radius: 8px; -moz-border-radius: 8px; ">
      <a  style="padding:2px;height:18px;background-color:gold;" *ngIf="checkedGold" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
      <a  style="padding:2px;height:18px;background-color:whitesmoke;" *ngIf="!checkedGold" class="siloqbutton">&nbsp;&nbsp;&nbsp;&nbsp;</a>
    <div style=" font-weight:500;font-size:14px;">GoldiAngels Gold ${{goldiAngelsGoldInvestment}}</div>
      <br>
      <br>
    </div>
  </div>

  </div>
    <br *ngIf="otherOptions">
      <div class="small-12  columns" style="width:300px;text-align:center;margin:10px  auto;">
        <div style="text-align:center;margin:auto;" class="siloqfont">
          <button (click)="subscribe()" type="button" class="siloqbutton" style="width:196px;background-color:whitesmoke;border:4px solid gold;padding:2px;font-weight:500;"><div class="ms-text-center" style="width:196px;color:navy; font-weight:600;font-size:1.4em;"><img src="assets/sweat.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo" style="margin-bottom:16px;width:24px; height:24px;" /><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:orange;">o</span><span style="color:dodgerblue;">q</span><span  style="color:#617CB5;">Pay</span><span class="sup" style="color:orangered;">&reg;</span></div> Start perfect investment</button>
                  <br *ngIf="!otherOptions">
                  <br *ngIf="!otherOptions">
        </div>
    </div>
    <br *ngIf="otherOptions">
  <div style="padding:0px;border-top:1px solid grey; width:100%; margin:0 auto; text-align:center;">
    <br>
    <a *ngIf="!otherOptions" [style.backgroundColor]="backgroundColor"  (click)="otherOptions=!otherOptions;backgroundColor = 'gold'" (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:160px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton">Other Options</a>
    <a  *ngIf="otherOptions"[style.backgroundColor]="backgroundColor2"  (click)="otherOptions=!otherOptions;backgroundColor2 = 'mediumseagreen'" (mouseover)="backgroundColor2 = 'olive'" (mouseout)="backgroundColor2 = 'mediumseagreen'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:160px;background-color:orangered;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"class="siloqbutton">Less Options</a>
    <a  [style.backgroundColor]="backgroundColor4"  (click)="backgroundColor4 = 'orangered'; done()" (mouseover)="backgroundColor4 = '#AF474C'" (mouseout)="backgroundColor4 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:160px;background-color:orangered;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"class="siloqbutton">Cancel</a>
  </div>
    <br *ngIf="!otherOptions">
</form>
</div>
<br>
<br>
<br>
<br>
<admin-footer></admin-footer>

