<sis-header></sis-header>
<div *ngIf="userSVC.authUser.email" style="display:table;height:30px;padding-top:0px;margin-top:20px;border:0px;text-align:center;background-color:orangered;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
  <div  style="display:table-cell;width:4%; text-align:left;padding-left:15px;">
  </div>
  <div *ngIf="userSVC.authUser.email" style="display:table-cell;width:4%; text-align:left;padding-left:15px;">
  </div>
  <div style="display:table-cell;padding-top:6px;">
    <a *ngIf="userSVC.authUser.email" [routerLink]="['/admin/feedback'] "style="color:whitesmoke;font-size:1em;font-weight:500;" >We have a powerful intelligent {{taxonomy}} (Perfect Practice) Zone [<b> {{zone}} </b>]. It is concentrated in two words:&nbsp;<b><i>"sustainable practice"</i></b></a>
  </div>
  <div style="display:table-cell;width:4%;text-align:right;padding-right:15px;">
  </div>
</div>
<div *ngIf="!userSVC.authUser.email" style="display:table;height:30px;padding-top:0px;margin-top:20px;border:0px;text-align:center;background-color:#2c2b2a;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
  <div  style="display:table-cell;width:4%; text-align:left;padding-left:15px;">
  </div>
  <div *ngIf="userSVC.authUser.email" style="display:table-cell;width:4%; text-align:left;padding-left:15px;">
  </div>
  <div style="display:table-cell;color:black;padding-top:6px;">
    <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" style="color:whitesmoke;font-size:1em;font-weight:500;" >We have a powerful intelligent {{taxonomy}} (Perfect Practice) Zone [<b> {{zone}} </b>]. It is concentrated in two words:&nbsp;<b><i>"sustainable practice"</i></b></a>
  </div>
  <div style="display:table-cell;width:4%;text-align:right;padding-right:15px;">
  </div>
</div>
<div style="display:table;height:3px;padding-top:0px;margin-top:0px;border:0px;text-align:center;background-color:orange;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
</div>
<div *ngIf="taxonomy===identity" class="row expanded align-center" style="width:100%;padding:20px 0px 0px 0px;text-align: center; margin:auto;">
  <div *ngIf="taxonomy==='imockr'">
    <span class="siloqfont" style="padding:0px;font-size:4em;font-weight:600;"><span style="color:orange;"></span><span style="color:mediumseagreen;">i</span><span style="color:dodgerblue;">mock</span><span style="color:orangered;">r</span><span class="sup" style="color:orange;">&trade;</span></span>
    <br>
    <span style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">eye-mocker<span style="color:orangered;font-size:.85em;font-weight:500;"> (/ai-mäkər/)</span></i> ]</span>
  </div>
  <div *ngIf="taxonomy==='Goldimocks'">
    <span class="siloqfont" style="padding:0px;font-size:4em;font-weight:600;"><span style="color:orange;">Gold</span><span style="color:mediumseagreen;">i</span><span style="color:dodgerblue;">mock</span><span style="color:orangered;">s</span><span class="sup" style="color:orange;">&trade;</span></span>
    <br>
    <span style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">gowl-dee-mocks<span style="color:orangered;font-size:.85em;font-weight:500;"> (/ˈɡōldēˌmäks/)</span></i> ]</span>
  </div>
  <div *ngIf="taxonomy==='iexamr'">
    <span class="siloqfont" style="padding:0px;font-size:4em;font-weight:600;"><span style="color:orange;"></span><span style="color:mediumseagreen;">i</span><span style="color:dodgerblue;">exam</span><span style="color:orangered;">r</span><span class="sup" style="color:orange;">&trade;</span></span>
    <br>
    <span style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">eye-mocker<span style="color:orangered;font-size:.85em;font-weight:500;"> (/ai-iɡˈzamər/)</span></i> ]</span>
  </div>
  <div *ngIf="taxonomy==='Goldiexams'">
    <span class="siloqfont" style="padding:0px;font-size:4em;font-weight:600;"><span style="color:orange;">Gold</span><span style="color:mediumseagreen;">i</span><span style="color:dodgerblue;">exam</span><span style="color:orangered;">s</span><span class="sup" style="color:orange;">&trade;</span></span>
    <br>
    <span style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">gowl-dee-exams<span style="color:orangered;font-size:.85em;font-weight:500;"> (/ˈɡōldēˌiɡˈzamz/)</span></i> ]</span>
  </div>
  <!--
  <span  style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">gold-eye-mocker<span style="color:orangered;font-size:.85em;font-weight:500;"> (/gold-ai-mäkər/)</span></i> ]</span>
  -->
</div>
<div *ngIf="taxonomy!==identity" class="row expanded align-center" style="width:100%;padding:20px 0px 0px 0px;text-align: center; margin:auto;">
  <span class="siloqfont" style="padding:0px;font-size:4em;font-weight:600;"><span style="color:orangered;">{{subclass}}</span><span *ngIf="subclass" style="color:mediumseagreen;">.</span><span style="color:orange;">{{class}}</span><span *ngIf="class" style="color:mediumseagreen;">.</span><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span></span>
  <br>
  <span *ngIf="taxonomy=='iexamr'" style="color:dodgerblue;padding-right:20px;font-size:1em;font-weight:400;" class="siloqfont"> <span style="color:orangered;"></span>[ <i style="color:mediumseagreen;font-size:1em;font-weight:600;">eye-examer<span style="color:orangered;font-size:.85em;font-weight:500;"> (/ai-iɡˈzamər/)</span></i> ]</span>
</div>
<div style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;height:100%;width:100%;itext-align:center;">
<div style="height:200px;width:100%;text-align:center; display:table;padding-top:0px;margin:auto;">
    <div style="width:100%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">
      <div class="small-12 columns" style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;color:navy; font-size:1em;font-weight:500;text-align:center;padding:10px 0px 50px 0px;">
      <div class="block_container" style="padding-top:0px;">
        <div style="width:45%;margin-left:auto;margin-right:auto; padding:0px;display:flex;justify-content:center;align-items:center;">
      <input class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure Search" name="siloqSearch" #siloqSearch (input)="auto()" [(ngModel)]='searchText' style="color: grey;padding:7px;height: 42px; width:100%;min-width:200px;margin:0px;"/>&nbsp;
        <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:2.3em;font-weight:400;padding:0px 0px 20px 0px;height:36px;"></span>
            </div> <div *ngIf="showAuto" style="margin-left:auto;margin-right:auto;margin-top:0 auto; width:44%;padding:0px;">
      <ul *ngIf="showAuto" style="margin-top:0px; list-style: none;display:table; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;display:inline-block;font-size:1em;border:3px lightsteelblue solid;padding: 5px; width:100%"> <li *ngFor="let auto of ptopics; let i = index" style="-moz-border-radius: 15px; border-radius: 15px;" (click)="selectAuto(auto, i)" [class.active]="i === selectedIndex">
            <div style="cursor:pointer;-moz-border-radius: 15px; border-radius: 15px;font-size:1em;text-align: left;padding:5px;">{{auto.hashTag}}</div>
          </li>
      </ul>
    </div>
        </div>
<div class="block_container" style="background-color:#ffffff00;border-bottom:0px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span><span style="color:dodgerblue;">&nbsp;Zone  <b>[ {{zone}} ]</b></span></a>
</div>
        <img class="intLink" title="{{taxonomy}}" style="width:100px; height:100px" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEAAQMAAABmvDolAAAABlBMVEX///8AAABVwtN+AAABW0lEQVR42uyYMY7EIAxFHaWg5Agchavt0TiKj5CSAvFXtoN2mN1sP5hfjEbJq6z8/zG0tbW19Z+gaicqhSteRMmefK0FdPk5G1GoARCA5Ul2BxwypnbqdGRSiRNQvAL6P8qkNkDim1UB84V+DxdR5AfjrA6MnKyhSk5GfgrSxYGhMPriSYsD/ehEJ1qwmIwshZEnZ60AAH20Yo1XBCdwLhnegAOSlGj6PdxJOeWkE6Af6GYMycl4JaaEMvnCBQBxjvZFtUEl8NwXSwB3KzYxxkXmC6IMb8Bci7ApoWRvgEaELIvii2j45AsngBjntEHdC4K8z2UxYFij2nYQmSiV93xwAPxckuikZDtwCYxLkoAqk7oHleEOuLdmPTabb1D+uCxaBNA7Me1FSiW/BKEvQGtR+oDtUPzre1geGL6w93g/FHsBXo9JckBgXZofLos+F9ja2vKo7wAAAP//IrEhMpKHB40AAAAASUVORK5CYII=" />
        <alert></alert>
        <div class="row expanded align-center" style="width:100%;padding:0px;text-align: center; margin:auto;">
		<span style="color:dodgerblue;padding:0px;font-size:.8em;font-weight:400;" class="siloqfont">
      <a [routerLink]="['/publish']" [queryParams]="{h:'PATENT'}" style="font-size:.8em;font-weight:400;color:tomato;" >PATENTED</a> <br> Discover why you will be happier, more successful, and feel more free!
    </span>
        </div>

        <div class="block_container" style="margin:0px;background-color:#ffffff00;border-top:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://sislr.com" >
          <span style="color:dodgerblue;">Powered by</span> <img src="assets/sislr.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://sislr.com"><span style="color:dodgerblue;">S</span><span
          style="color:orangered;">i</span><span
          style="color:mediumseagreen;">s</span><span
          style="color:orange;">l</span><span
          style="color:dodgerblue;">r</span><span
          class="sup"
          style="color:orangered;">™</span>
        </a>
        </div>
        <div style="display:table;border-top:0px solid grey;text-align:center;padding-top:0px;height:20px;width:100%;">
          <div class="siloqlogofont" style="text-align:center;height:40px;margin:auto;padding-left:30px;font-size:1em;font-weight:200; padding:0px;display:inline;">
            <a href="https://www.facebook.com/Siloq" target="_blank" class="fa fa-facebook" style="height:20px;width:20px;"></a>
            <a href="https://twitter.com/SiloQInc" target="_blank" class="fa fa-twitter" style="height:20px;width:20px;"></a>
            <a href="https://www.google.com/search?q=siloq" target="_blank" class="fa fa-google" style="height:20px;width:20px;"></a>
            <a href="https://www.linkedin.com/company/18317376" target="_blank" class="fa fa-linkedin" style="height:20px;width:20px;"></a>
            <a href="https://www.youtube.com/channel/UCSTBLoXXBBU0H1jydxd3-6g" target="_blank" class="fa fa-youtube" style="height:20px;width:20px;"></a>
            <a href="https://www.instagram.com/siloqinc/" target="_blank" class="fa fa-instagram" style="height:20px;width:20px;"></a>
            <a href="https://www.glassdoor.com/Overview/Working-at-Siloq-EI_IE1878370.11,16.htm" target="_blank" class="fa fa-glassdoor" style="height:20px;width:20px;">G</a>
            <a href="https://en.wikipedia.org/wiki/siloq" target="_blank" class="fa fa-wikipedia" style="height:20px;width:20px;">W</a>
          </div>
        </div>
        <div *ngIf="taxonomy===identity" id="textbox" style="width:90%; margin:0; padding: 0px 5% 0px 5%;">
          <br>
          <div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:85%;">
            <div style="display: inline-block;">
              <a *ngIf='currentPage>1' href="admin/mocks?p=1&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/mocks?p={{currentPage-1}}&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/mocks?&p={{currentPage+1}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/mocks?p={{pageCount}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">>></a>
            </div>
          </div>
          <div class="container" style="width:100%;color:#696969;border:0px solid #696969;
border-color:gainsboro;margin-top:0px;">
            <div style="width:100%;overflow:hidden;">
              <table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:1em;font-weight:200;width:100%;">
                <tr class="block_container" style="color:grey;padding:40px;">
                  <th style="width:100%;" valign="top">
                    <div (click)="sortKey=sortKey.includes('-') ? 'hashtag' : '-hashtag'; setFirstPage();" style="cursor:pointer;font-weight:500;">
                      <div  style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:400; color: navy; height:23px;text-align:center;" >
                        <div *ngIf="selectedTopics && selectedTopics.length >= 64" style="display: inline-block;">
                          Page {{currentPage | number:'1.0-0'}} > {{totalCount | number:'1.0-0'}},000,000 Mock(s)
                        </div>
                        <div *ngIf="selectedTopics && selectedTopics.length > 0 && selectedTopics.length < 64" style="display: inline-block;">
                          Page {{currentPage | number:'1.0-0'}} of {{totalCount | number:'1.0-0'}} Mock(s)
                        </div>
                        <div  *ngIf="selectedTopics === undefined" style="display: inline-block; text-align:left;">
                          Working...
                        </div>
                        <div  *ngIf="selectedTopics && selectedTopics.length === 0" style="display: inline-block;">
                          No Mock(s)
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>


                <tr *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">
                  <td *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show" style="margin-left:10%;" valign="top">

                    <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px;">
                      <div style="background-color:whitesmoke;width:100%;min-height:20px;height:auto;margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;border-bottom:1px solid lightgrey;">

                        <div style="float:left; text-align:left;width:80%;">
                          <div contenteditable="false" style="height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="Mock Topic {{topic.hashTag}}" (click)='docTopic(topic)' [innerHTML]="parseTitle(topic.hashTag) | truncate : 64"></div>
                        </div>

                        <div style="text-align:right;min-width:60px;"> <input class="intLink" type="button" value="❐" valign="middle" (click)='docTopic(topic)' title="Mock Topic {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
                        </div>
                      </div>

                      <div style="vertical-align:top;margin:0px;text-align:left;padding:20px 5px 5px 5px;" >
                        <div style="display:inline-block;width:100%;">
                          <!--
                          <div (click)='docTopic(topic)' style="display:table-cell;cursor:pointer;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: left; valign: top; font-size:.85em;font-weight:300;color:black" [innerHTML]="renderImage(topic.value) | truncate : 128 | safeHtml"> </div>
                          -->
                          <span class="doNotPrint" (click)='docTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

          </div>
          <br>
          <div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:85%;">
            <div style="display: inline-block;">
              <a *ngIf='currentPage>1' href="admin/mocks?p=1&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' href="admin/mocks?p={{currentPage-1}}&q={{searchText}}" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/mocks?&p={{currentPage+1}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' href="admin/mocks?p={{pageCount}}&q={{searchText}}" style="color:dodgerblue;cursor:pointer;">>></a>
            </div>
          </div>
          <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
            <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" valign="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
            style="color:mediumseagreen;">s</span><span
            style="color:dodgerblue;">l</span><span
            style="color:orange;">o</span><span
            style="color:dodgerblue;">q</span><span
            class="sup"
            style="color:orangered;">™</span>
          </a>
          </div>
        </div>

        <br>



        <div style="background-color:white;height:140px;width:100%;text-align:center; display:table;padding:0px;">
          <div style="width:30%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">
            <div class="small-12 columns" style="padding:2px;">
        <span style="padding-top:4px; color:whitesmoke; width:60%;
        display:inline-block;text-align:center;" class="siloqlogofont">
          <ul class="siloqinput" style="list-style: none;color:#383838;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;inline-block;font-size:.8em;font-weight:400;padding: 10px; height:20px;text-align:left;">
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span style="color:orange;">•</span> <b>{{taxonomy}}</b> is an instant publishing star and a smart <span style="color:dodgerblue;">AI</span> Engine
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span
              style="color:orangered;">•</span> Simply search, glean, and publish in a single step
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span
              style="color:dodgerblue;">•</span> Work together, share and secure in a controlled way
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span style="color:mediumseagreen;">•</span> <b>{{taxonomy}}</b> anywhere across applications and platforms
            </li>
          </ul>
        </span>
            </div>
          </div>
        </div>


        <div style="text-align:center;padding-top:0px;height:150px;width:100%;display:table;">
          <div style="border:0px;text-align:center;background-color:white;">
            <div style="height:300px;margin:auto;font-family:Helvetica,AppleGothic,sans-serif;color:mediumseagreen;font-size:1em;font-weight:100;">
              <img *ngIf="userSVC.authUser.email" src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin:20px 0px 0px 0px;width:250px; height:250px;" />
              <img *ngIf="!userSVC.authUser.email" src="/assets/admin-{{taxonomy}}-dark.png" align="middle" style="margin:20px 0px 0px 0px;width:250px; height:250px;" />
              <br>
              <br>
              <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span><span style="color:dodgerblue;">&nbsp;Zone <b>[ {{zone}} ]</b></span></a>
              </div>
            </div>
          </div>
          <br>
          <br>
          <div style="width:100%;">
            <span style="color:dodgerblue; padding-bottom:20px; width:60%;font-size:.8em;font-weight:400; display:inline-block;text-align:center;" class="siloqlogofont"><span style="color:orangered;">*</span> Right now, {{taxonomy}} works on all chromium based browsers like Chrome, Edge, Opera and Brave with limited features on mobile devices.</span>
          </div>
        </div>
        <br>
        <div style="height:400px;border:0px;text-align:center;background-color:dodgerblue;font-family:Helvetica,AppleGothic,sans-serif;color:green ">
          <div style="margin:auto;padding:5%;">
            <a style="font-size:2.5em;font-weight:600;" >Why {{taxonomy}}?</a>
            <div style="font-size:.9em;font-weight:500;margin:30px;text-align:
                      justify; text-justify: inter-word;color:whitesmoke;">Whether you’re an
              individual looking forward to publishing your work
              instantly to advance your career or an enterprise team
              looking to cut cycle times, speed up the searching
              process, and give your teammates the skills to realize
              your strategies, {{taxonomy}} removes the challenges and
              roadblocks slowing you down. {{taxonomy}} is advancing everyone
              in the world’s workforce, and that starts with making
              your day-to-day searching, gleaning, and publishing more
              efficient and effective—and giving you more to
              celebrate.
              <br>
              <br>
              <div style="text-align:center; width:100%;">
                <a *ngIf="!userSVC.authUser.email" [routerLink]="['/signup']" class="siloqbutton" style="border:0px;color:dodgerblue;">See our Solution</a>
                <a *ngIf="userSVC.authUser.email" [routerLink]="['/login']" class="siloqbutton" style="border:0px;color:dodgerblue;">Explore our Solution</a>
              </div>
              <br>
              <br>
            </div>
            <br>
          </div>
          <br>
        </div>

        <br>
        <br>
        <div style="display:table; width:100%;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5; ">
          <div style="margin:auto;padding:2%;">
            <a style="font-size:1em;font-weight:600;" >Let's get in Touch</a>
            <br>
            <br>
            <a *ngIf="!userSVC.authUser.email" style="font-size:.9em;font-weight:500;" >For more information or a demo request, just drop us a message.</a>
            <a *ngIf="userSVC.authUser.email" style="font-size:.9em;font-weight:500;" >For more information or a feedback, just drop us a message.</a>
            <br>
            <br>
            <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" class="siloqbutton" style="border:0px;color:tomato;">Contact Us</a>
            <a *ngIf="userSVC.authUser.email" [routerLink]="['/admin/feedback']" class="siloqbutton" style="border:0px;color:tomato;">Feedback To Us</a>
            <br>
          </div>
          <br>
        </div>
      </div>

                <!--
          <div style="display:table;border:0px;text-align:center;background-color:#f5f5f5;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5;width:100%;height:20px;padding:0px; ">
        -->

      <div style="width:100%;">
        <span style="color:dodgerblue; padding-bottom:20px; width:60%;font-size:.8em;font-weight:600; display:inline-block;text-align:center;" class="siloqlogofont"> {{taxonomy}} works on all chromium based browsers like Chrome, Edge, Opera and Brave with limited features on mobile devices.</span>
      </div>
      <br>
    <div style="display:table;width:100%;height:100px;vertical-align:middle;">
      <div style="width:100%;height:100%;margin:0 auto;text-align:center;background-color:orange;float:left;">
        <br>
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"I practice, I should practice perfect, and I'm so there"</a>
        <br>
        <br>
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.8em;font-weight:400;color:whitesmoke;padding:1%;">
          I practice, I should practice perfect, and I'm so there. I practice, I should practice perfect, and I'm so there." I practice, I should practice perfect, and I'm so there.
        </a>
        <br>
        <br>
        <br>
      </div>
      <br>
      <div style="text-align:left;padding:0px;width:100%;vertical-align:top;">
        <img src="/assets/kid-reading.jpg" align="middle" style="margin:0px 0px 0px 0px;width:100%; height:66%;" />
      </div>
    </div>

    <br>
      <br>
      <div class="block_container" style="background-color:#ffffff00;border-bottom:0px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span><span style="color:dodgerblue;">&nbsp;Zone <b>[ {{zone}} ]</b> Wonder!</span></a>
    <br>
        <br>

      <div style="width:100%;height:20px;">
        <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
        <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:dodgerblue;">
          <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"The future of intelligence is here. For a demo, contact us"</a>
        </div>
      </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:right;text-align:left;height:10px;padding:50px;width:50%;background-color:orangered;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"I mock, I should mock more, and so I am"</a>

      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:mediumseagreen;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"I think of an Idea, I should publish my Idea to my peer community, and therefore I am"</a>
      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:right;text-align:left;height:10px;padding:50px;width:50%;background-color:orange;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"Sustainable Intelligence Sustainable Learning Runtime!"</a>
      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:dodgerblue;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"The future of intelligence is here.  For a demo, contact us"</a>
      </div>
    </div>
<!--
 </div>
-->
      </div>
    </div>
  </div>



  <div style="display:table;width:100%;border-top:1px solid grey;text-align:center;margin-top:60px;padding-top:0px;height:40px;">
      <div class="siloqlogofont" style="display:table-cell;text-align:center;height:40px;margin:auto;font-size:1em;font-weight:200; padding:1%;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-privacy'}" style="text-decoration:none;color:teal;">Privacy</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:'imoqr-terms-and-conditions'}" style="text-decoration:none;color:teal;">Terms</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-licensing-and-purchasing'}" style="text-decoration:none;color:teal;">Pricing</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-invest'}" style="text-decoration:none;color:teal;">Invest</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-news'}" style="text-decoration:none;color:teal;">News</a>&nbsp;&nbsp;
           <a [routerLink]="['/careers']" style="text-decoration:none;color:teal;">careers</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-help'}" style="text-decoration:none;color:teal;">Help</a>&nbsp;&nbsp;
           <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" style="text-decoration:none;color:teal;">Contact&nbsp;&nbsp;</a>
           <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-about'}" style="text-decoration:none;color:teal;">About</a>&nbsp;&nbsp;
      </div>
  </div>
<home-footer></home-footer>
















