import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { Subscription } from 'rxjs';
import { compress, decompress } from 'compress-json'
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { LoqFooterComponent } from '../loqFooter/loq-footer.component';
import { FormsModule } from '@angular/forms';
declare function escape(s:string): string;
declare function unescape(s:string): string;
declare function testJs(): any;
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../tag/tag.safehtml.pipe';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-doc',
  templateUrl: './loq.component.html',
  styleUrls: ['./loq.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, LoqFooterComponent, AdminHeaderComponent, CommonModule, SafeHtmlPipe, TruncatePipe],
  standalone: true
})

export class LoqComponent implements OnInit {

  tags: Tag[] = [];
  tagsCache: Tag[] = [];
  public topic  = new Topic('', '', '');
  utags: any;
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public ttopic = new Topic("","","");
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public selectedSlide;
  public selectedTag;
  public classTag = "Not Selected Yet";
  selectedTagIndex = -1;
  selectedSlideIndex = -1;
  browseTagIndex = -1;
  browseSlideIndex = -1;
  selectAll = false;
  editable = false;
  edit = false;
  public theUser = "";
  public theGoldiUser = "";
  currentPage: number = 1;
  pageSize = 1;
  totalCount: number = 0;
  pageCount: number = 1;
  host = window.location.protocol+"//"+window.location.hostname;
  public slides = [];
  public subtitles: string[] = [];
  public printable: boolean = false;
  loq = "";
  subscription: Subscription;
  random: number = 5;
  newLoq = false;
  ready = false;
  subtitlesCount = 0;
  tagsCount = 0;
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public blurTimeout = true;
  public admin = [];
  public backgroundColor1  = "gold";
  public backgroundColor5  = "orangered";
  public backgroundColor2  = "gold";
  public backgroundColor3  = "gold";
  public backgroundColor4  = "dodgerblue";
  public showcol = false;
  public top = true;

  constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){}

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  openModalEx(modalTemplate: TemplateRef<any>, tag: any) {
    this.modalService
      .open(modalTemplate, { size: 'sm', title: 'Archive', message: "Want to archive the selected LOQ?" })
      .subscribe((action) => {
        //TODO
        if(tag.id) {
          if(tag.value === "") {
            this.tagService.deleteTagName(tag.id).subscribe( () => {
                this.showAlert(AlertTypeEnum['info'], "The selected item has been archived.");
                this.getTopicTagsEx();
            },
             (err: any) => {
              console.log(err);
            });
          } else {
            this.tagService.deleteTag(tag.id).subscribe( () => {
                this.showAlert(AlertTypeEnum['info'], "The selected item has been archived.");
                this.getTopicTagsEx();
            },
             (err: any) => {
              console.log(err);
            });
          }
        }
      });
  }

  getTopicTagsEx() {
          this.tags = [];
          document.getElementById("loading").style.display = "inline";
          this.blurTimeout = true;
          this.topicService.getTopicTags(this.topic)
                  .subscribe(
                   (tags: any) => {
                     this.tags = tags
                     this.redraw();
                    document.getElementById("loading").style.display = "none";
                    },
                     (err: any) => {
                      console.log(err);
                  });
  }

  getTopicTagsExToo() {
          this.tags = [];
          document.getElementById("loading").style.display = "inline";
          this.blurTimeout = true;
          this.topicService.getTopicTags(this.topic)
                  .subscribe(
                   (tags: any) => {
                     this.tags = tags
                     this.redraw();
                     this.editable = !this.editable;
                    document.getElementById("loading").style.display = "none";
                    },
                     (err: any) => {
                      console.log(err);
                  });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      var e = params['e'] || "";
      this.model.hashTag = unescape(params['h']) || "";
      this.model.topicId = unescape(params['i']) || "";
      this.topic.id = this.model.topicId;
      var u = params['d'] || "";

      this.class = config.class;
      this.subclass = config.subclass;
      this.identity = config.identity;
      this.taxonomy = config.taxonomy;
      this.admin = config.admin;

      if (u != "" && this.theUser !== window.atob(u)) {
        this.showAlert(AlertTypeEnum['danger'], "It looks like that you do not have access to this page!");
        this.router.navigate(['']);
      }

      this.subscription = this.data.currentClassTag.subscribe(classTag => this.classTag = classTag)
      if (e) {
        this.editable = true;
        this.classTag = e
      }
    });

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.selectedSlideIndex = Number(fragment);
      if (this.selectedSlideIndex === 0) {
        this.selectedSlideIndex = -1;
      }
    })

    if(this.selectedTagIndex > 0) {
      window.location.replace(window.location.href);
    }

    if(this.model.hashTag === "newLoq") {
        var d = new Date();
        this.model.hashTag = this.taxonomy + "_" + d.toDateString().toLowerCase();
        this.model.email = this.theUser;
        this.newLoq = true;
        setTimeout(()=>{
          this.showAlert(AlertTypeEnum['info'], "Edit Title");
          }, 100);
        this.ready = true;
        return;
    }

    if(this.topic.id) {
      this.slides = [];
      this.subtitles = [];
      this.utags = [];

      this.getTopicById(this.topic.id, (t: any)=>{
        if (t.id) {
          document.getElementById("loading").style.display = "inline";
          this.topic = t;
          this.topic.hashTag = unescape(t.hashTag);
          this.model.hashTag = unescape(t.hashTag);
          this.data.changeMessage(unescape(t.hashTag));
          this.titleService.setTitle(unescape(t.hashTag));
          this.setFirstPage();
        }

        this.ready = true;
        return;
      });
    }
  }

  loadTags(callback: () => any) {
    this.getTopicTags(callback);
  }

  getTopicTags(callback: () => any) {
    this.topic.hashTag = escape(unescape(this.topic.hashTag));
    this.topicService.getTopicTags(this.topic)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
            if(this.tags && this.tags.length <= 0) {
              this.showAlert(AlertTypeEnum['info'], "Click Edit [<i class='fa fa-pencil'></i>] above to add LOQs");
            }
          //this.tagsCache = JSON.parse(JSON.stringify(this.tags));
          if(this.tags !== undefined) {
            if(this.tags.length <= 0) {
              document.getElementById("loading").style.display = "none";
              this.redraw();
              return;
            }

            if(callback !== undefined) {
              callback();
            }

            this.redraw();
            this.setPageVisible();
            document.getElementById("loading").style.display = "none";
          }
          this.ready = true;
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e)
    .subscribe({ next: (t: any) => {
                          callback(t);
                         },
                         error: (error: any) => {
                          console.log(error);
                         }
                       });
  }

  getTopicById(id: string, callback: (t: any) => any) {
    this.topicService.getTopicById(id).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  mapTags(tag: Tag){
    //if (tag.position > 0) { //Do not add the title at index 0
      this.subtitles.indexOf(tag.name.toLowerCase()) === -1 && this.subtitles.push(tag.name.toLowerCase());
      this.addValueToName(tag.name.toLowerCase(), tag);
    //}
  }

  addValueToName(name: any, value: any) {
    // Shorcut || returns left side if it is "truthy," or the right otherwise.
    // This means that we only assign a new Array to the Object's property
    // if it has not previously been used.
    this.slides[name] = this.slides[name] || [];
    // Adds a value to the end of the Array
    this.slides[name].push(value);
  }

  setSearchText() {
    if(this.model.hashTag.length > 0 && this.selectedSlide.length > 0 && this.selectedTag.value.length > 0) {
      this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedSlide.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split   (' ').slice(0,6).join(' '));
    }
  }

  public selectSlide( slide: any, i: number) {
    this.selectedSlideIndex = i;
    this.selectedSlide =  slide;
    //this.setSearchText();
  }

 public selectTag( tag: any, j: number) {
    tag.rank = j + 1;
    this.selectedTagIndex = j;
    this.selectedTag =  tag;
    //this.setSearchText();
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        if(event.shiftKey) {
          this.onTagUp();
        } else {
          this.onUp();
        }
        break;
      case 40:
        if(event.shiftKey) {
          this.onTagDown();
        } else {
          this.onDown();
        }
        break;
      case 13:
        if(event.shiftKey) {
          this.cloneTag(this.selectedTag);
          //let elm = event.target;
          //elm.focus();
          event.preventDefault();
        }
        event.stopPropagation();
        break;
      case 75:
        if(event.metaKey) {
          this.editable = true;
          this.newLOQ();
        }
        break;
      case 69:
        if(event.metaKey) {
          this.editable = !this.editable;
          this.edit = !this.editable;
        }
        break;
      case 27:
        this.getTopicTagsEx();
        this.escapeIndex();
        break;
    }
    return;
  }

  escapeIndex() {
    this.selectedSlideIndex = -1;
    this.selectedTagIndex = -1;
    this.browseTagIndex = -1;
    this.browseSlideIndex = -1;
  }

  public onDown() {
    if(this.slides.length > 0 && this.selectedSlideIndex < this.subtitles.length - 1)
    {
      this.selectedSlide = this.slides[++this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onUp() {
    if(this.slides.length > 0 && this.selectedSlideIndex > 0)
    {
      this.selectedSlide = this.slides[--this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onTagDown() {
    if(this.slides.length > 0 && this.selectedTagIndex < this.tags.length - 1)
    {
      this.selectedTag = this.slides[++this.selectedTagIndex];
      //this.setSearchText();
    }
  }

  public onTagUp() {
    if(this.slides.length > 0 && this.selectedTagIndex > 0)
    {
      this.selectedTag = this.slides[--this.selectedTagIndex];
      //this.setSearchText();
    }
  }

  refIndex(source: string): number {
    if (source.trim() !== "") {
       return this.utags.indexOf(source) + 1;
    }

    return -1;
  }

  public setFirstPage() {
    this.loadTags(() => {
      if(this.tags.length > 0) {
        this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
      }
    });
  }

  setPageVisible() {
    this.selectedSlide = this.subtitles[this.currentPage-1];
  }

  updateTag(tag: Tag, j: number) {
    this.blurTimeout = false;
    var t = Object.assign({}, tag);
    if(!t.value.trim() || !t.name.trim()) {
       this.showAlert(AlertTypeEnum['warning'], "Subtitle or LOQ is Empty");
      this.blurTimeout = true;
      return;
    }

    if(t.name.trim() === "AddNewSubtitle" && t.value.trim() === "AddNewLOQ") {
       this.showAlert(AlertTypeEnum['warning'], "Modify both LOQ and subtitle, then add");
      this.blurTimeout = true;
      return;
    }

    if(t.name.trim() === "AddNewSubtitle") {
       this.showAlert(AlertTypeEnum['warning'], "Modify subtitle, then add");
      this.blurTimeout = true;
      return;
    }

    if(t.value.trim() === "AddNewLOQ") {
       this.showAlert(AlertTypeEnum['warning'], "Modify LOQ, then add");
      this.blurTimeout = true;
      return;
    }

    t.hashTag = escape(unescape(t.hashTag).trim());
    t.name = escape(unescape(t.name).trim());
    t.value = escape(unescape(t.value).trim());

    if(t.id.trim() !== "") {
        this.tagService.updateTag(t).subscribe(
           () => {
              this.showAlert(AlertTypeEnum['warning'], "Updated");
              this.blurTimeout = true;
              return
           },
            (err: any) => {
             console.log(err);
             this.blurTimeout = true;
             return
           });
    }

    if(t.id.trim() === "") {
        this.tagService.addTag(t)
        .subscribe((t: any) => {
           this.showAlert(AlertTypeEnum['warning'], "Saved");
           if (this.slides[unescape(tag.name.toLowerCase())]) {
             this.slides[tag.name.toLowerCase()][j].id = t.id;
             this.blurTimeout = true;
           } else {
            this.getTopicTagsEx();
           }
           return;
        },
        (err: any) => {
           console.log(err);
           this.blurTimeout = true;
           return;
        });
      }
  }

  updateTagName(tag: Tag){
    if(tag.name.trim() === "AddNewSubtitle") {
      this.showAlert(AlertTypeEnum['warning'], "Modify subtitle, then add");
      return;
    }

    for (let i = 0; i < this.slides[this.selectedSlide].length; i++) {
      this.slides[this.selectedSlide][i].name = tag.name;
      if(this.slides[this.selectedSlide][i].value.trim() && this.slides[this.selectedSlide][i].value === "AddNewLOQ") {
        this.showAlert(AlertTypeEnum['warning'], "Modify LOQ, then add");
        return;
      }
      this.updateTag(this.slides[this.selectedSlide][i], i);
    }
  }

  deleteTag(tag: any){
    if(this.editable) {
      this.openModalEx(this.modalTemplate, tag);
    }
  }

  cloneTag(tag: any){
    if(this.editable) {
      if(tag && tag.name.length < 0 || tag.value .length < 0) {
        return;
      } else if(tag && tag.name.length > 0 && tag.value.length > 0){
        //TODO: Configurable
        var clone = Object.assign({},tag);
        clone.id = "";
        if(this.slides[tag.name.toLowerCase()]) {
          clone.checked = true;
          clone.value = "AddNewLOQ";
          this.tags.push(clone);
          this.redraw();
        }
      }
    }
  }

  newLOQ(){
    if(this.editable) {
      var clone = new Tag("", '', '', '', '')
        clone.id = "";
        clone.topicId = this.topic.id;
        clone.userId = this.topic.userId;
        clone.hashTag = this.topic.hashTag;
        clone.source = this.theUser;
        clone.name = "AddNewSubtitle";
        clone.value = "AddNewLOQ";
        clone.email = this.theUser;
        clone.checked = true;
        this.tags.push(clone);
        this.redraw();
        this.routeToPageSection('end');
    }
  }

redraw() {
    this.slides = [];
    this.subtitles = [];
    ///
    this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
    for (let i = 0; i < this.tags.length; i++) {

        this.tags[i].hashTag = unescape(this.tags[i].hashTag);
        if (!this.tags[i].name) {
            this.tags[i].name = this.tags[i].hashTag;
        }

        if (this.tags[i].value.length > 0 && this.tags[i].name.length > 0) {
            this.tagsCount++;
            this.tags[i].name = unescape(this.tags[i].name);
            this.tags[i].value = unescape(this.tags[i].value);
            if (this.classTag && this.classTag === this.tags[i].id) {
                this.classTag = this.tags[i].name;
            }
            this.mapTags(this.tags[i]);
        }
    }

    this.subtitlesCount = 0;
    this.addValueToName("Synopsis", new Tag(this.model.hashTag, 'Synopsis', window.location.href, "", this.topic.id));

    for (let i = 0; this.subtitles && i < this.subtitles.length; i++) {
        if (this.subtitles[i].toLowerCase() !== this.topic.hashTag.toLowerCase()) {
            this.subtitlesCount++;
        }

        var clone = Object.assign({}, this.slides[this.subtitles[i]][0]);
        clone.value = "";
        this.slides[this.subtitles[i]].unshift(clone);
        if (this.slides[this.subtitles[i]][0].name !== "") {
            this.addValueToName("Synopsis", new Tag(this.model.hashTag, '', window.location.href, this.slides[this.subtitles[i]][0].name, this.topic.id));
        }
    }

    //Cleanup untitled loqs
    //TODO: Verify
    if (this.tags[0]) {
        var ix = this.subtitles.indexOf(this.tags[0].hashTag.toLowerCase());
        if (ix > 0) {
            var tt = this.subtitles.splice(ix, 1);
            this.subtitles.unshift(this.tags[0].hashTag.toLowerCase());
        }
    }

    this.addValueToName("Synopsis", new Tag(this.model.hashTag, this.userSVC.authUser.userId, window.location.href, "Appendix", this.topic.id));
    this.subtitles.unshift("Synopsis");

    //To be consistent with synopsis and other subtitles
    this.subtitles.push("Appendix");
    this.addValueToName("Appendix", new Tag(this.model.hashTag, "Appendix", window.location.href, "", this.topic.id));
    for (let i = 0; this.utags && i < this.utags.length; i++) {
        if (this.utags[i]) {
            this.addValueToName("Appendix", new Tag(this.model.hashTag, this.userSVC.authUser.userId, window.location.href, this.utags[i], this.topic.id));
        }
    }

    this.totalCount = this.subtitles.length;
    this.pageSize = this.totalCount;
    ///
    this.ready = true;
}
////////////// below belongs to doc //////////


  editTag(tag: any) {
    this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, this.topic.id);
    tag.edit = !tag.edit;
  }

  shareLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can share!");
      return;
    }

    this.router.navigate(['/admin/share'],  { queryParams: { h : this.topic.hashTag}});
  }

  branchLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can clone!");
      return;
    }
    this.router.navigate(['/admin/branch'],  { queryParams: { h : this.topic.hashTag}});
  }

  publicLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can publish!");
      return;
    }

    if(this.topic.topics.indexOf(this.topic.hashTag+"_public") >= 0) {
      this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      return;
    }

    var lx = this.topic.hashTag.lastIndexOf("_public");
    if( lx == this.topic.hashTag.length-"_public".length) {
      //TODO: Check if this belong to public user
      this.router.navigate(['/public'],  { queryParams: { h : escape(this.topic.hashTag.substring(0, lx)), d : window.btoa(this.topic.email)}});
      return;
    }

    this.openModal(this.modalTemplate, "md", "Publish LOQUMENT", "Want to publish?", () => {
      this.atomicPublishTopic(escape(this.topic.hashTag), this.topic.email, (t) => {
        this.router.navigate(['/public'],  { queryParams: { h : t.hashTag, i : t.id, d : window.btoa(t.email)}});
      });
    });
  }

deloq() {
    this.router.navigate(['/admin/deloq'],  { queryParams: { i : this.topic.id}});
    return;
  }

review() {
    this.router.navigate(['/admin/review'],  { queryParams: { h : this.model.hashTag}});
    return;
  }

  pressLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can press release!");
      return;
    }

    if(this.topic.topics.indexOf(this.topic.hashTag+"_press") >= 0) {
      this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      return;
    }

    var lx = this.topic.hashTag.lastIndexOf("_press");
    if( lx == this.topic.hashTag.length-"_press".length) {
      //TODO: Check if this belong to public user
      this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag.substring(0, lx)), d : window.btoa(this.topic.email)}});
      return;
    }

    this.openModal(this.modalTemplate, "md", "Press Release LOCUMENT", "Want to press release?", () => {
      this.atomicPressTopic(escape(this.topic.hashTag), this.topic.email, () => {
        this.router.navigate(['/release'],  { queryParams: { h : escape(this.topic.hashTag), d : window.btoa(this.topic.email)}});
      });
    });
  }

  renameTopic(){
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can rename!");
      return;
    }

    if (this.model.hashTag === "") {
      return;
    }

    var t = this.model.hashTag.trim();
    if (t === "") {
      return;
    }

    this.topic.hashTag = escape(unescape(this.topic.hashTag));
    //TODO: Remove this hack
    //Or, is it a good idea to keep and follow?!
    this.topic.value = escape(unescape(t.replace(/<[^>]*>/g, '')));
    this.topic.email = this.theUser;
    this.topicService.renameTopic(this.topic).subscribe(
      () => {
          this.showAlert(AlertTypeEnum['warning'], "Topic is renamed!");
          this.editable = false;
      },
       (err: any) => {
        console.log(err);
      });
    this.data.changeMessage(this.model.hashTag);
  }

  rankup(i: number){
    if (i > 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank - 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i-1].rank;
      this.tags[i-1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i-1];
      this.tags[i-1] = tag;
    }
  }

  rankdown(i: number){
    if (i < this.tags.length - 1) {
      var t1 = this.tags[i];
      t1.rank = t1.rank + 1;
      this.rankTag(t1)

      var t = this.tags[i].rank;
      this.tags[i].rank = this.tags[i+1].rank;
      this.tags[i+1].rank = t;

      var tag = this.tags[i];
      this.tags[i] = this.tags[i+1];
      this.tags[i+1] = tag;
    }
  }

  rankTag(tag: Tag){
    this.tagService.rankTag(tag).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  routeToPageSection(psec: string) {
    var e = document.getElementById(psec);
    if(e) {
      e.scrollIntoView({behavior: 'smooth'});
    }
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

/*
  cloneTopic(t: Topic, callback: () => any) {
    this.topicService.cloneTopic(t).subscribe(
      () => {
        callback();
      },
       (err: any) => {
        console.log(err);
      });
  }
  */

 atomicPublishTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.publishTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  atomicPressTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.pressTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  updateTopic(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  approve() {
    ;
  }

  export() {
    this.escapeIndex();
    this.printable = false;
    setTimeout(()=>{
    if(!this.editable) {
      window.print();
      this.printable = true;
       } else {
        this.showAlert(AlertTypeEnum['warning'], "Can't download during edit mode!");
       }
    }, 1000);
  }

  curbemail(email: string): string {
       if(!email) {
         return email;
       }

       var index = email.indexOf("@");
       if(index > 0) {
         return email.substring(0, index);
       }
    return email;
     }

   profileEx(h: any, d: any) {
    this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
    return;
  }

  addNewTopic(){
   var t = this.model.hashTag.trim();
    if (t === "") {
      return;
    }

    this.topic = new Topic('', '', '');
    this.topic.hashTag = escape(unescape(t.replace(/<[^>]*>/g, '')));
    this.topic.email = this.theUser;
    this.topic.users = [];
    this.topic.users.push(this.theUser);
    this.topicService.addTopic(this.topic).subscribe(
      (t: any) => {
        this.topic = t;
        this.topic.hashTag = unescape(this.topic.hashTag);
        this.model.hashTag = this.topic.hashTag;
        this.showAlert(AlertTypeEnum['warning'], "Topic is ready!");
        this.router.navigate(['/admin/loq'],  { queryParams: { h : this.topic.hashTag, i : t.id}});
      },
       (err: any) => {
        console.log(err);
      });
  }

  getContent(innerHTML){
    this.model.value = innerHTML.trim();
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + this.model.value.split(' ').slice(0,6).join(' '));
  }


  open(){
   var link = document.getElementById('upload');
   link.click();
  }

  import(){
   if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can upload SQB!");
      return;
    }
    this.router.navigate(['/admin/sqb'],  { queryParams: { h : this.model.hashTag}});

    return;
  }

     fileChangeEvent(fileInput:any){
          document.getElementById("loading").style.display = "inline";
          var currentFile = fileInput.target.files[0];
          var ht = currentFile.name.split(".slq")[0]
          let reader = new FileReader();
          reader.onload = () => {
            var tags = decompress(JSON.parse(reader.result as string));
            //var tags = JSON.parse(reader.result);
            if(tags.length > 0) {
              this.getTopic(escape(ht), this.theUser, (t: any) => {
                    if(!t.hashTag){
                     for (let i = 0; i < tags.length; i++) {
                       //alert(tags[i].name+" >>>>>>>>>>> "+tags[i].value);
                       if (!tags[i]) {
                          tags.splice(i, 1);
                        }
                          tags[i].hashTag = escape(unescape(ht));
                          tags[i].name = escape(unescape(tags[i].name));
                          tags[i].value = escape(unescape(tags[i].value));
                          tags[i].email = this.theUser;
                          tags[i].topicId = tags[i].topicId;
                          tags[i].userId = tags[i].userId;
                          tags[i].users = [];
                          tags[i].users.push = [this.theUser];
                          if(tags[i].ping) {
                            tags[i].ping = false;
                          }
                          if(tags[i].pong) {
                            tags[i].pong = false;
                          }
                      }
                     this.addTags(tags);
                    } else {
                     this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument does not indeed exist?");
                     document.getElementById("loading").style.display = "none";
                     this.router.navigate(['/admin/loq'],  { queryParams: { h : this.topic.hashTag, i : this.topic.id}});
                   }
              });
           } else {
             this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is empty or so?");
             document.getElementById("loading").style.display = "none";
             this.router.navigate(['/admin/loq'],  { queryParams: { h : this.topic.hashTag, i : this.topic.id}});
           }
          };
          reader.readAsText(currentFile);
    }

addTags(tags: Tag[]) {
    this.tagService.addTags(tags).subscribe(
     (t: any) => {
         this.model.value = "";
         document.getElementById("loading").style.display = "none";
         this.router.navigate(['/admin/loq'],  { queryParams: { h : t.hashTag, i : t.id}});
      },
       (err: any) => {
        document.getElementById("loading").style.display = "none";
        console.log(err);
      });
  }


  scrollToBottom() {
    const element = document.getElementById('loq');
    //element.scrollTop = element.scrollHeight;
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollToTop() {
    const element = document.getElementById('top');
    element.scrollTop = 0;
  }

  renderImage(t: any) {
  if(!t) {
       return t;
  }
  var s = t;
  //Removing Special Chars
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
       return t;
    }
       return s;
  }

  cloneTopic(t: Topic, callback: (t: any) => any){
         this.topicService.cloneTopic(t).subscribe(
          (data: any) => {
                 callback(data);
               },
            (err: any) => {
             console.log(err);
           });
       }

       cloneTopicToUser(t: Topic, callback: (t: any) => any){
       this.topicService.cloneTopicToUser(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

  slqIdTopicTags(topic: Topic, callback: (t: any) => any) {
    this.topicService.slqIdTopicTags(topic).subscribe(
      (t: any) => {
        callback(t);
      },
       (err: any) => {
        console.log(err);
      });
  }

     branchTopic(suffix: string){
       if (this.topic.hashTag === undefined) {
         return;
       }

         if(suffix === "hold") {
          this.openModal(this.modalTemplate, "md", "Submit Exam", "Want to submit for approval?", () => {
          this.showAlert(AlertTypeEnum['info'], "We are preparing the exam for approval.");
            document.getElementById("loading").style.display = "inline";
            var t = Object.assign({}, this.topic);
            t.hashTag = escape(unescape(t.hashTag));
            t.users = [];
            t.users.push(this.topic.email);
            t.users.push('hold.imoqr@siloq.com');
            this.updateTopic(t, (tt: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/exam'], { queryParams: { h : tt.hashTag, i : tt.id, t : "Hold", u : tt.users[1]}});
            });
          });
        }

         if(suffix === "loq") {
          this.openModal(this.modalTemplate, "md", "Reject Exam", "Want to reject this exam request?", () => {
          this.showAlert(AlertTypeEnum['info'], "Please wait while we are preparing release the hold.");
            document.getElementById("loading").style.display = "inline";
            var t = Object.assign({}, this.topic);
            t.hashTag = escape(unescape(t.hashTag));
            t.users = [];
            t.users.push(this.topic.email);
            this.updateTopic(t, (tt: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/topic']);
            });
          });
        }

       //TODO: WARNING - Only by APPROVAL
       if(suffix === "exam") {
        this.openModal(this.modalTemplate, "md", "Approve Exam", "Want to approve the exam?", () => {
          this.showAlert(AlertTypeEnum['info'], "Please hold on while we are preparing the exam.");
          document.getElementById("loading").style.display = "inline";
          var t = Object.assign({}, this.topic);
          t.hashTag = escape(unescape(t.hashTag));
          t.users = [];
          t.users.push(this.topic.email);
          t.users.push('finalized.imoqr@siloq.com');
            this.slqIdTopicTags(t, (t1: any) => {
                document.getElementById("loading").style.display = "none";
                this.router.navigate(['/admin/topic']);
            });
        });
      }

     if(suffix === "act") {
              var selected = false;
              for (let i = 0; i < this.tags.length; i++) {
                 if(this.tags[i].checked) {
                   selected = true;
                   break;
                 }
              }
              var tags = [];
              if (selected) {
                 let ht = prompt("New Act name for the selected LOQ(s): ");
                  if(ht === null) {
                   return;
                  }

                 if(ht && this.tags && this.tags.length > 0) {
                   document.getElementById("loading").style.display = "inline";
                   this.getTopic(escape(unescape(ht)), this.theUser, (t: any) => {
                         if(!t.hashTag){
                          for (let i = 0; i < this.tags.length; i++) {
                             if(this.tags[i].checked) {
                               this.tags[i].checked = false;
                               var clone = Object.assign({},this.tags[i]);
                               clone.hashTag = escape(unescape(ht));
                               clone.email = this.theUser;
                               tags.push(clone);
                             }
                           }
                          this.addTags(tags);
                         } else {
                          this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument does indeed exist?");
                          document.getElementById("loading").style.display = "none";
                          this.router.navigate(['/admin/loq'],  { queryParams: { h : this.topic.hashTag, i : this.topic.id}});
                         }
                   });
                } else {
                  this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is empty or so?");
                  document.getElementById("loading").style.display = "none";
                  this.router.navigate(['/admin/loq'],  { queryParams: { h : this.topic.hashTag, i : this.topic.id}});
                }
               //TODO: Add Tags to the new LOQ
              } else {
                this.showAlert(AlertTypeEnum['info'], "Please select at least 1 LOQ to create a new ACT.");
              }
          }

     if(suffix === "sub") {
              var selected = false;
              for (let i = 0; i < this.tags.length; i++) {
                 if(this.tags[i].checked) {
                   selected = true;
                   break;
                 }
              }
              var tags = [];
              if (selected) {
                 let nm = prompt("New Subtitle name for the selected LOQ(s): ");
                  if(nm === null) {
                   return;
                  }

                 if(nm && this.tags && this.tags.length > 0) {
                   document.getElementById("loading").style.display = "inline";
                    for (let i = 0; i < this.tags.length; i++) {
                       if(this.tags[i].checked) {
                          this.tags[i].checked = false;
                          var clone = new Tag("", "", "", "", "")
                          clone.hashTag = escape(unescape(this.tags[i].hashTag));
                          clone.name = escape(unescape(nm));
                          clone.value = escape(unescape(this.tags[i].value));
                          clone.email = this.theUser;
                          clone.topicId = this.tags[i].topicId;
                          clone.users = this.tags[i].users;
                          clone.userId = this.tags[i].userId;
                          clone.ping = false;
                          clone.pong = false;
                          clone.insertDate = this.tags[i].insertDate;
                         tags.push(clone);
                       }
                     }
                    this.addTags(tags);
                } else {
                  this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is empty or so?");
                  document.getElementById("loading").style.display = "none";
                }
              } else {
                this.showAlert(AlertTypeEnum['info'], "Please select at least 1 LOQ to create a new Subtitle.");
                document.getElementById("loading").style.display = "none";
              }
          }

         if(suffix === "split") {
             var selected = false;
             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                  selected = true;
                  break;
                }
             }

             var tags = [];
             if (!selected) {
                  this.showAlert(AlertTypeEnum['info'], "Please select at least 1 LOQ to split into LOQ(s).");
                  return;
             }

            if(this.tags && this.tags.length > 0) {
             document.getElementById("loading").style.display = "inline";
             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                  var loqs = this.tags[i].value.split('<br>');//(/\r\n|\r|\n/);
                  if (loqs.length <= 1) {
                     this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is a single paragraph?");
                     document.getElementById("loading").style.display = "none";
                     return;
                  }


                  for (let j = 0; j < loqs.length; j++) {
                      var clone = new Tag("", "", "", "", "")
                      clone.hashTag = escape(unescape(this.tags[i].hashTag));
                      clone.name = escape(unescape(this.tags[i].name));
                      clone.value = escape(unescape(loqs[j]));
                      clone.email = this.theUser;
                      clone.topicId = this.tags[i].topicId;
                      clone.users = this.tags[i].users;
                      clone.userId = this.tags[i].userId;
                      clone.ping = false;
                      clone.pong = false;
                      clone.insertDate = this.tags[i].insertDate;
                      tags.push(clone);
                  }
                  this.tagService.deleteTag(this.tags[i].id).subscribe( () => { });
                }
             }
             this.addTags(tags);
           } else {
             this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is empty or so?");
             document.getElementById("loading").style.display = "none";
           }
        }

         if(suffix === "merge") {
             var selected = false;
             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                  selected = true;
                  break;
                }
             }

             var slides = [];
             var subtitles = [];
             var tags = [];
             if (!selected) {
                  this.showAlert(AlertTypeEnum['info'], "Please select at least e LOQ(s) to merge into a new LOQ.");
                  return;
             }

          if(this.tags && this.tags.length > 0) {
             document.getElementById("loading").style.display = "inline";
             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                  var name =  this.tags[i].name.toLowerCase();
                  subtitles.indexOf(name.toLowerCase()) === -1 && subtitles.push(name.toLowerCase());
                  slides[name] = slides[name] || [];
                  if(slides[name].length <= 0) {
                    slides[name].push(this.tags[i]);
                    continue;
                  }
                  var t = slides[name][0];
                  t.value = t.value + "&nbsp;" + this.tags[i].value;
                  slides[name][0] = t;
                }
            }

             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                    this.tagService.deleteTag(this.tags[i].id).subscribe( () => { });
                }
            }

              for (let j = 0; j < subtitles.length; j++) {
                  var clone = new Tag("", "", "", "", "")
                  clone.hashTag = escape(unescape(slides[subtitles[j]][0].hashTag));
                  clone.name = escape(unescape(slides[subtitles[j]][0].name));
                  clone.value = escape(unescape(slides[subtitles[j]][0].value));
                  clone.email = this.theUser;
                  clone.topicId = slides[subtitles[j]][0].topicId;
                  clone.users = [this.theUser];
                  clone.userId = slides[subtitles[j]][0].userId;
                  clone.ping = false;
                  clone.pong = false;
                  clone.insertDate = slides[subtitles[j]][0].insertDate;
                  tags.push(clone);
              }

             this.addTags(tags);
           } else {
             this.showAlert(AlertTypeEnum['info'], "Please, check if Loqument is empty or so?");
             document.getElementById("loading").style.display = "none";
           }
        }

         //
         if(suffix === "delete") {
             var selected = false;
             for (let i = 0; i < this.tags.length; i++) {
                if(this.tags[i].checked) {
                  selected = true;
                  break;
                }
             }

             var slides = [];
             var subtitles = [];
             var tags = [];
             if (!selected) {
                  this.showAlert(AlertTypeEnum['info'], "Please select at least 1 LOQ to be deleted.");
                  return;
             }

          this.openModal(this.modalTemplate, "md", "Delete LOQ(s)", "Want to delete the selected LOQ(s)?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please wait while we are deleting the selected LOQ(s).");
            document.getElementById("loading").style.display = "inline";
            if(this.tags && this.tags.length > 0) {
               document.getElementById("loading").style.display = "inline";
               for (let i = 0; i < this.tags.length; i++) {
                  if(this.tags[i].checked) {
                      this.tagService.deleteTag(this.tags[i].id).subscribe( () => { });
                  }
               }
               document.getElementById("loading").style.display = "none";
               this.router.routeReuseStrategy.shouldReuseRoute = () => false;
               this.router.navigate(['/admin/loq'], { relativeTo: this.activatedRoute, queryParams: { h : this.topic.hashTag, i : this.topic.id}});
             } else {
               this.showAlert(AlertTypeEnum['info'], "Please, check if selected LOQ(s) is/are empty or so?");
               document.getElementById("loading").style.display = "none";
             }
           });
        }
   }

     jsunescape(t: string) {
     return unescape(t)
     }

     validateEmail(email: any) {
         const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
       }

      saveAll() {
        if(this.tags && this.tags.length > 0) {
           document.getElementById("loading").style.display = "inline";
           for (let i = 0; i < this.tags.length; i++) {
              if(this.tags[i].checked) {
                  //this.updateTag(this.tags[i]);
              }
          }
       }
     }


      downloadSlq() {
         this.showAlert(AlertTypeEnum['info'], "File will be automatically downloaded!");
         var sqb = JSON.stringify(compress(this.tags));
         //var sqb = JSON.stringify(this.tags);

         if(sqb.length > 0) {
           this.download(unescape(this.topic.hashTag)+".slq", sqb);
         }
      }

    download(filename: string, text: string) {
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }

   setState(event: any, tag: Tag) {
      if(tag.value === "") {
        var key = tag.name.toLowerCase();
        for (let i = 1; i < this.slides[key].length; i++) {
          this.slides[key][i].checked=event.target.checked;
        }
      } else {
        tag.checked=event.target.checked;
      }
      event.stopPropagation();

      return;
   }

   setSubState() {
    if(!this.editable) {
      this.edit=!this.edit;
      }
   }

   setSubStateAll(event: any) {
      event.target.checked=!event.target.checked;
      this.edit=!this.edit;
   }

    initNewLOQ() {
      this.editable=!this.editable;
      if(this.editable && this.tags.length <= 0) {
        this.newLOQ();
      }
    }

    line() {
     this.showcol=!this.showcol;
    }
}

