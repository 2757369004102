<admin-header></admin-header>
<body *ngIf="ready" style="margin:0px;background-color:white; height:100%; width:100%; padding: 2px 0% 0px 0%;">
  <form method="post">
    <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:-7px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" title="Merge Activities" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;width:96px;" class="siloqbutton" (click)="merge();" >Merge Acts</a>
        <a *ngIf="(topics && topics.length > 1) && !edit" (click)="setStateAll($event);" [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'green'" (mouseout)="backgroundColor2 = 'mediumseagreen'" title="Merge Activities" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:mediumseageen;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;width:96px;" class="siloqbutton" ><span style="margin-top:-5px;vertical-align:top;">Show </span><span class="fa fa-check-square-o"  style="margin:-5px 0px 0px 0px;padding:0px;"></span></a>
        <a *ngIf="(topics && topics.length > 1) && edit" (click)="setStateAll($event);" [style.backgroundColor]="backgroundColor3"  (mouseover)="backgroundColor3 = 'orange'" (mouseout)="backgroundColor3 = 'orangered'" title="Merge Activities" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:orangered;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;width:96px;" class="siloqbutton" ><span style="margin-top:-5px;vertical-align:top;">Hide </span><span class="fa fa-check-square-o"  style="margin:-5px 0px 0px 0px;padding:0px;"></span></a>
      </div>
    </div>
    <admin-alert></admin-alert>
    <ng-template #modalTemplate>
    </ng-template>
<!--    <div class="doNotPrint" style="display:table;width:100%; height:18px; margin-top:60px; valign:middle;padding-top:50px;">-->
<!--      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;"> </div>-->
<!--      <div style="padding-bottom:10px;display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0;color:#617CB5;font-size:1em;font-weight:600;vertical-align: middle;"></div>-->
<!--      <div title="Sort By" style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">-->
<!--      </div>-->
<!--    </div>-->

    <div style="margin-top:60px; padding-top:50px;width:45%;margin-left:auto;margin-right:auto; display:flex;justify-content:center;align-items:center;"></div>
    <div *ngIf="!showAuto" style="width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
      <div *ngIf="!showAuto" style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; color:#617CB5;height:23px;text-align:center;" >
      <span *ngIf="topics && topics.length > 0" style="display: inline-block;">
        ACTs({{totalCount | number:'1.0-0'}}+)
      </span>
        <span  *ngIf="topics === undefined" style="display: inline-block; text-align:center;">
        Working...
      </span>
        <span  *ngIf="topics && topics.length === 0" style="display: inline-block;color:orangered;">
        No ACTs
      </span>
      </div>
    </div>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://isloq.com" >&nbsp;<img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a>&nbsp;<span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span></a></div>
    <!--  Search      -->
    <div style="padding:0px;width:45%;margin-left:auto;margin-right:auto; display:flex;justify-content:center;align-items:center;">
      <input class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure {{taxonomy}} Search [Min 3 Chars]" name="siloqSearch" #siloqSearch (input)="auto()" [(ngModel)]='searchText' style="color: grey;padding:7px;height: 36px; width:100%;min-width:200px;margin:0px;"/>&nbsp;
      <span style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;font-size:2.3em;font-weight:400;padding:0px 0px 20px 0px;height:36px;"></span>
    </div>
    <div *ngIf="showAuto" style="margin-left:auto;margin-right:auto;margin-top:0 auto; width:44%;padding:0px;">
      <ul *ngIf="showAuto" style="margin-top:0px; list-style: none;display:table; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;display:inline-block;font-size:1em;border:3px lightsteelblue solid;padding: 5px; width:100%">
        <li *ngFor="let auto of ptopics; let i = index" style="-moz-border-radius: 15px; border-radius: 15px;" (click)="selectAuto(auto, i)" [class.active]="i === selectedIndex">
          <div style="cursor:pointer;-moz-border-radius: 15px; border-radius: 15px;font-size:1em;text-align: left;padding:5px;">{{auto.hashTag}}</div>
        </li>
      </ul>
    </div>
    <!---->

    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://siloq.com" style="color:dodgerblue;" >&nbsp;Powered By <img src="/assets/siloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;</span></a> </div>


<div (dblclick)="edit=!edit;" id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">



  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
      <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
    </div>
      <div style="display: inline-block;font-weight:600;color:#617CB5;">
      Page {{currentPage}} of {{pageCount | number:0}}
    </div>
    <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
      <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
    </div>
  </div>


    <div *ngIf="(selectedTopics && selectedTopics.length > 0)" class="container2 siloqfont" style="margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;width:90%;">

      <div *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">
        <div *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show"  valign="top">
          <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px; cursor:pointer;">

            <div  style="background-color:whitesmoke;width:100%;min-height:20px; margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;">
              <div *ngIf="edit===true" style="text-align:right;min-width:60px;">
                <input  class="doNotPrint" type="checkbox" style="float:left;width: 13px; height: 13px; vertical-align: top; position: relative; top: -2px; *overflow: hidden;padding:0px;font-weight:300;font-size:.75em;padding:10px 0px 0px 0px; margin-right:10px;" (click)="setState($event.target, topic);"/>
              </div>
              <div  (click)='docTopic(topic)'  style="float:left; text-align:left;width:80%;border-bottom:1px solid lightgrey;padding:0px 8px 8px 0px;">
                <span  style="height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="View Topic {{topic.hashTag}}"  [innerHTML]="parseTitle(topic.hashTag) | truncate : 64"></span>
              </div>
              <div style="text-align:right;min-width:60px;">
                <input *ngIf="theUser===topic.email" class="intLink" type="button" value="✕" valign="middle" (click)='archiveTopic(topic)' title="Archive {{topic.hashTag}}" style="vertical-align:middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
                <input class="intLink" type="button" value="❐" valign="middle" (click)='docTopic(topic)' title="View Topic" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
              </div>
            </div>

            <div style="vertical-align:top;margin:0px;text-align:left;padding:2px 5px 5px 5px;" >
              <div style="display:inline-block;width:100%;">
                <span class="doNotPrint" (click)='docTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;width:30%;text-align:right;">
      <a class="fa fa-backward first" *ngIf='currentPage>1' (click)="first()" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="previous()" ></a>
    </div>
      <div style="display: inline-block;font-weight:600;color:#617CB5;">
      Page {{currentPage}} of {{pageCount | number:0}}
    </div>
    <div style="display: inline-block;font-weight:600;width:30%;text-align:left;">
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="next()"></a>
      <a class="fa fa-forward last" *ngIf='currentPage<pageCount' (click)="last()"></a>
    </div>
  </div>
  <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://sisloq.com"  style="color:dodgerblue;" >&nbsp;Powered By&nbsp;<img src="/assets/sisloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:orangered;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;"></span></a> </div>
</div>
<br>
<br>
<br>
<br>
<admin-footer *ngIf="ready"></admin-footer>
