import { Component, OnInit } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import {TopicService} from '../services/topic.service';
import {Topic} from '../admin/topic/model/topic';
import {TagService} from '../services/tag-service.service';
import {Tag} from '../admin/tag/model/tag';
import { config } from '../config';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import {User} from '../admin/user/model/user';
import {DataService } from "../services/data.service";
import {Title} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../admin/tag/tag.truncate.pipe';
import { Pipe, PipeTransform, NgModule } from '@angular/core';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  imports: [CommonModule, AlertComponent, FormsModule, RouterModule, HomeFooterComponent, HeaderComponent, TruncatePipe],
  standalone: true
})

export class HomeComponent implements OnInit {
  ptopics: Topic[] = [];
  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  public selectedAuto;
  public selectedTopic;
  searchText = "";
  oldSearchText = "";
  selectedIndex = 0;
  show = true;
  showAuto = false;
  currentPage = 0;
  pageSize = 10;
  pageDirection = -1;
  totalCount = 0;
  pageCount = 0;
  working = true;
  counter = 0;
  tin = "";
  sortKey = "-rank";
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public zone = "";
  public theUser = "";
  public theGoldiUser = "";
  loggedinUser= "";
  host = window.location.protocol+"//"+window.location.hostname;
  user = new User("", "", "");
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  private model: Topic = new Topic('', '', '');
  public publicSearchTopics: Topic[] = [];
  public backgroundColor  = "gold";
  public chromeExtension = "";
  public ready = false;

  @HostListener('document:click')
  clickout() {
    this.showAuto = false;
  }

  constructor(private titleService: Title,  private alertService: AlertService, private data: DataService, private topicService: TopicService, private tagService: TagService, private activatedRoute: ActivatedRoute, private router: Router, public userSVC: UserService, private _cookieService:CookieService){}

  ngOnInit(){
    this.identity = config.identity;
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.zone = config.zone;
    this.loggedinUser = this.getCookie("email");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.chromeExtension = config.chromeExtension;
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentPage = parseInt(params['p']) || 1;
      this.searchText = params['q'] || "";
      if(this.searchText) {
        this.setFirstPage();
      } else {
     }

      this.theUser = params['d'] || this.loggedinUser;
    });

      this.loadTopics();
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  setCookie(key: string, value: string){
    return this._cookieService.set(key, value);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  signup(){
    this.router.navigate(['signup']);
  }

  signin(){
    this.router.navigate(['login']);
  }

  forgot(){
    this.router.navigate(['forgot']);
  }

  auto() {
        //Use a queue for managing in-flight requests
        var timeoutId = setTimeout(()=> {

          this.showAuto = false;
          if (this.searchText.length > 2) {

            this.ptopics = new Array();
            this.topicService.mockSearchTopics(escape(this.searchText), this.sortKey, "9")
                     .subscribe(
                       ptopics => {
                       if(ptopics !== undefined) {
                          this.ptopics = ptopics; //Bind to view

                          var i = 0;
                          for (;i < 10 && i < this.ptopics.length;) {
                            this.ptopics[i].show = true;
                            this.ptopics[i].hashTag = unescape(this.ptopics[i].hashTag);
                            i++;
                          }

                          if(i > 0) {
                            this.showAuto = true;
                          }
                        }
                      },
                       (err: any) => {
                        this.showAuto = false;
                        console.log(err);
                      });
          } else {
            this.ptopics = new Array();
            this.showAuto = false;
          }

      }, 1000/60);
      //	clearTimeout(timeoutId);
  }

  public selectAuto( topic: any, i: number) {
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.searchText = topic.hashTag;
    this.docTopic(topic);
    //TODO:
    /*
    if(topic.users.indexOf("mock.imoqr@siloq.com") > 0) {
      this.router.navigate(['/admin/mock'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }

    if(topic.users.indexOf("exam.imoqr@siloq.com") > 0) {
          this.router.navigateByUrl('admin/exam?h='+topic.hashTag+'&d='+btoa(topic.email));
    } else if(this.userSVC.authUser.email) {
      this.router.navigateByUrl('/admin/doc?h='+topic.hashTag+'&d='+btoa(this.userSVC.authUser.email));
    }
    */
    this.showAuto = false;
  }

  public onDown() {
      this.searchText = this.selectedAuto.Name;
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.searchText = this.selectedAuto.Name;
    }
  }

  setSiloqPage() {
    if(this.searchText.length > 0) {
      this.router.navigateByUrl('/search?q='+this.searchText+'&l='+this.tin);
    }
  }
  setSisloqPage() {
    if(this.searchText.length > 0) {
      this.router.navigateByUrl('/graph?q='+this.searchText+'&l='+this.tin);
    }
  }

  public setFirstPage() {
    if(this.searchText.length > 0) {
      if (!this.userSVC.authUser.email) {
          this.showAlert(AlertTypeEnum['info'], "Please login");
      } else {
        this.router.navigateByUrl('/admin/mocks?q='+this.searchText);
      }
    }
  }

  ////////////
  loadPublicTopics() {

       if (this.publicSearchTopics && this.publicSearchTopics.length > 0) {
            this.topics = [];
            for (let i = 0; i < this.publicSearchTopics.length; i++) {
              if(this.publicSearchTopics[i].hashTag) {
                  this.topics.push(this.publicSearchTopics[i]);
              }
            }
            this.setPageVisible();
            return;
       }

      this.topicService.publicSearchTopics(this.searchText, this.sortKey, "3")
        .subscribe(
          ptopics => {
            this.ptopics = ptopics; //Bind to view
            if(this.ptopics !== undefined) {
            this.topics = [];
              for (let i = 0; i < this.ptopics.length; i++) {
                if(this.ptopics[i].hashTag) {
                    this.topics.push(this.ptopics[i]);
                }
              }
              this.setPageVisible();
            }
          },
           (err: any) => {
            console.log(err);
          });
    }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(i >= this.selectedIndex && i < end) {
        this.topics[i].show = true;
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.selectedTopics.push(this.topics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
//     if (this.selectedTopic) {
//       this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
//       this.selectedTopic.rank = this.selectedIndex + 1;
//     }
    this.ready = true;
  }

 @HostListener('keydown', ['$event'])
  keyDown(event: any) {
      switch (event.keyCode) {
        case 38:
          this.onUp();
          break;
        case 40:
          this.onDown()
          break;
        case 27:
          this.showAuto = false;
          break;
        case 13:
          if(this.searchText.length > 0) {
            this.setFirstPage();
            break;
          }
          if(this.theUser) {
                this.loadTopics();
              } else {
                this.loadPublicTopics();
              }
            break;
          }
      return;
    }

    public selectTopic( topic: any, i: number) {
      topic.rank = i + 1;
      this.selectedIndex = i;
      this.selectedTopic =  topic;
      this.data.changeMessage(this.selectedTopic.hashTag);
    }

  loadTopics() {
    this.model = new Topic('', '', '');
    this.topicService.mockSearchTopics(escape(this.searchText), this.sortKey, "3")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  docTopic(topic: any) {
    if(this.isGoldimembers(topic.taxonomy) && this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }

    if(this.isGoldimembers(topic.taxonomy) && (!this.theGoldiUser || this.theGoldiUser === 'null')) {
      this.router.navigate(['/admin/goldimembers']);
    }

    if(!this.isGoldimembers(topic.taxonomy)) {
      this.router.navigate(['/admin/mocks'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }
  }

    publicTopic(topic: any) {
      var lx = topic.hashTag.lastIndexOf("_public");
      this.router.navigate(['/public'],  { queryParams: { h : topic.hashTag.substring(0, lx), d : window.btoa(topic.email)}});
    }

profile(t: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(t.email)}});
    return;
  }

    myTopic(h: any) {
      this.router.navigate(['/admin/topic'],  { queryParams: {d : window.btoa(this.loggedinUser), h : h}});
    }

    getParentTopic(topic: any): string {
      var lx = topic.hashTag.lastIndexOf("_public");
      if (lx >= 0) {
       return topic.hashTag.substring(0, lx);
      }

      lx = topic.hashTag.lastIndexOf("_press");
       if (lx >= 0) {
           return topic.hashTag.substring(0, lx);
          }

      lx = topic.hashTag.lastIndexOf("_mock");
       if (lx >= 0) {
           return topic.hashTag.substring(0, lx);
          }

      return topic.hashTag;
    }

    getUserByEmail() {
      this.userSVC.getUserByEmail(this.theUser)
        .subscribe(
          (user: any) => {
            if(user!=undefined){
              this.user = user;
            }
          },
           (err: any) => {
            console.log(err);
          });
    }

    convertToId(source: string): string {
      return source.replace(/ /g, "-");
    }

    cutUntil(tag: Tag): string {
      var index = tag.source.indexOf("#");
      if(index > 1) {
        var url = tag.source.substring(0, index);
        return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
      } else {
        return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
      }
    }

    curbemail(email: string): string {
      var index = email.indexOf("@");
      if(index > 0) {
        return email.substring(0, index);
      }
        return email;
    }

      renderImage(t: any) {
        var s = t;
        t = t.replace(/<(?:.|\n)*?>/gm, '');
         if (t.startsWith("http://") || t.startsWith("https://")) {
             const img = new Image();
             img.src = t;
             var w = 60;
             var h = (w/img.width)*img.height;
             t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
             return t;
          }
             return s;
        }

    parseTitle(t: string): string {
            return t.replace(/_/g, " ");
        }

    help(h: any) {
      this.router.navigate(['publish'],  { queryParams: {h:this.taxonomy+'-help'}});
    }

    isGoldimembers(tax): boolean {
       if(tax) {
           for (let i = 0; i < tax.length; i++) {
             if(tax[i].key === "goldimembersEnabled") {
               return (tax[i].value[0] === "true");
             }
           }
       }
       return false;
    }

    first() {
      if(this.currentPage>1){
        this.currentPage=1;
        this.setPageVisible();
      }
    }

    previous() {
      if(this.currentPage>1){
        this.currentPage-=1;
        this.setPageVisible();
      }
    }

    next() {
      if(this.currentPage<this.pageCount){
        this.currentPage+=1;
        this.setPageVisible();
      }
    }

    last() {
      if(this.currentPage<this.pageCount){
        this.currentPage=this.pageCount;
        this.setPageVisible();
      }
    }

    goldimembers() {
              if(this.userSVC.authUser.email) {
                if(this.theGoldiUser && this.theGoldiUser !== 'null') {
                  this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
                } else {
                  this.router.navigate(['/admin/goldimembers']);
                }
              } else {
                  this.router.navigate(['/goldimembers']);
              }
            }

  goldiAngels() {
          if(this.userSVC.authUser.email) {
              this.router.navigate(['/admin/goldiangels']);
          } else {
              this.router.navigate(['/goldiangels']);
          }
        }

    refer() {
        this.copyTinyUrlToClipboard();
        this.showAlert(AlertTypeEnum['success'], "The referral URL is copied to the clipboard, Paste anywhere!");
        return;
    }

    public copyTinyUrlToClipboard() {
      var dummy = document.createElement('input');
      document.body.appendChild(dummy);
   dummy.value = window.location.href;
      dummy.value = "https://www.goldimocks.com";
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }

  getTaxon(t: Topic, x: string) {
        if(t && t.taxonomy) {
          for (let i = 0; i < t.taxonomy.length; i++) {
             if (t.taxonomy[i].key === x) {
                switch (x) {
                  case "random":
                    return +t.taxonomy[i].value[0];
                  case "timeDuration":
                    return +t.taxonomy[i].value[0];
                  case "startTime":
                    return t.taxonomy[i].value[0];
                  case "pageSize":
                    return +t.taxonomy[i].value[0];
                  case "rightQuestionReward":
                    return +t.taxonomy[i].value[0];
                  case "wrongQuestionPenalty":
                    return +t.taxonomy[i].value[0];
                  case "audioEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "multiColEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "goldimembersEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "rentPrice":
                    return +t.taxonomy[i].value[0];
                  case "buyPrice":
                    return +t.taxonomy[i].value[0];
                  case "rank":
                    return +t.taxonomy[i].value[0] || 0;
                }
            }
          }
        }
        return ""
  }
}
