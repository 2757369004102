import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TagService} from '../../services/tag-service.service';
import {Tag} from '../tag/model/tag';
import {TopicService} from '../../services/topic.service';
import {SiloqpayService} from '../../services/siloqpay.service';
import {Topic} from '../topic/model/topic';
import {Taxon} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

declare function unescape(s:string): string;
declare function escape(s:string): string;

@Component({
  selector: 'app-mocks',
  templateUrl: './mocks.component.html',
  styleUrls: ['./mocks.component.css'],
  imports: [CommonModule, AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, TruncatePipe],
  standalone: true
})

export class MocksComponent implements OnInit {

  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  golditopics: Topic[] = [];
  redeemtopics: Topic[] = [];
  public showAuto: boolean;
  private model: Topic = new Topic('', '', '');
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = -1;
  searchText = "";
  currentPage: number = 1;
  pageSize = 9;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-rank";
  selectAll = false;
  public theUser = "";
  public theGoldiUser = "";
  host = window.location.protocol+"//"+window.location.hostname;
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  user = "mock.imoqr@siloq.com";
  public title = "";
  public ptopics: Topic[] = [];
  public selectedAuto;
  public id = "";
  public ready = false;

  @HostListener('document:click')
  clickout() {
    this.showAuto = false;
    this.searchText = "";
    //TODO: Later for navigation slides
    //this.setCurrentPage(1);
  }

  constructor(private tagService: TagService, private topicService: TopicService, private siloqpayService: SiloqpayService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo : Login, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){
    loginInfo = new Login();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  archiveModal(modalTemplate: TemplateRef<any>, topic: any) {
      this.modalService
        .open(modalTemplate, { size: 'md', title: 'Archive', message: "Want to delete the selected item?" })
        .subscribe((action) => {

        var clone = Object.assign({}, topic);
        clone.hashTag = escape(unescape(clone.hashTag));
        this.topicService.archiveTopic(clone).subscribe(
          () => {
            this.model = new Topic('', '', '');
            this.setFirstPage();
            this.showAlert(AlertTypeEnum['info'], "Deleted.");
          },
           (err: any) => {
            console.log(err);
          });
        });
    }

    archiveTopic(topic: Topic){
        let commentOperation:Observable<Topic[]>;
        this.archiveModal(this.modalTemplate, topic);
    }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
    this.pageTitle = "MOCK";

    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params['i'] || "";
      this.searchText = params['q'] || "";
            if(this.searchText) {
              this.searchText = unescape(this.searchText);
            }
      this.currentPage = parseInt(params['p']) || 1;
      this.user = params['u'] || "mock.imoqr@siloq.com";
      this.title = params['t'] || "MOCK";
      this.pageTitle = this.title
    });
    if(this.id !== "") {
          this.getTopicById(this.id, (t: any) =>  {
          this.mockExamSilentRequest(t)
        });
     } else {
      if(this.title === "REDEEM") {
        this.searchRedeemMocks();
      } else if(this.title === "GOLD") {
        this.searchGoldimembersMocks();
      } else if(this.title === "REMOCK") {
        this.searchMyMocks();
      } else {
        this.setCurrentPage(this.currentPage);
      }
    }
  }

  loadTopicsEx(callback: () => any) {
    this.model = new Topic('', '', '');
    this.topicService.examSearchTopics(escape(this.searchText), this.sortKey, this.user, this.theUser, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
            this.ready = true;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  loadTopics(callback: () => any) {
  //TODO: Load for GoldiMembers
    this.model = new Topic('', '', '');
    this.topicService.mockSearchTopics(escape(this.searchText), this.sortKey, "128")
      .subscribe(
        (topics: any) => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            callback();
            this.ready = true;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.theUser));
  }

 @HostListener('keydown', ['$event'])
 keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 27:
        this.showAuto = false;
        this.searchText = "";
        this.setCurrentPage(1);
        break;
      case 13:
        this.setCurrentPage(1);
        this.showAuto = false;
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
    }
  }

  updateTopic(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  setTaxon(t: Topic, x: Taxon) {
      if(t) {
        for (let i = 0; i < t.taxonomy.length; i++) {
          if (t.taxonomy[i].key === x.key) {
             t.taxonomy[i].value[0] = x.value[0];
          }
        }
      }
  }

  mockExamSilentRequest(topic: Topic) {
    if(this.isGoldimembers(topic.taxonomy) && (!this.theGoldiUser || this.theGoldiUser === 'null')) {
      this.router.navigate(['/admin/goldimembers']);
      return;
    }
        document.getElementById("loading").style.display = "inline";
        if(topic.email !== this.theUser) {
          ++topic.rank;
          this.updateTopic(topic, (t: any) => {
            topic = t;
          });
         }
         //
       var  tt = Object.assign({}, topic);
       tt.hashTag = escape(unescape(topic.hashTag));
       tt.id = topic.id;
       tt.rank = topic.rank;
       tt.email = this.theUser;
       var u = tt.users[0];
       tt.users = [];
       tt.users.push(u);
       tt.users.push('mock.imoqr@siloq.com');
       tt.users.push(this.theUser);
       this.mockRequest(tt, (t: any) => {
        document.getElementById("loading").style.display = "none";
        if(t.users && t.users.length > 1) {
          this.router.navigate(['/admin/mock'],  { queryParams: { h : t.hashTag, i : tt.id, ai : t.id, ui : t.users[1]}});
        }
      });
    }

  mockExamRequest(topic: Topic) {
      if(this.isGoldimembers(topic.taxonomy) && (!this.theGoldiUser || this.theGoldiUser === 'null')) {
        this.router.navigate(['/admin/goldimembers']);
        return;
      }
      document.getElementById("loading").style.display = "inline";
      if(topic.email !== this.theUser) {
        ++topic.rank;
        this.updateTopic(topic, (t: any) => {
          topic = t;
          this.showAlert(AlertTypeEnum['info'], "Hold on while we're bringing your Mock details");
        });
       }
      //

     var  tt = Object.assign({}, topic);
     tt.hashTag = escape(unescape(topic.hashTag));
     tt.id = topic.id;
     tt.email = this.theUser;
     var u = tt.users[0];
     tt.users = [];
     tt.users.push(u);
     tt.users.push('mock.imoqr@siloq.com');
     tt.users.push(this.theUser);
     this.mockRequest(tt, (t: any) => {
        document.getElementById("loading").style.display = "none";
        this.openModal(this.modalTemplate, "md", "Mock: Details", "Want to Review the Mock details?", () => {
        if(t.users && t.users.length > 1) {
          this.router.navigate(['/admin/mock'],  { queryParams: { h : t.hashTag, i : tt.id, ai : t.id, ui : t.users[1]}});
        }
      });
    });
    //TODO: Do not remove
    this.router.navigate(['/admin/mocks']);
  }

  docTopic(topic: any) {
    this.mockExamRequest(topic)
  }

redeem(topic: any) {
  if(topic.email === "tpalanisamy@yahoo.com") {
    this.siloqpayService.redeem("1.10", "USD", "tpalanisamy@yahoo.com")
    .subscribe( () => {
      this.showAlert(AlertTypeEnum['info'], "Your redeemed amount will show up in your bank account with in a week. Thank you for your business!");
    },
    (err: any) => {
    console.log(err);
    });

    return;
  }

    if(this.title === "REDEEM"){
      if (+this.getTaxon(topic, "rank") < 1000) {
        this.showAlert(AlertTypeEnum['info'], "You need to earn minimum of $10 (1000 Attendance) to redeem");
      }
      return;
    }

  this.siloqpayService.redeem( (+this.getTaxon(topic, "rank")/100).toString(), "USD", topic.email)
    .subscribe( () => {
      this.showAlert(AlertTypeEnum['info'], "You have redeemed amount will show up in your bank account with in a week. Thank you for your business!");
    },
    (err: any) => {
    console.log(err);
    });

  }

  mockRequest(t: Topic, callback: (t: any) => any) {
        this.topicService.mockRequest(t).subscribe(
          (data: any) => {
            callback(data);
          },
           (err: any) => {
            console.log(err);
          });
      }

  playTopic(topic: any) {
    this.router.navigate(['/admin/play'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  editTopic2(topic: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : topic.hashTag, i : topic.id}});
  }

  docBranch(branch: any) {
    this.router.navigate(['/admin/edit'],  { queryParams: { h : branch}});
  }

  manageTopicUsers(topic: any) {
    this.router.navigate(['/admin/user'],  { queryParams: { h : topic.hashTag}});
  }

  invite() {
    this.router.navigate(['/admin/invite']);
  }

  inbox() {
    //TODO:
    //this.router.navigate(['/admin/inbox'], { queryParams: { h : "inbox-"+this.theUser, d : this.theUser}});
    this.router.navigate(['/admin/publish'], { queryParams: { h : "inbox", d : "tagndoc@tagndoc.com"}});
  }

  tagndoc() {
    this.router.navigate(['/admin/tagndoc']);
  }

  editTopic(topic: any) {
    //this.umodel.edit =  !this.umodel.edit;
    this.umodel = new Topic(topic.hashTag, topic.value, topic.userId);
    topic.edit = !topic.edit;
  }

  cancelUpdate(topic: Topic) {
    topic.hashTag = this.umodel.hashTag;
    topic.value = this.umodel.value;
    topic.insertDate = this.umodel.insertDate;
    topic.edit = false;
  }

  //pagination for table
  setCurrentPage(page: number) {
    this.loadTopics(() => {
      this.setPageVisible();
      this.currentPage = page;
    });
  }

  public setFirstPage() {
    this.loadTopics(() => {
      this.setPageVisible();
      this.currentPage = 1;
    });
  }

  setRedeemPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    this.redeemtopics = [];
    var j = this.selectedIndex;
    for (let i = 0; i < this.topics.length; i++) {
      if(this.topics[i].email === this.theUser) {
        this.redeemtopics.push(this.topics[i]);
      }
    }
    this.totalCount = this.redeemtopics.length;
    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

    for (let i = 0; i < this.redeemtopics.length; i++) {
      this.redeemtopics[i].show = false;
      if(i >= this.selectedIndex && i < end ) {
          this.redeemtopics[i].show = true;
          this.redeemtopics[i].hashTag = unescape(this.redeemtopics[i].hashTag);
          this.selectedTopics.push(this.redeemtopics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }

  setGoldimembersPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    this.golditopics = [];
    var j = this.selectedIndex;
    for (let i = 0; i < this.topics.length; i++) {
      if(this.isGoldimembers(this.topics[i].taxonomy)) {
        this.golditopics.push(this.topics[i]);
      }
    }
    this.totalCount = this.golditopics.length;
    this.pageCount = Math.ceil(this.totalCount / this.pageSize);

    for (let i = 0; i < this.golditopics.length; i++) {
      this.golditopics[i].show = false;
      if(i >= this.selectedIndex && i < end ) {
          this.golditopics[i].show = true;
          this.golditopics[i].hashTag = unescape(this.golditopics[i].hashTag);
          this.selectedTopics.push(this.golditopics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
  }

  setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    for (let i = 0; i < this.topics.length; i++) {
      this.topics[i].show = false;
      if(i >= this.selectedIndex && i < end) {
        this.topics[i].show = true;
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.selectedTopics.push(this.topics[i]);
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
//     if (this.selectedTopic) {
//       this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
//       this.selectedTopic.rank = this.selectedIndex + 1;
//     }
  }


  setMe(element: any) {
    var s_height = element.scrollHeight;
    element.setAttribute('style','height:'+s_height+'px');
  }

  selectAllTopics() {
    for (let i = 0; i < this.topics.length; i++) {
      if(!this.selectAll) {
        this.topics[i].checked = true;
      }
      else {
        this.topics[i].checked = false;
      }

    }
  }

  removeUserFromTopic(model: Topic, user: string){
    var id = model.users.indexOf(user);
    model.users.splice(id,  1);
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(model).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }

  setArrayFromNumber(i: number) {
  return new Array(i);
}

curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }
    return email;
  }

profile(t: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(t.email)}});
    return;
  }

myProfile(t: any) {
    this.router.navigate(['profile'],  { queryParams: { h : t.hashTag, i : t.id, d : window.btoa(this.theUser)}});
    return;
  }

examTopics(list: string) {
    this.router.navigate(['/admin/exams'],  { queryParams: { sislr : "siloq", t : "MOCK " + list.toUpperCase(), u : list.toLowerCase()+".imoqr@siloq.com"}});
}

  renderImage(t: any) {
    var s = t;
    t = t.replace(/<(?:.|\n)*?>/gm, '');
     if (t.startsWith("http://") || t.startsWith("https://")) {
         const img = new Image();
         img.src = t;
         var w = 60;
         var h = (w/img.width)*img.height;
         t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
         return t;
      }
         return s;
    }

    parseTitle(t: string): string {
        return t.replace(/_/g, " ");
    }

    searchRedeemMocks() {
            this.pageTitle = this.title;
            this.loadTopics(() => {
              this.currentPage = 1;
              this.setRedeemPageVisible();
            });

            return;
    }

    searchGoldimembersMocks() {
            this.pageTitle = this.title;
            this.loadTopics(() => {
              this.currentPage = 1;
              this.setGoldimembersPageVisible();
            });

            return;
    }

    searchMyMocks() {
            this.pageTitle = this.title;
            this.loadTopicsEx(() => {
              this.currentPage = 1;
              this.setPageVisible();
            });

            return;
    }

    searchTopics(list: string) {
            this.title = list;
            this.pageTitle = list.toUpperCase();
            this.user = list.toLowerCase()+".imoqr@siloq.com"
            this.loadTopicsEx(() => {
              this.currentPage = 1;
              this.setPageVisible();
            });

            return;
    }

  results() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
    }

    setPage() {
        if(this.title === "REDEEM") {
            this.setRedeemPageVisible();
        } else if(this.title === "GOLD") {
            this.setGoldimembersPageVisible();
        } else {
          this.setPageVisible();
        }
    }

    first() {
      if(this.currentPage>1){
        this.currentPage=1;
        this.setPage();
      }
    }

    previous() {
      if(this.currentPage>1){
        this.currentPage-=1;
        this.setPage();
      }
    }

    next() {
      if(this.currentPage<this.pageCount){
        this.currentPage+=1;
        this.setPage();
      }
    }

    last() {
      if(this.currentPage<this.pageCount){
        this.currentPage=this.pageCount;
        this.setPage();
      }
    }

    isGoldimembers(tax): boolean {
     if(tax) {
         for (let i = 0; i < tax.length; i++) {
           if(tax[i].key === "goldimembersEnabled") {
             return (tax[i].value[0] === "true");
           }
         }
     }
     return false;
  }

  getTaxon(t: Topic, x: string) {
        if(t && t.taxonomy) {
          for (let i = 0; i < t.taxonomy.length; i++) {
             if (t.taxonomy[i].key === x) {
                switch (x) {
                  case "random":
                    return +t.taxonomy[i].value[0];
                  case "timeDuration":
                    return +t.taxonomy[i].value[0];
                  case "startTime":
                    return t.taxonomy[i].value[0];
                  case "pageSize":
                    return +t.taxonomy[i].value[0];
                  case "rightQuestionReward":
                    return +t.taxonomy[i].value[0];
                  case "wrongQuestionPenalty":
                    return +t.taxonomy[i].value[0];
                  case "audioEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "multiColEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "goldimembersEnabled":
                    return (t.taxonomy[i].value[0] === "true");
                  case "rentPrice":
                    return +t.taxonomy[i].value[0];
                  case "buyPrice":
                    return +t.taxonomy[i].value[0];
                  case "rank":
                    return +t.taxonomy[i].value[0] || 0;
                }
            }
          }
        }
        return ""
  }



  getTopicById(id: string, callback: (t: any) => any) {
    this.topicService.getTopicById(id).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }




    searchResults(list: string) {
       this.router.navigate(['/admin/results'],  { queryParams: { sislr : "siloq", t : "MOCK " + list.toUpperCase(), u : list.toLowerCase()+".imoqr@siloq.com"}});
    }

  auto() {
        //Use a queue for managing in-flight requests
        var timeoutId = setTimeout(()=> {
          this.showAuto = false;
          if (this.searchText.length > 2) {
            this.ptopics = new Array();
            this.topicService.mockSearchTopics(escape(this.searchText), this.sortKey, "9")
                     .subscribe(
                     ptopics => {
                       if(ptopics !== undefined) {
                          this.ptopics = ptopics; //Bind to view
                          var i = 0;
                          for (;i < 10 && i < this.ptopics.length;) {
                            this.ptopics[i].show = true;
                            this.ptopics[i].hashTag = unescape(this.ptopics[i].hashTag);
                            i++;
                          }
                          if(i > 0) {
                            this.showAuto = true;
                          }
                        }
                      },
                       (err: any) => {
                        this.showAuto = false;
                        console.log(err);
                      });
          } else {
            this.ptopics = new Array();
            this.showAuto = false;
          }
        }, 1000/60);
      }

  public selectAuto( topic: any, i: number) {
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.searchText = topic.hashTag;
    this.docTopic(topic);
    this.showAuto = false;
  }

  public autoDown() {
      this.searchText = this.selectedAuto.Name;
  }

  public autoUp() {
    if(this.selectedIndex > 0)
    {
      this.searchText = this.selectedAuto.Name;
    }
  }

}
