<header></header>
<div  style="text-align:center; width:100%; padding-top:70px;">
<br>

  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Forgot</h3>
    <br>
      <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
    <br>
    <br>
      <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Password</h3>
  </div>

  <alert></alert>
  <br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="slqinput" placeholder="Email" id="email" [(ngModel)]="email" name="email" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
            </div>
        </label>
    </div>
    <br>
    <br>
  <div class="small-12 columns">
    <a [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  (click)='doforgot()' class="slqok">Submit</a>
    &nbsp;<a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  (click)='done()' class="slqcancel">Cancel</a>
  </div>
                <br>
                <br>
</div>
</form>
</div>
<footer></footer>

