import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertService } from './services/alert.service';
import { AlertInterface } from './types/alert.interface';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  imports: [CommonModule],
  standalone: true
})
export class AlertComponent implements OnInit {
  alert?: AlertInterface;
  timeoutId?: number;
  public taxonomy = "";
  public class = "";
  public subclass = "";
  alertClass="";

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertService.getAlert().subscribe((alert) => {
      this.alert = alert;
      this.resetTimer();
    });

    this.taxonomy = config.taxonomy;
    this.subclass = config.subclass;
    this.class = config.class;
  }

  resetTimer(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.alert = undefined;
    }, 3000);
  }

  getClass() {
  if(this.alertClass) {
    this.alertClass = 'alert';//, '+this.alert.type;
    }
  }
}
