import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../config';

@Injectable()
export class SiloqpayService {
constructor(private http: HttpClient) { }
    redeem(a: string, c: string, d: string) {
          return this.http.get(config.apiSiloqpayUrl + "redeem/"+ a + "/" + c + "/" + d);
     }
}
