<admin-header></admin-header>
<body *ngIf="ready" style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
  <form method="post">
    <div class="doNotPrint d-flex justify-content-center align-items-center container"  style="position:fixed;background-color:whitesmoke;position:display:table;width:100%; height:50px; valign:middle;border:0px solid lightgrey;margin:54px 0px 0px 0px;">
      <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
        <a [style.backgroundColor]="backgroundColor1"  (mouseover)="backgroundColor1 = 'orange'" (mouseout)="backgroundColor1 = 'gold'"  *ngIf="!newLoq && theUser === 'hold.imoqr@siloq.com' && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') > 0" title="Approve Exam"  style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton" (click)="branchTopic('exam');" >Approve Exam</a>
        <a [style.backgroundColor]="backgroundColor5"  (mouseover)="backgroundColor5 = 'red'" (mouseout)="backgroundColor5 = 'orangered'"  *ngIf="!newLoq && theUser === 'hold.imoqr@siloq.com' && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') > 0" title="Reject Exam"  style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"class="siloqbutton" (click)="branchTopic('loq');" >Reject Exam</a>
        <a [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'gold'"  *ngIf="!newLoq && (theUser === topic.email || theUser === model.email) && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') < 0" title="Submit Exam for Review"  style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"class="siloqbutton" (click)="branchTopic('hold');" >Submit Exam</a>
        <a [style.backgroundColor]="backgroundColor3"  (mouseover)="backgroundColor3 = 'orange'" (mouseout)="backgroundColor3 = 'gold'"  *ngIf="!newLoq && theUser !== 'hold.imoqr@siloq.com' && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') < 0" title="Create New Act" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton"  (click)="branchTopic('act');" >Create Act</a> <!--New Act from selected LOQs -->
        <a [style.backgroundColor]="backgroundColor4"  (mouseover)="backgroundColor4 = 'blue'" (mouseout)="backgroundColor4 = 'dodgerblue'"  *ngIf="!newLoq && theUser !== 'hold.imoqr@siloq.com' && topic.users && topic.users.indexOf('hold.imoqr@siloq.com') < 0" title="Create New Subtitle" style="font-family:Helvetica,AppleGothic,sans-serif; background-color:dodgerblue;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;" class="siloqbutton"  (click)="branchTopic('sub');" >Create Sub</a> <!--New Subtitle from selected LOQs-->
      </div>
    </div>
    <div class="doNotPrint d-flex justify-content-center align-items-center container" style="background-color:#FAF9F6;position:fixed;margin-top:100px;display:table;width:100%; height:20px; valign:middle;border:0px solid lightgrey;padding:0px">
      <div style="text-align:center;margin:0 auto;color:mediumseagreen;padding:6px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:98%;vertical-align: middle;">
        <a *ngIf="!newLoq"  title="Open Loqument" class="fa fa-folder-open-o intLink" type="button" valign="middle" (click)="open();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"><input type="file" id="upload" (change)="fileChangeEvent($event)"></a>
        <a *ngIf="!newLoq"  title="Download Locumnt" class="fa fa-save intLink" type="button" valign="middle" (click)="downloadSlq();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newLoq" class="fa fa-trash intLink" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;margin:0px;"  (click)="branchTopic('delete')" title="Delete LOQs"></a>
        <a *ngIf="!newLoq" class="fa fa-code-fork intLink fa-fw" style="margin:0px;color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:600;font-size:1em;width:22px;height:22px;"  (click)="branchTopic('merge')" title="Merge LOQs"></a>
        <a *ngIf="!newLoq" class="fa fa-code-fork fa-rotate-90 intLink" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:600;font-size:1em;width:22px;height:22px;"  (click)="branchTopic('split')" title="Split LOQs"></a>
        <a *ngIf="!newLoq" title="Export To PDF" class="fa fa-sign-out intLink" type="button" valign="middle" (click)="export();" style="color:dodgerblue;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"></a>
        <a *ngIf="!newLoq" title="Share"  class="fa fa-users double intLink" (click)="shareLoq()" type="button"  valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newLoq" class="fa fa-history intLink" [routerLink]="['/admin/raw']" [queryParams]="{i:topic.id}" type="button"  title="Timeline"valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newLoq" title="Clone"  class="fa fa-clone intLink" (click)="branchLoq()" type="button"  valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="theUser==='contact@siloq.com' && !newLoq" title="Edit"  class="fa fa-edit intLink" [routerLink]="['/admin/edit']" [queryParams]="{h:model.hashTag}" valign="middle" style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newLoq" title="DeLOQ"  class="fa fa-bug intLink"   type="button"  valign="middle" (click)="deloq()"style="color:mediumseagreen;background-color:#ffffff00;height:22px;width:22px;border:0px; padding :0px 5px 0px 0px;font-weight:300;font-size:1em;"></a>
        <a *ngIf="!newLoq && !showcol" class="fa fa-circle-o intLink" (click)="line()"  style="color:#617CB5;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;" title="Show Row Number(s)"></a>
        <a *ngIf="!newLoq && showcol" class="fa fa-dot-circle-o intLink" (click)="line()"  style="color:orangered;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;" title="Show Row Number(s)"></a>
        <a class="fa fa-square-o intLink" *ngIf="!newLoq && !edit" (click)="setSubStateAll($event);" style="color:#617CB5;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:600;font-size:1em;width:22px;height:22px;margin:0px;" title="Show Subtitle(s) Selector"></a>
        <a class="fa fa-check-square-o intLink" *ngIf="!newLoq && edit" (click)="setSubStateAll($event);" style="color:orangered;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:600;font-size:1em;width:22px;height:22px;margin:0px;" title="Show Subtitle(s) Selector"></a>
        <a *ngIf="!newLoq && top" title="Go To End"  class="fa fa-fast-backward fa-rotate-270 intLink"  (click)="routeToPageSection('end');top=false;" type="button"  valign="middle" style="color:#617CB5;background-color:#ffffff00;height:12px;width:14px;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:.8em;margin:0px 4px 12px 4px;"></a>
        <a *ngIf="!newLoq && !top" title="Go To Top"  class="fa fa-fast-backward fa-rotate-90 intLink"  (click)="routeToPageSection('doc');top=true" type="button"  valign="middle" style="color:orangered;background-color:#ffffff00;height:12px;width:14px;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:.8em;margin:0px 4px 12px 4px; "></a>
        <a *ngIf="!newLoq && !editable" class="fa fa-pencil intLink" style="margin:0px;color:#617CB5;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;"(click)="initNewLOQ()" title="Edit LOQs"></a>
        <a *ngIf="!newLoq && editable" class="fa fa-pencil-square-o intLink" style="margin:0px;color:orangered;background-color:#ffffff00;border:0px; padding:0px 0px 0px 0px;font-weight:300;font-size:1em;width:22px;height:22px;" (click)="getTopicTagsExToo();" title="View LOQs"></a>
      </div>
    </div>
    <div  class="doNotPrint" style="padding:150px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">LOQUMENT</div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
                                                  style="color:dodgerblue;">i</span><span
                                                     style="color:mediumseagreen;">s</span><span
                                                     style="color:orangered;">l</span><span
                                                     style="color:orange;">o</span><span
                                                     style="color:dodgerblue;">q</span><span
                                                     class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly Runtime!</span> </a>
    </div>

    <admin-alert></admin-alert>
    <ng-template #modalTemplate>
    </ng-template>


    <div (dblclick)="setSubState();" id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 5% 40px 5%;padding-top:10px;z-index:-1px;">
      <h2 *ngIf="model.hashTag" style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
        <div *ngIf="newLoq" id="editUTopic" [contentEditable]="editable" [class.active]="editable" style="min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px auto;" (blur)="model.hashTag = $event.target.innerHTML; addNewTopic();">{{model.hashTag}}</div>
        <span *ngIf="newLoq && !editable" class="fa fa-pencil intLink" style="margin:0px;color:dodgerblue;background-color:#ffffff00; padding:0px 0px 0px 0px;font-weight:500;font-size:18px;width:120px;border:2px solid gold;"(click)="editable=!editable" title="Edit LOQs"> Edit Title</span>
        <div *ngIf="!newLoq && topic.email" id="editTopic" [contentEditable]="editable" [class.active]="editable" style="min-width:30%;  min-height:30px; font-family: Arial, Helvetica, sans-serif;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px auto;color:#617CB5;" (blur)="model.hashTag = $event.target.innerHTML; renameTopic();">{{model.hashTag}}</div>
        <div *ngIf="!newLoq && topic.email" style="font-family: Arial, Helvetica, sans-serif;font-size;1em;font-weight:200;text-align:center;color:#617CB5;">({{curbemail(topic.email)}})</div>
        <span *ngIf="!newLoq && tags && tags.length <= 0 && !editable && ready" class="fa fa-pencil intLink" style="margin:0px;color:dodgerblue;background-color:#ffffff00;padding:0px 0px 0px 0px;font-weight:500;font-size:18px;width:256px;border:2px solid gold;"(click)="initNewLOQ()" title="Edit LOQs"> Click Edit above to add LOQs</span>
      </h2>
      <div *ngIf="!model.hashTag" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 0px 4%;padding-top:10px;z-index:-1px;">
        <h3 style="text-align:center;">No Item Available</h3>
      </div>


  <div  *ngFor="let selectedSlide of subtitles; let i = index" (click)="selectSlide(selectedSlide, i)" (mouseenter)="browseSlideIndex = i" style="width:98%;margin:0px;padding:2px">

    <!-- editable -->
    <div *ngIf="selectedSlide && slides[selectedSlide] && slides[selectedSlide].length > 1 &&  i > 0 && i < subtitles.length-1 && editable" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">
      <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;" >

          <div *ngIf="i === 1 && j  > 0 && tag.name === tag.hashTag">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <div *ngIf="j > 0 && tag.value.length > 1" [contentEditable]="editable" class="editTag" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;"  (blur)="tag.value = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
              <div  style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint"  *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:16px;">
              <input *ngIf="editable" class="intLink" type="button" title="Archive" value="✘" (click)="deleteTag(tag)" style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;vertical-align: middle;"  valign="middle"/> </div>
            </div>
          </div>

        <div *ngIf="i >= 1 && i < subtitles.length-1 && tag.name !== tag.hashTag" style="display:table;">
          <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
            <input *ngIf="edit===true" name="{{tag.id}}" [(ngModel)]="tag.checked" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: middle; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;" (click)="setState($event, tag);"/>
            <div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;">
              <a *ngIf="editable && j === 0" class="fa fa-sign-out fa-rotate-270  intLink" type="button" title="Move Subtitle Up" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j === 0" class="fa fa-sign-out fa-rotate-90  intLink" type="button" title="Move Subtitle Down"  style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j > 0" class="fa fa-sign-out fa-rotate-270  intLink" type="button" title="Move LOQ Up" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j > 0" class="fa fa-sign-out fa-rotate-90  intLink" type="button" title="Move LOQ Down"  style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:dodgerblue;font-size:12px;vertical-align: middle;"  valign="middle"></a>
            </div>
            &nbsp;<a *ngIf="j === 0" (click)="routeToPageSection((i-1)+'s')"><span><span  *ngIf="showcol">{{i}}.&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span></a>
            <div *ngIf="j === 0"   class="editTag" [contentEditable]="editable" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML;updateTagName(tag);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml"></div>
            <span *ngIf="j > 0 && showcol">[{{i}}-{{j}}]&nbsp;</span>
            <div style="border:2px solid blue;" *ngIf="j > 0" class="editTag" [contentEditable]="editable" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;"  (blur)="tag.value = $event.target.innerHTML;updateTag(tag, j);" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
            <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="validateEmail(tag.source)" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;
            </div>
            <div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;text-align:right;">
              <a *ngIf="editable && j === 0" class="fa fa-sign-in fa-rotate-270 intLink" type="button" title="Insert Subtitle Above" value="－" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:orange;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j > 0" class="fa fa-sign-in fa-rotate-270 intLink" type="button" title="Insert LOQ Above" value="－"  style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:orange;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j === 0" class="fa fa-trash intLink" type="button" title="Archive Subtitle" value="✘" (click)="deleteTag(tag)" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:orangered;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j > 0" class="fa fa-trash intLink" type="button" title="Archive LOQ" value="✘" (click)="deleteTag(tag)" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:orangered;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j === 0" class="fa fa-sign-in fa-rotate-90 intLink" type="button" title="Insert Subtitle Below" value="＋" (click)="newLOQ()" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:mediumseagreen;font-size:12px;vertical-align: middle;"  valign="middle"></a>
              <a *ngIf="editable && j > 0" class="fa fa-sign-in fa-rotate-90 intLink" type="button" title="Insert LOQ Below" value="＋" (click)="cloneTag(tag)" style="margin:0px;padding:0px;border:0px;background-color:#ffffff00;color:mediumseagreen;font-size:12px;vertical-align: middle;"  valign="middle"></a>
            </div>
          </div>
        </div>

    </div>
    </div>



      <!-- non editable -->
      <div *ngIf="selectedSlide && slides[selectedSlide] && slides[selectedSlide].length > 1  && !editable" class = "regular" [class.browse]="i === browseSlideIndex" [class.active]="i === selectedSlideIndex">
        <div *ngFor="let tag of slides[selectedSlide]; let j = index" (click)="selectTag(tag, j)" (mouseenter)="browseTagIndex = j" style="width:98%; list-style: none;background-color:white;display:table; margin:0; font-family:Helvetica,AppleGothic,sans-serif; inline-block;padding: 2px; height:20px;text-align:left;" >

          <div *ngIf="i === 0" style="display:table;">
            <div *ngIf="j === 0" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"[innerHTML]="selectedSlide | safeHtml"></div>
            <div *ngIf="j > 0" id="{{j}}s" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <span><span *ngIf="showcol">{{j}}.&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span><span style="cursor:pointer;text-align: justify; text-justify: inter-word;padding:0px 0px 0px 0px;color:dark;font-weight:500;font-size:12px;"> <a valign="top" (click)="routeToPageSection(j)" [innerHTML]="tag.value | safeHtml"></a> </span></span>
            </div>
          </div>

          <div *ngIf="i === 1 && j  > 0 && tag.name === tag.hashTag">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <div *ngIf="j > 0 && tag.value.length > 1" [contentEditable]="editable" class="editTag" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml"></div>
              <div  style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="tag.source.indexOf('@') > 0" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint"  *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:16px;"><input *ngIf="editable" class="intLink" type="button" title="Archive" value="✘" (click)="deleteTag(tag)" style="border:0px;background-color:#ffffff00;color:orangered;height:19px;width:19px;vertical-align: middle;"  valign="middle"/> </div>
            </div>
          </div>

          <div *ngIf="i >= 1 && i < subtitles.length-1 && tag.name !== tag.hashTag" style="display:table;">
            <div class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px; ">
              <input *ngIf="edit===true" name="{{tag.id}}" [(ngModel)]="tag.checked" class="doNotPrint" type="checkbox" style="width: 13px; height: 13px; vertical-align: middle; position: relative; top: -1px; *overflow: hidden;padding:0px;margin:0px;font-weight:300;font-size:.75em;" (click)="setState($event, tag);"/>&nbsp;<a *ngIf="j === 0" (click)="routeToPageSection((i-1)+'s')"><span><span  *ngIf="showcol">{{i}}.&nbsp;</span><span class="editTag"  style="padding:0px 1px 2px 0px; font-weight:600;font-size:14px;" ></span></span></a><div *ngIf="j === 0"   class="editTag" [contentEditable]="editable" tabindex="{{i}}" (focus)="selectedTag = tag;" (blur)="tag.name = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;" id="{{i}}" [innerHTML]="renderImage(tag.name) | safeHtml">
            </div>
              <span *ngIf="j > 0 && showcol">[{{i}}-{{j}}]&nbsp;</span>
              <div *ngIf="j > 0" class="editTag" [contentEditable]="editable" tabindex="{{i+j+1}}"(focus)="selectedTag = tag;" (blur)="tag.value = $event.target.innerHTML;" valign="top" style="display:table-cell;padding:0px 1px 2px 0px;text-align: justify; text-justify: inter-word;display: inline-block; margin: 0 auto !important; inter-word;font-weight:500;font-size:12px;"[innerHTML]="renderImage(tag.value) | safeHtml">
              </div>
              <div style="display:table-cell;padding:0px 1px 2px 0px;font-weight:500;font-size:12px;" >&nbsp;<a *ngIf="tag.source.startsWith('http')" href="{{cutUntil(tag)}}" target="_blank">[{{refIndex(tag.source)}}]</a><a *ngIf="validateEmail(tag.source)" (click)="profileEx(model.hashTag, tag.source)" style="cursor:pointer;" target="_self">[{{refIndex(tag.source)}}]</a>&nbsp;</div><div class="doNotPrint" *ngIf="i === selectedSlideIndex && j === selectedTagIndex" style="display:table-cell;width:38px;">
            </div>
          </div>
          </div>

          <div *ngIf="i === subtitles.length-1" style="display:table;">
            <div *ngIf="j === 0" id="{{i}}" style="display:table-cell;padding:0px 1px 15px 0px;text-align:left;display: inline-block; margin: 0 auto !important; font-weight:600;font-size:14px;"> {{selectedSlide}} </div>
            <div *ngIf="j > 0" class = "regular" [class.browse]="i === browseSlideIndex && j === browseTagIndex" [class.active]="i === selectedSlideIndex && j === selectedTagIndex" style="vertical-align:top;display:table; text-align:left;margin:0px;font-weight:500;font-size:12px; ">
              <div *ngIf="tag.value.trim().length > 1" style="padding:0px 0px 0px 0px;color:dark">
                <span *ngIf="tag.value.startsWith('http')" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a href="{{tag.value}}" target="_blank">{{tag.value | truncate : 64}}</a></span>
                <span *ngIf="validateEmail(tag.value)" style="display: inline-block;" valign="top">[{{j}}]&nbsp;<a (click)="profileEx(model.hashTag, tag.value)" style="cursor:pointer;"target="_self">{{curbemail(tag.value)}}</a></span>
                <span *ngIf="!(tag.value.startsWith('http') || validateEmail(tag.value))" style="display: inline-block;" valign="top">[{{j}}]&nbsp;{{tag.value | truncate : 64}}</span>
              </div>
            </div>

        </div>
        </div>
 </div>
</div>
    </div>

    <br class="doNotPrint">
    <br class="doNotPrint">
    <div class="block_container" id="end" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by&nbsp;</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" />&nbsp;</a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
<loq-footer *ngIf="ready"></loq-footer>
