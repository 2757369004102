<header  *ngIf="ready" class="doNotPrint"></header>
<body *ngIf="ready" style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
<form method="post">
  <div class="doNotPrint" style="min-width:84%;width:100%; valign:middle;display:table;height:38px;padding-top:0px;margin:16px 0px 0px 0px; auto;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5;">
    <div style="display:table-cell;width:20%; text-align:left;padding-left:15px;">
      <a style="font-size:1em;font-weight:600;" >Let's get in Touch</a>
    </div>
    <div style="display:table-cell;width:40%;">
      <a *ngIf="!loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a demo, drop us a message.</a>
      <a *ngIf="loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a feedback, drop us a message.</a>
    </div>
    <div style="display:table-cell;width:20%;text-align:right;padding-right:15px;">
      <a *ngIf="!loggedinUser" [routerLink]="['/contact']" class="siloqbutton" style="height:20px;border:0px;color:tomato;">Contact</a>
      <a *ngIf="loggedinUser" [routerLink]="['/admin/feedback']" [queryParams]="{s: 'Feedback-Profile'}" class="siloqbutton" style="height:20px;border:0px;color:tomato;">Feedback</a>
    </div>
</div>

    <div *ngIf="theUser" style="padding-top:10px;font-family:Helvetica,AppleGothic,sans-serif; width:60%;text-align:center;margin:0 auto;color:#617CB5;font-size:1.5em;font-weight:400;vertical-align: middle;text-align:center;">
      <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="width:180px; padding:10px 12px 10px 12px;font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='notify()' class="siloqbutton">Subscribe <b> <i class='fa fa-bell-o' style="font-size:17px"></i></b></a>
      <h4 class="block_container" style="font-size:.8;color:#617CB5;font-weight:500;">Influence The Individual<a style="text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;" (click)="refer()">&nbsp;🔗</a></h4>
    </div>
    <div *ngIf="!theUser" style="padding-top:10px;font-family:Helvetica,AppleGothic,sans-serif; width:60%;text-align:center;margin:0 auto;color:mediumseagreen;font-size:1.5em;font-weight:400;vertical-align: middle;text-align:center;">
      <a class="siloqbutton"  (click)="notify();" style="width:120px;background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;">Subscribe <i class='fa fa-bell' style="font-size:17px"></i></a>
      <h4 class="block_container" style="font-size:.8em;color:#617CB5;font-weight:500;">Influence the Individual<a style="text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;" (click)="refer()">&nbsp;🔗</a></h4>
    </div>
<alert></alert>
    <div class="block_container" style="font-family:Helvetica,AppleGothic,sans-serif; width:100%;margin:0 auto;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;"><input name="siloqSearch" #siloqSearch  class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure PROFILE Search [Min 3 Chars]"  [(ngModel)]='searchText' style="color: grey;padding:7px;height: 36px; width:42%;min-width:160px;margin: auto;"/></div>
<br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="" >&nbsp;<img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span
                             style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">i</span><span
                             style="color:mediumseagreen;">s</span><span
                             style="color:orangered;">l</span><span
                             style="color:dodgerblue;">o</span><span
                             style="color:orange;">q</span><span
                             class="sup"
                             style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span>
                           </a>
</div>
<div id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">

<!-- Acts(45)-->
  <div *ngIf="!showAuto && theUser" style="width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
    <div *ngIf="!showAuto" style="color:#617CB5;padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; height:42px;text-align:center;" >
      <div *ngIf="topics && topics.length >= 64" style="display: inline-block;">
        Top {{totalCount | number:'1.0-0'}},000,000 PROFILEs<br><i style="font-size:.8em;">({{curbemail(theUser)}})</i>
      </div>
      <div *ngIf="topics && topics.length > 0 && topics.length < 64" style="display: inline-block;">
        PROFILEs({{totalCount | number:'1.0-0'}}+)<br><i style="font-size:.8em;">({{curbemail(theUser)}})</i>
      </div>
      <div  *ngIf="topics === undefined" style="display: inline-block; text-align:center;">
        Working...
      </div>
      <div  *ngIf="topics && topics.length === 0" style="display: inline-block;color:orangered;">
        No PROFILEs
      </div>
    </div>
  </div>
    <div *ngIf="!showAuto && !theUser" style="width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
      <div *ngIf="!showAuto" style="color:#617CB5;padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; height:42px;text-align:center;" >
        <div *ngIf="topics && topics.length >= 64" style="display: inline-block;">
         Top {{totalCount | number:'1.0-0'}},000,000 PROFILEs<br><i style="font-size:.8em;">({{taxonomy}})</i>
        </div>
        <div *ngIf="topics && topics.length > 0 && topics.length < 64" style="display: inline-block;">
        PROFILEs({{totalCount | number:'1.0-0'}}+)<br><i style="font-size:.8em;">({{taxonomy}})</i>
        </div>
        <div  *ngIf="topics === undefined" style="display: inline-block; text-align:center;">
          Working...
        </div>
        <div  *ngIf="topics && topics.length === 0" style="display: inline-block;color:orangered;">
        No PROFILEs
      </div>
      </div>
    </div>
<br>

  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;">
      <a class="fa fa-play first" *ngIf='currentPage>1' (click)="profileEx(1)" ></a>
      <a class="fa fa-play first" *ngIf='currentPage>1' (click)="profileEx(1)" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="profileEx(currentPage-1)" ></a>
      Page {{currentPage}} of {{pageCount | number:0}}
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="profileEx(currentPage+1)"></a>
      <a class="fa fa-play last" *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)"></a>
      <a class="fa fa-play last" *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)"></a>
    </div>
  </div>
<div class="container" style="width:100%;color:#696969;border:0px solid #696969; border-color:gainsboro;margin-top:0px;">
	<div style="width:100%;overflow:hidden;">

      <div *ngIf="(selectedTopics && selectedTopics.length > 0)" class="container2 siloqfont" style="margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; font-size:1em; font-weight: 200;width:90%;">
      <div *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (mouseenter)="browseIndex=i;">
        <div *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show && !topic.isDisabled && topic.hashTag"  valign="top">

          <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px;">
            <div style="background-color:whitesmoke;width:100%;min-height:20px;height:auto;margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;border-bottom:1px solid lightgrey;">

              <div style="float:left; text-align:left;width:80%;">
                <div  style="height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="View Topic {{topic.hashTag}}" (click)='publicTopic(topic)' [innerHTML]="parseTitle(getParentTopic(topic)) | truncate : 64"></div>
              </div>

              <div style="text-align:right;min-width:60px;"> <input class="intLink" type="button" value="❐" valign="middle" (click)='publicTopic(topic)' title="View Topic {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
              </div>
            </div>


            <div style="vertical-align:top;margin:0px;text-align:left;padding:2px 5px 5px 5px;" >
              <div style="display:inline-block;width:100%;">
                <span class="doNotPrint" (click)='publicTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>
	</div>

</div>
  <div  *ngIf="selectedTopics && selectedTopics.length" class="col-md-12 page">
    <div style="display: inline-block;font-weight:600;">
      <a class="fa fa-play first" *ngIf='currentPage>1' (click)="profileEx(1)" ></a>
      <a class="fa fa-play first" *ngIf='currentPage>1' (click)="profileEx(1)" ></a>
      <a *ngIf='currentPage>1' class="fa fa-play prev" (click)="profileEx(currentPage-1)" ></a>
      Page {{currentPage}} of {{pageCount | number:0}}
      <a class="fa fa-play next" *ngIf='currentPage<pageCount' (click)="profileEx(currentPage+1)"></a>
      <a class="fa fa-play last" *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)"></a>
      <a class="fa fa-play last" *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)"></a>
    </div>
  </div>
	 </div>
  <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://sisloq.com"  style="color:dodgerblue;" >&nbsp;Powered By&nbsp;<img src="/assets/sisloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:orangered;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;"></span></a> </div>
<br>
<br>
<br>
<br>
<footer *ngIf="ready"></footer>
