<header></header>
<body *ngIf="ready" style="background-color:#ffffff00; height:100%;width:100%; margin:0px;">
<form method="post">
<div style="padding:4px 0px 4px 0px; display:table;height:30px;margin-top:16px;border:0px;text-align:center;background-color:orangered;font-family:Helvetica,AppleGothic,sans-serif;width:100%;">
  <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-we-belong-here'}" style="text-decoration:none;">
    <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:1em;font-weight:600;">
      GoldiMocks™
    </button>
  </a>
  <br>
  <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-we-belong-here'}" style="font-weight:600;font-size:.8em; padding:8px;text-decoration:none;color:gold;">
    "Good Question, We Belong Family"™
  </a>
  <div style="padding-top:8px;">
  <img class="intLink" title="{{taxonomy}}" style="width:64px; height:64px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEAAQMAAABmvDolAAAABlBMVEX///8AAABVwtN+AAABX0lEQVR42uyYsXF0IQyElyEgVAmUQmt/aZRCCYQEN+gfCT987853BUhsYI/9voiRdiXh6Ojo6JtY9YjMPECdWl7/+WcLmPIjPiIP/ZNabvK7uAOCPNMjyndi7sjyVNUrAKQhADffQBxJS4asAqsvkNQVOvChcawDl0+ONFL/ZqTGga2rLz7mo21gIqyXGWkQd+KGXMstN20A2hYaByBtDMErnAEIE5FXHAjRkFupT/7gBAhSMdeUJI3BDeXZH5wA67sQibs81HugWACYeV5DMXVi9QeUCneA9IU2htrlGpNuuekCmIHnSkXNxZ6ZW3mZJ10AQNCdWUpGK0aG5nugGAB+FIfUQwc16HjgDthHkpH2llT/2g6MA9eRZG8HUJ9kd8DvkWQZpc6KZoHlD2KDMh64BWQo1nqQ8eA1Fl0A+0iieaGnwbdt0QGwjyRrWdRb8W1LsgEcHR151P8AAAD//9lchV6TXVq6AAAAAElFTkSuQmCC" />
  </div>
</div>
<div style="display:table;height:3px;padding-top:0px;margin-top:0px;border:0px;text-align:center;background-color:gold;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
</div>

<div class="row expanded align-center" style="width:100%;padding:0px;text-align: center; margin:auto;">
          <span style="color:dodgerblue;padding:0px;font-size:1em;font-weight:600;" class="siloqfont">
            <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-patents-trademarks'}" style="font-size:.8em;font-weight:600;color:tomato;" >PATENTED</a> <br> <span style="font-weight:400;font-size:.8em;">Discover why you will be happier, more successful, and feel more free!</span>
          </span>
</div>
<div class="block_container" style="margin:0px;background-color:#ffffff00;border-top:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;">
  <a style="text-decoration:none;" href="http://sislr.com" >
    <span style="color:dodgerblue;">Powered by&nbsp;</span> <img src="assets/sislr.png" align="middle" style="margin-bottom:8px;width:16px; height:16px;" />&nbsp;</a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://sislr.com"><span style="color:dodgerblue;">S</span><span
  style="color:orangered;">i</span><span
  style="color:mediumseagreen;">s</span><span
  style="color:orange;">l</span><span
  style="color:dodgerblue;">r</span><span
  class="sup"
  style="color:orangered;">™</span>
</a>
</div>

<div *ngIf="userSVC.authUser.email" style="padding:4px;margin:0px; border:0px;text-align:center;background-color:dodgerblue;font-family:Helvetica,AppleGothic,sans-serif;display: block;  clear: both;">
  <div style="padding-top:6px;width:100%;text-align:center;">
    <a *ngIf="userSVC.authUser.email" [routerLink]="['/admin/feedback']"  [queryParams]="{s: 'Feedback-Home'}" style="color:whitesmoke;font-size:.8em;font-weight:500;" >We have one powerful intelligent <b><i>{{taxonomy}}™</i></b> Zone [<b>{{zone}}</b>], Perfect Practice Platform for Real Exams. <br>It is concentrated in two words:&nbsp;<b><i>"Sustainable Practice"</i></b></a>
    <br>
  </div>
  <span>
  <button (click)="docTopic(topics[0]);"  type="button" class="siloqbutton" style="background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;">
    Mock of Today
  </button>
  <a href="https://chromewebstore.google.com/detail/{{taxonomy}}/{{chromeExtension}}" class="siloqbutton siloqfont" style="background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;"  target="_blank">Browser Extension</a>
  <a  (click)="goldiAngels()" class="siloqbutton siloqfont" style="background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;"  target="_blank">GoldiAngels™</a>
  </span>
  <br>
  <a style="font-size:.8em;text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;color:whitesmoke;" (click)="refer()">Empower Everyone&nbsp;🔗</a>
</div>
<div *ngIf="!userSVC.authUser.email" style="padding:4px;margin:0px; border:0px;text-align:center;background-color:dodgerblue;font-family:Helvetica,AppleGothic,sans-serif;display: block;  clear: both;">
  <div style="padding-top:6px;width:100%;text-align:center;">
    <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" style="color:whitesmoke;font-size:.8em;font-weight:500;" >We have one powerful intelligent <b><i>{{taxonomy}}</i></b> Zone [<b>{{zone}}</b>] Perfect Practice Platform for Real Exams. <br>It is concentrated in two words:&nbsp;<b><i>"Sustainable Practice"</i></b></a>
    <br>
  </div>
  <span>
  <button (click)="docTopic(topics[0]);"  type="button" class="siloqbutton" style="background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;">
    Mock of Today
  </button>
  <a href="https://chromewebstore.google.com/detail/{{taxonomy}}/{{chromeExtension}}" class="siloqbutton siloqfont" style="font-size:.8em;background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;"  target="_blank">Browser Extension</a>
  <a  (click)="goldiAngels()" class="siloqbutton siloqfont" style="background-color:#FF474C;color:whitesmoke;border:2px solid whitesmoke;padding:4px 6px 4px 6px;font-size:.8em;font-weight:600;"  target="_blank">GoldiAngels™</a>
  </span>
  <br>
  <a style="font-size:.8em;text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;color:whitesmoke;" (click)="refer()">Empower Everyone&nbsp;🔗</a>
</div>
<div style="height:3px;padding:0px;margin:0px;border:0px;background-color:gold;">
</div>

<div class="block_container" style="background-color:#ffffff00;border-bottom:0px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://isloq.com" >&nbsp;<img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Mock Instantly Runtime!</span></a> </div>
<alert></alert>

<div *ngIf="taxonomy===identity" class="row expanded align-center" style="width:100%;padding:0px 0px 0px 0px;text-align: center; font-family:Helvetica,AppleGothic,sans-serif;font-size:2em;font-weight:600; margin:0 auto;">
    <span  style="padding:0px;margin:0px;"><span
      style="color:dodgerblue;">S</span><span
      style="color:orangered;">i</span><span
      style="color:mediumseagreen;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup" style="color:orangered;">®</span></span>
</div>

<div style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;height:100%;width:100%;text-align:center;">
<div style="height:200px;width:100%;text-align:center; display:table;padding-top:0px;margin:auto;">
    <div style="width:100%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">
      <div class="small-12 columns" style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;color:navy; font-size:1em;font-weight:500;text-align:center;padding:0px 0px 0px 0px;">
      <div class="block_container" style="padding-top:0px;">

<!--  Search      -->
          <div style="width:45%;margin-left:auto;margin-right:auto; padding:5px 0px 0px 0px;display:flex;justify-content:center;align-items:center;">
          <input class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure {{taxonomy}} Search [Min 3 Chars]" name="siloqSearch" #siloqSearch (input)="auto()" [(ngModel)]='searchText' style="color: grey;padding:4px;height: 36px; width:100%;min-width:200px;margin:0px;"/>&nbsp;
            </div>
            <div *ngIf="showAuto" style="margin-left:auto;margin-right:auto;margin-top:0 auto; width:44%;padding-left:0px;">
              <ul *ngIf="showAuto" style="margin-left:-10px;margin-top:0px; list-style: none;display:table; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;display:inline-block;font-size:1em;border:3px lightsteelblue solid;padding: 5px; width:100%">
                <li *ngFor="let auto of ptopics; let i = index" style="-moz-border-radius: 15px; border-radius: 15px;" (click)="selectAuto(auto, i)" [class.active]="i === selectedIndex">
                  <div style="cursor:pointer;-moz-border-radius: 15px; border-radius: 15px;font-size:1em;text-align: left;padding:5px;">{{auto.hashTag}}</div>
                </li>
              </ul>
            </div>
        <!---->

        <div class="block_container" style="background-color:#ffffff00;border-bottom:0px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://siloq.com" style="color:dodgerblue;" >&nbsp;Powered By <img src="/assets/siloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;</span></a> </div>
        </div>


        <div *ngIf="taxonomy===identity" id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">

          <div *ngIf="!showAuto && topics && topics.length > 0" style="font-size:.8em;font-weight:600;width:100%;overflow:hidden;padding-top:0px;" class="doNotPrint">
            <div *ngIf="!showAuto" style="color:#617CB5;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:1em;font-weight:600; height:23px;text-align:center;" >
              <div *ngIf="topics && topics.length > 0" >
               Top ({{totalCount | number:'1.0-0'}}+) Mocks
              </div>
              <div  *ngIf="topics === undefined" style="display: inline-block; text-align:center;">
                Working...
              </div>
              <div  *ngIf="topics && topics.length === 0" style="display: inline-block;">
                No Acts
              </div>
            </div>
          </div>




        <div *ngIf="(selectedTopics && selectedTopics.length > 0)" class="container2 siloqfont" style="margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; font-size:.8em; font-weight: 200;width:90%;">
          <div *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">
            <div *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show"  valign="top">

              <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px; cursor:pointer;">
                <div  style="background-color:whitesmoke;width:100%;min-height:20px; margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;">
                  <div (click)='docTopic(topic)'  style="float:left; text-align:left;width:80%;border-bottom:1px solid lightgrey;padding:0px 8px 8px 0px;">
                    <span  style="height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="View Topic {{topic.hashTag}}"  [innerHTML]="parseTitle(topic.hashTag) | truncate : 64"></span>
                    <span *ngIf="topic.rank" style="height:auto;color:grey;font-weight:500;font-size:.85em;padding-left:4px;">(<span  style="height:auto;color:orangered;font-weight:500;font-size:.85em;">{{topic.rank}}</span>)</span>
                    <span *ngIf="getTaxon(topic, 'audioEnabled')" class="fa fa-volume-up" style="padding:0px;margin:0px;height:auto;color:dodgerblue;font-weight:500;font-size:.85em;"></span>
                    <span *ngIf="getTaxon(topic, 'goldimembersEnabled')" class="fa fa-star-o" style="padding:0px;margin:0px;height:auto;color:gold;font-weight:500;font-size:.85em;"></span>
                  </div>

                  <div style="text-align:right;min-width:60px;">
                    <input *ngIf="theUser===topic.email" class="intLink" type="button" value="✕" valign="middle" (click)='archiveTopic(topic)' title="Archive {{topic.hashTag}}" style="vertical-align:middle;color:orangered;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
                    <input class="intLink" type="button" value="❐" valign="middle" (click)='docTopic(topic)' title="View Topic" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
                  </div>
                </div>

                <div style="vertical-align:top;margin:0px;text-align:left;padding:2px 5px 5px 5px;" >
                  <div style="display:inline-block;width:100%;">
                    <span class="doNotPrint" (click)='docTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        </div>



        <div class="block_container" style="background-color:#ffffff00;border-top:0px solid gainsboro;padding:1px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.7em;font-weight:200;"><a href="http://sisloq.com"  style="color:dodgerblue;" >&nbsp;Powered By&nbsp;<img src="/assets/sisloq-logo.png" align="middle" style="margin-bottom:13px;width:16px; height:16px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">S</span><span style="color:orangered;">i</span><span style="color:mediumseagreen;">s</span><span style="color:orangered;">l</span><span style="color:dodgerblue;">o</span><span style="color:orange;">q</span><span class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;"></span></a> </div>




        <div *ngIf="userSVC.authUser.email" style="display:table;height:30px;margin-top:4px;border:0px;text-align:center;background-color:#617CB5;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
          <div  style="display:table-cell;width:29%;">
          </div>
          <div style=" border-radius: 30px; -moz-border-radius: 30px;border: 6px solid #e0e0e0;color:black;padding-top:16px 10px 10px 10px;text-align:center;min-width:300px;margin:20px auto;">
            <br>
            <a *ngIf="userSVC.authUser.email" [routerLink]="['/admin/feedback']"  [queryParams]="{s: 'Feedback-Home'}" style="color:whitesmoke;font-size:1em;font-weight:400;" >What is <b><i>{{taxonomy}}™</i></b> Zone (<b>{{zone}}</b>)?
            </a>
            <br>
            <br>
            <div style="color:whitesmoke;font-size:.8em;font-weight:600;">
              Why is it a Perfect Practice Platform for real exams, and not limited to MCAT, Citizenship, NEET, Driver Licensing, Petco Interviews, etc.,?
            </div>
            <br>
            <button [style.backgroundColor]="backgroundColor" (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" (click)='help()' class="siloqbutton" style="width:180px;border-radius: 30px; -moz-border-radius: 30px;background-color:gold;color:navy;border:0px;">Show Me More</button>
            <br>
            <br>
          </div>
          <div style="display:table-cell;width:29%;">
          </div>
        </div>


        <div *ngIf="!userSVC.authUser.email" style="display:table;height:30px;margin-top:4px;border:0px;text-align:center;background-color:#617CB5;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
          <div  style="display:table-cell;width:29%;">
          </div>
          <div style=" border-radius: 30px; -moz-border-radius: 30px;border: 6px solid #e0e0e0;color:black;padding-top:16px 10px 10px 10px;text-align:center;min-width:300px;margin:20px auto;">
            <br>
            <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" style="color:whitesmoke;font-size:1em;font-weight:400;" ><b>{{taxonomy}}™</b>  Zone (<b>{{zone}}</b>)<br>[<i style="font-weight:600;font-size:.6em">gowl-dee-mocks<span style="font-weight:500;"> (/ˈɡōldēˌmäks/)</span></i>]</a>
            <br>
            <br>
            <div style="color:gold;font-size:2em;font-weight:600;">
              1-2-3 Done.
            </div>
            <br>
            <div style="color:whitesmoke;font-size:.8em;font-weight:600;">
              1. Onboard your questions/answers and convert them to the exam immediately
              <br>
              <br>
              2. Publish and practice them as many times as you want
              <br>
              <br>
              3. Earn returns while millions practicing your exams
              <br>
              <br>
              How does it help us to practice <b><i>"Sustainable Learning"</i></b> beyond our lives?
            </div>
            <br>
            <button [style.backgroundColor]="backgroundColor" (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" (click)='help()' class="siloqbutton" style="width:180px;border-radius: 30px; -moz-border-radius: 30px;background-color:gold;color:navy;border:0px;">Show Me More</button>
            <br>
            <br>
          </div>
          <div style="display:table-cell;width:29%;">
          </div>
        </div>
        <div style="display:table;height:3px;padding-top:0px;margin-top:0px;border:0px;text-align:center;background-color:orange;font-family:Helvetica,AppleGothic,sans-serif;color:dimgrey;width:100%;">
        </div>
        <br>





        <div style="display:table;border-top:0px solid grey;text-align:center;padding-top:0px;height:20px;width:100%;">
          <div class="siloqlogofont" style="text-align:center;height:40px;margin:auto;padding-left:30px;font-size:1em;font-weight:200; padding:0px;display:inline;">
            <a href="https://www.facebook.com/Siloq" target="_blank" class="fa fa-facebook" style="height:20px;width:20px;"></a>
            <a href="https://twitter.com/SiloQInc" target="_blank" class="fa fa-twitter" style="height:20px;width:20px;"></a>
            <a href="https://www.google.com/search?q=siloq" target="_blank" class="fa fa-google" style="height:20px;width:20px;"></a>
            <a href="https://www.linkedin.com/company/18317376" target="_blank" class="fa fa-linkedin" style="height:20px;width:20px;"></a>
            <a href="https://www.youtube.com/channel/UCSTBLoXXBBU0H1jydxd3-6g" target="_blank" class="fa fa-youtube" style="height:20px;width:20px;"></a>
            <a href="https://www.instagram.com/siloqinc/" target="_blank" class="fa fa-instagram" style="height:20px;width:20px;"></a>
            <a href="https://www.glassdoor.com/Overview/Working-at-Siloq-EI_IE1878370.11,16.htm" target="_blank" class="fa fa-glassdoor" style="height:20px;width:20px;">G</a>
            <a href="https://en.wikipedia.org/wiki/siloq" target="_blank" class="fa fa-wikipedia" style="height:20px;width:20px;">W</a>
          </div>
        </div>

        <br>

        <div style="background-color:white;height:140px;width:100%;text-align:center; display:table;padding:0px;">
          <div style="width:30%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">
            <div class="small-12 columns" style="padding:2px;">
        <span style="padding-top:4px; color:whitesmoke; width:60%; display:inline-block;text-align:center;" class="siloqlogofont">
          <ul  style="border: 4px solid #e0e0e0;list-style: none;color:#383838;background-color:white;display:table; margin:0 auto; font-family:Helvetica,AppleGothic,sans-serif; -moz-border-radius: 15px; border-radius: 15px;inline-block;font-size:.8em;font-weight:400;padding: 10px; height:20px;text-align:left;">
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span style="color:orange;">•</span> <b style="color:orangered;">{{taxonomy}}™</b> is an instant publishing star and a smart <span style="color:dodgerblue;">AI</span> Engine
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span
              style="color:orangered;">•</span> Simply search, glean, and publish in a single step
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span
              style="color:dodgerblue;">•</span> Work together, share and secure in a controlled way
            </li>
            <li  style="-moz-border-radius: 15px; border-radius: 15px;"> <span style="color:mediumseagreen;">•</span> <b style="color:orangered;">{{taxonomy}}™</b> anywhere across applications and platforms
            </li>
          </ul>
        </span>
            </div>
          </div>
        </div>


        <div style="text-align:center;padding-top:0px;height:150px;width:100%;display:table;">
          <div style="border:0px;text-align:center;background-color:white;">
            <div style="height:300px;margin:auto;font-family:Helvetica,AppleGothic,sans-serif;color:mediumseagreen;font-size:1em;font-weight:100;">
              <img src="/assets/admin-{{taxonomy}}-gold.png" align="middle" style="margin:20px 0px 0px 0px;width:250px; height:250px;" />
              <br>
              <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-we-belong-here'}" style="font-weight:500; padding:10px;text-decoration:none;color:orangered;">
                "Good Question, We Belong Family"™
              </a>
              <br>
              <br>
              <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" >&nbsp;<img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span>&nbsp;<a style="text-decoration:none;" href=""><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span><span style="color:dodgerblue;">&nbsp;Zone <b>[ {{zone}} ]</b></span></a>
              </div>
            </div>
          </div>
          <br>
          <br>
          <div style="width:100%;">
            <span style="color:dodgerblue; padding-bottom:20px; width:60%;font-size:.8em;font-weight:400; display:inline-block;text-align:center;" class="siloqlogofont"><span style="color:orangered;">*</span> Right now, {{taxonomy}} works on all chromium based browsers like Chrome, Edge, Opera and Brave with limited features on mobile devices.</span>
          </div>
        </div>
        <br>
        <div style="height:400px;border:0px;text-align:center;background-color:dodgerblue;font-family:Helvetica,AppleGothic,sans-serif;color:green ">
          <div style="margin:auto;padding:5%;">
            <a style="color:font-size:2.5em;font-weight:600;" >Why {{taxonomy}}?</a>
            <div style="font-size:.9em;font-weight:500;margin:30px;text-align:
                      justify; text-justify: inter-word;color:whitesmoke;">Whether you’re an
              individual looking forward to publishing your work
              instantly to advance your career or an enterprise team
              looking to cut cycle times, speed up the searching
              process, and give your teammates the skills to realize
              your strategies, {{taxonomy}} removes the challenges and
              roadblocks slowing you down. {{taxonomy}} is advancing everyone
              in the world’s workforce, and that starts with making
              your day-to-day searching, gleaning, and publishing more
              efficient and effective—and giving you more to
              celebrate.
              <br>
              <br>
              <div style="text-align:center; width:100%;">
                <a *ngIf="!userSVC.authUser.email" [routerLink]="['/signup']" class="siloqbutton" style="border:0px;color:dodgerblue;">See our Solution</a>
                <a *ngIf="userSVC.authUser.email" [routerLink]="['/login']" class="siloqbutton" style="border:0px;color:dodgerblue;">Explore our Solution</a>
              </div>
              <br>
              <br>
            </div>
            <br>
          </div>
          <br>
        </div>

        <br>
        <br>
        <div style="display:table; width:100%;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5; ">
          <div style="margin:auto;padding:2%;">
            <a style="font-size:1em;font-weight:600;" >Let's get in Touch</a>
            <br>
            <br>
            <a *ngIf="!userSVC.authUser.email" style="font-size:.9em;font-weight:500;" >For more information or a demo request, just drop us a message.</a>
            <a *ngIf="userSVC.authUser.email" style="font-size:.9em;font-weight:500;" >For more information or a feedback, just drop us a message.</a>
            <br>
            <br>
            <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" class="siloqbutton" style="border:0px;color:tomato;">Contact Us</a>
            <a *ngIf="userSVC.authUser.email" [routerLink]="['/admin/feedback']" [queryParams]="{s: 'Feedback-Home'}" class="siloqbutton" style="border:0px;color:tomato;">Feedback To Us</a>
            <br>
          </div>
          <br>
        </div>
      </div>


      <div style="width:100%;">
        <span style="color:#789885; padding:20px; width:60%;font-size:.8em;font-weight:600; display:inline-block;text-align:center;" class="siloqlogofont"> <span style="color:orangered">{{taxonomy}}™</span> - Perfect Practice Platform for Real Classroom Exams.</span>
      </div>
      <br>
    <div style="display:table;width:100%;height:100px;vertical-align:middle;">
      <div style="width:100%;height:100%;margin:0 auto;text-align:center;background-color:orange;float:left;">
        <br>
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"I practice, I should practice perfect, and I'm so there"</a>
        <br>
        <br>
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.8em;font-weight:400;color:whitesmoke;padding:1%;">
          I practice, I should practice perfect, and I'm so there. I practice, I should practice perfect, and I'm so there." I practice, I should practice perfect, and I'm so there.
        </a>
        <br>
        <br>
        <br>
      </div>
      <br>
      <div style="text-align:center;padding:0px;width:100%;vertical-align:top;">
        <img src="/assets/kid-reading.jpg" align="middle" style="margin:0px 0px 0px 0px;width:50%; height:33%;" />
      </div>
    </div>

    <br>
      <br>
      <div class="block_container" style="background-color:#ffffff00;border-bottom:0px solid gainsboro;padding:0px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/admin-{{taxonomy}}.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a>&nbsp;<span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">{{taxonomy}}</span><span class="sup" style="color:mediumseagreen;">&trade;</span><span style="color:dodgerblue;">&nbsp;Zone <b>[ {{zone}} ]</b> Wonder!</span></a>
    <br>
        <br>

      <div style="width:100%;height:20px;">
        <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
        <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:dodgerblue;">
          <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"The future of intelligence is here. For a demo, contact us"</a>
        </div>
      </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:right;text-align:left;height:10px;padding:50px;width:50%;background-color:orangered;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"I mock, I should mock more, and so I am"</a>

      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:mediumseagreen;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"I think of an Idea, I should publish my Idea to my peer community, and therefore I am"</a>
      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:right;text-align:left;height:10px;padding:50px;width:50%;background-color:orange;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:1em;font-weight:600;">"Sustainable Intelligence Sustainable Learning Runtime!"</a>
      </div>
    </div>

    <div style="width:100%;height:20px;">
      <div style="display:table-cell;text-align:left;padding:0px;width:50%;"> </div>
      <div style="display:table-cell;float:left;text-align:right;height:10px;padding:50px;width:50%;background-color:dodgerblue;">
        <a [routerLink]="['/publish']" [queryParams]="{h:'loq'}" style="font-size:.9em;font-weight:500;">"The future of intelligence is here.  For a demo, contact us"</a>
      </div>
    </div>
      </div>
    </div>
  </div>

  <div style="display:table;width:100%;border-top:1px solid grey;text-align:center;margin-top:60px;padding-top:0px;height:40px;">
      <div class="siloqlogofont" style="display:table-cell;text-align:center;height:40px;margin:auto;font-size:1em;font-weight:200; padding:1%;">
        <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-privacy'}" style="text-decoration:none;color:teal;">Privacy</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-terms-and-conditions'}" style="text-decoration:none;color:teal;">Terms</a>&nbsp;&nbsp;
           <a (click)="goldimembers()" style="cursor:pointer;text-decoration:none;color:teal;">Pricing</a>&nbsp;&nbsp;
           <a (click)="goldiAngels()" style="cursor:pointer;text-decoration:none;color:teal;">Invest</a>&nbsp;&nbsp;
<!--           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-invest'}" style="text-decoration:none;color:teal;">Invest</a>&nbsp;&nbsp;-->
           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-news'}" style="text-decoration:none;color:teal;">News</a>&nbsp;&nbsp;
           <a [routerLink]="['/careers']" style="text-decoration:none;color:teal;">Careers</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-help'}" style="text-decoration:none;color:teal;">Help</a>&nbsp;&nbsp;
           <a *ngIf="!userSVC.authUser.email" [routerLink]="['/contact']" style="text-decoration:none;color:teal;">Contact&nbsp;&nbsp;</a>
           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-about'}" style="text-decoration:none;color:teal;">About</a>&nbsp;&nbsp;
           <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-cookie-use'}" style="text-decoration:none;color:teal;">Cookies</a>&nbsp;&nbsp;
<!--           <a [routerLink]="['/publish']" [queryParams]="{h:'siloq-tamil'}" style="text-decoration:none;color:teal;">Siloq®-Tamil</a>&nbsp;&nbsp;-->
      </div>
  </div>
<home-footer *ngIf="ready"></home-footer>
















