import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../user/model/user';
import {Login} from '../../services/user.service';
import { Router } from '@angular/router';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { SiloqpayComponent } from '../siloqpay/siloqpay.component';
import { FormsModule } from '@angular/forms';
import {CookieService} from 'ng2-cookies';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'admin-goldiangels',
  templateUrl: './adminGoldiAngels.component.html',
  styleUrls: ['./adminGoldiAngels.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, SiloqpayComponent, CommonModule],
  standalone: true
})

export class AdminGoldiAngelsComponent {
  password2= "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public goldiAngelsPremiumInvestment = '10,000';
  public goldiAngelsPrestigeInvestment = '100,000';
  public goldiAngelsGoldInvestment = '1,000,000';
  public currency = "USD"
  public theUser = "";
  ttl = "30";
  price = "10,000";
  public theGoldiUser = "";
  user = new User("", "", "");
  siloqpayUrl = "";
  callback = "";
  checkedPrestige=true;
  checkedGold=false;
  checkedPremium=false;
  public otherOptions = false;
  public gold = "gold"; //TODO: angel
  public backgroundColor  = "gold";
  public backgroundColor2  = "mediumseagreen";
  public backgroundColor3  = "dodgerblue";
  public backgroundColor4  = "orangered";
  public cb  = "/admin/goldiangels";

  constructor( private alertService: AdminAlertService, public userSVC: UserService, private router: Router, public loginInfo: Login, private _cookieService:CookieService){
  	loginInfo = new Login();
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    if(this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
    }

    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.goldiAngelsPremiumInvestment = config.goldiAngelsPremiumInvestment;
    this.goldiAngelsPrestigeInvestment = config.goldiAngelsPrestigeInvestment;
    this.goldiAngelsGoldInvestment = config.goldiAngelsGoldInvestment;
    this.siloqpayUrl = config.siloqpayUrl;
    this.callback = config.siloqpayCallback;
    this.currency = config.currency;
	}

   validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
   }

  showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

//TODO: Avoid this call.
subscribe() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
            this.router.navigate(['/admin/siloqpay'],  { queryParams: { a : this.price, c : this.currency, u : user.id, l : this.ttl, p : this.gold, g : this.callback, cb : this.cb}});
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

subscribeGold(){
    this.checkedGold=true;
    this.checkedPrestige=false;
    this.checkedPremium=false;
    this.price = this.goldiAngelsGoldInvestment;
  }

subscribePrestige(event: any){
    this.checkedGold=false;
    this.checkedPrestige=true;
    this.checkedPremium=false;
    this.price = this.goldiAngelsPrestigeInvestment;
  }

subscribePremium(){
    this.checkedGold=false;
    this.checkedPrestige=false;
    this.checkedPremium=true;
    this.price = this.goldiAngelsPremiumInvestment;
  }

  done() {
    this.router.navigate(['/admin/account']);
  }

 }
