import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AdminAlertService } from './services/admin-alert.service';
import { AlertInterface } from './types/alert.interface';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'admin-alert',
  templateUrl: './admin-alert.component.html',
  styleUrls: ['./admin-alert.component.css'],
  imports: [CommonModule],
  standalone: true
})

export class AdminAlertComponent implements OnInit {
  alert?: AlertInterface;
  timeoutId?: number;
  alertClass= "";

  public class = "";
  public subclass = "";
  public taxonomy = "";

  constructor(private alertService: AdminAlertService) {}

  ngOnInit(): void {
    this.getClass();
    this.alertService.getAlert().subscribe((alert) => {
      this.alert = alert;
      this.resetTimer();
    });
    this.class = config.class;
    this.subclass = config.subclass;
    this.taxonomy = config.taxonomy;
  }

  resetTimer(): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => {
      this.alert = undefined;
    }, 2000);
  }

  getClass() {
    this.alertClass = 'alert';//, '+this.alert.type;
  }
}
