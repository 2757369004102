import { Component, OnInit, NgZone } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../services/tag-service.service';
import {TopicService} from '../services/topic.service';
import {Tag} from '../admin/tag/model/tag';
import {Topic} from '../admin/topic/model/topic';
import {User} from '../admin/user/model/user';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../services/data.service";
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../admin/tag/tag.truncate.pipe';
import { SafeHtmlPipe } from '../admin/tag/tag.safehtml.pipe';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { FooterComponent } from '../footer/footer.component';

declare function escape(s:string): string;
declare function unescape(s:string): string;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent, CommonModule, TruncatePipe, SafeHtmlPipe],
  standalone: true
})

export class ProfileComponent implements OnInit {

  tags: Tag[];
  ctags: Tag[];
  utags: any;
  public theUser = "";
  theUserId="";
  public theGoldiUser = "";
  loggedinUser="";
  user = new User("", "", "");
  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  public showAuto: boolean;
  public unmodel: Topic = new Topic('', '', '');
  private umodel: Topic = new Topic('', '', '');
  private branch: Topic = new Topic('', '', '');
  public selectedTopic;
  selectedIndex: number = 0;
  searchText = "";
  currentPage: number = 1;
  pageSize = 8;
  totalCount: number = 0;
  pageCount: number = 0;
  sortKey = "-updatedate";
  selectAll = false;
  host = window.location.protocol+"//"+window.location.hostname;
 public taxonomy = "";
 public class = "";
 public subclass = "";
 public backgroundColor  = "gold";
  public ready = false;

  constructor(private alertService: AlertService, private titleService: Title, private ngZone: NgZone, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.loggedinUser = this.getCookie("email");
    this.theUserId = this.getCookie("uid");
    this.theGoldiUser = this.getCookie("gmt");
    this.host = window.location.protocol+"//"+window.location.hostname;
    this.unmodel = new Topic('', '', '');
    this.activatedRoute.queryParams.subscribe(params => {
      this.currentPage = parseInt(params['p']) || 1;
      this.searchText = params['q'] || "";
      if(this.searchText) {
        this.searchText = unescape(this.searchText);
      }

    this.theUser = params['d'] || "";
    if(this.theUser) {
      this.theUser = window.atob(this.theUser);
    }
      this.taxonomy = config.taxonomy;
      this.class = config.class;
      this.subclass = config.subclass;
    });

    if(this.theUser && this.loggedinUser && this.theUser === this.loggedinUser) {
      /*
      if(!this.loggedinUser) {
            this.router.navigate(['login']);
      }
      */
      //TODO: if Gold member
      this.titleService.setTitle(this.curbemail(this.theUser));
      this.loadTopics();
    } else {
      this.loadPublicTopics();
    }
  }

  loadPublicTopics() {
    this.topicService.publicSearchTopics(escape(this.searchText), this.sortKey, "128")
      .subscribe(
        topics => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  loadTopics() {
    this.topicService.searchTopics(escape(this.searchText), this.sortKey, this.theUser, "128")
      .subscribe(
        topics => {
          this.topics = topics; //Bind to view
          if(this.topics !== undefined) {
            this.totalCount = this.topics.length;
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);
            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

setPageVisible() {
    this.selectedIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedTopics = [];
    var j = this.selectedIndex;
    for (let i = j; i < this.topics.length; i++) {
      this.topics[i].show = false;
      /*
      if (this.topics[i].hashTag.indexOf("_exam") > 0) {
           continue;
      }
      */
      if(j >= this.selectedIndex && j < end) {
        this.topics[i].hashTag = unescape(this.topics[i].hashTag);
        this.topics[i].show = true;
        this.topics[i].value = "";
        this.selectedTopics.push(this.topics[i]);
        j++;
      }
    }
    this.selectedTopic = this.topics[this.selectedIndex];
    if (this.selectedTopic) {
      this.data.changeMessage(this.selectedTopic.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTopic.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTopic.email));
      this.selectedTopic.rank = this.selectedIndex + 1;
    }
    this.ready = true;
  }

 @HostListener('keydown', ['$event'])
keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
      if(this.theUser) {
            this.loadTopics();
          } else {
            this.loadPublicTopics();
          }
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag);
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
      this.data.changeMessage(this.selectedTopic.hashTag);
    }
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
    this.data.changeMessage(this.selectedTopic.hashTag);
  }

  publicTopic(topic: any) {
  //TODO: Are we allowing documentation?
  /*
    var lx = topic.hashTag.lastIndexOf("_public");
    if(lx > 0) {
      this.router.navigate(['public'],  { queryParams: { h : topic.hashTag.substring(0, lx), d : window.btoa(topic.email)}});
    }
    */
  }

  profile(t: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {d : window.btoa(t.email)}
        });
  }

  profileEx(p: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {d : window.btoa(this.theUser), p : p}
        });
  }

  myTopic(h: any) {
    this.router.navigate(['admin/topic'],  { queryParams: {d : window.btoa(this.loggedinUser), h : h}});
  }

  getParentTopic(topic: any): string {
    var lx = topic.hashTag.lastIndexOf("_public");
    if (lx >= 0) {
     return topic.hashTag.substring(0, lx);
    }

    lx = topic.hashTag.lastIndexOf("_press");
     if (lx >= 0) {
         return topic.hashTag.substring(0, lx);
        }

    lx = topic.hashTag.lastIndexOf("_mock");
     if (lx >= 0) {
         return topic.hashTag.substring(0, lx);
        }

    return topic.hashTag;
  }

  getUserByEmail() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

  curbemail(email: string): string {
  if(email) {
      var index = email.indexOf("@");
      if(index > 0) {
        return email.substring(0, index);
      }
       else {
        return email;
      }
    }

    return email;
  }

    renderImage(t: any) {
      var s = t;
      t = t.replace(/<(?:.|\n)*?>/gm, '');
       if (t.startsWith("http://") || t.startsWith("https://")) {
           const img = new Image();
           img.src = t;
           var w = 60;
           var h = (w/img.width)*img.height;
           t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
           return t;
        }
           return s;
      }

    parseTitle(t: string): string {
          return t.replace(/_/g, " ");
    }

  public sort(event): void {
    if(event.target) { this.sortKey=this.sortKey.includes('-') ? event.target.value : '-'+event.target.value; }
    this.setFirstPage();
  }

  public setFirstPage() {
    this.loadTopics();
  }

  notify() {
    this.showAlert(AlertTypeEnum['warning'], "Coming soon!");
  }

    refer() {
        this.copyTinyUrlToClipboard();
        this.showAlert(AlertTypeEnum['success'], this.taxonomy+" referral URL is copied to the clipboard, Paste anywhere!");
        return;
    }

    public copyTinyUrlToClipboard() {
      var dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.value = window.location.href;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    }
}

