<admin-header></admin-header>
 <body style="height:100%;width:100%; margin:0px;">
 <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:10px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
   <admin-alert></admin-alert>
   <ng-template id="modal" #modalTemplate>
    </ng-template>
   <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
     <a class="fa fa-angle-double-left intLink" title="Done with the sharing of '{{model.hashTag}}'" (click)='docTopic(model)'  valign="middle" style="color:#FF474C;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:500;"></a>
     <a class="intLink fa fa-bell-o" title="Notify the loq of '{{model.hashTag}}'" (click)='notify()'  valign="middle" style="color:orangered;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
   </div>
 </div>
 <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">SHARE</div>
 <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" />&nbsp;</a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
   style="color:dodgerblue;">i</span><span
   style="color:mediumseagreen;">s</span><span
   style="color:orangered;">l</span><span
   style="color:orange;">o</span><span
   style="color:dodgerblue;">q</span><span
   class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
 </div>
 <div id="doc" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
   <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
     <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{model.hashTag}}</div>
   </h2>
<div style="width:100%; padding-top: 0px;margin:0 auto;">
  <h4 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;padding-top:10px;">Topic Contributor(s)</h4>
          <div *ngIf="contributors && contributors.length > 0" class="container2" >
            <div style="text-align: center;width:90%;margin:10px auto;" *ngFor="let u of contributors; let j = index">
              <div style="display:table-cell;float:left;width:90%;text-align:left;">{{u}}
              </div>
              <div  *ngIf="theUser != u" style="display:table-cell;color:mediumseagreen; width:10%;text-align:right;">
                <a style="border:0px;float:right;background-color:#ffffff00;color:orangered;height:20px;width:20px;font-weight:300;font-size:1em;"  class="fa fa-angle-double-down intLink" title="Remove User from ACT" (click)="removeUserFromTopic(u)"></a>
              </div>
            </div>
          </div>
  </div>
  <h4 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;padding-top:10px;">Your Friend(s)</h4>
  <div *ngIf="user.users && user.users.length <= 0"  class="siloqbox" style="text-align:left;font-family:arial,sans-serif; font-weight:100;margin:auto; width:90%;color:orangered; ">
    No Friend(s)
  </div>
      <div class="small-12  columns">
        <div *ngIf="user.users && user.users.length > 0" class="container2">
          <div style="text-align: center;width:90%;margin:10px auto;" *ngFor="let u of user.users; let j = index">
            <div style="display:table-cell;float:left;width:90%;text-align:left;">{{u}}
            </div>
            <div  *ngIf="theUser != u && model.users.indexOf(u) < 0" style="display:table-cell;color:mediumseagreen; width:10%;text-align:right;">
              <a style="border:0px;float:right;background-color:#ffffff00;color:mediumseagreen;height:20px;width:20px;font-weight:300;font-size:1em;"  class="fa fa-angle-double-up intLink" title="Add User to ACT" (click)="addUserToTopic(u)"></a>
            </div>
          </div>
        </div>
</div>
 </div>
<br>
<br>
<dummy-footer></dummy-footer>
