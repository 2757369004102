<admin-header></admin-header>
<body tyle="height:100%;width:100%; margin:0px;">
<div style="text-align:center; width:100%;padding-top:5%;min-height: calc(100vh - 70px);">
  <admin-alert></admin-alert>
  <ng-template id="modal" #modalTemplate>
    </ng-template>
<div>

  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" >Invite a friend<a style="text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;" (click)="refer()">&nbsp;🔗</a>
    </h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
    <br>
  </div>
  <br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
            <input type="text" class="siloqinput" placeholder="Email" id="email" style="min-width:300px;"
                [(ngModel)]="femail" name="email" required #em="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            <div [hidden]="em.valid || em.pristine" class="siloqfont">
            </div>
        </label>
    </div>
    <br>
    <div class="small-12  columns">
      <div  class="siloqfont">
        <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='invite()' class="siloqbutton">Invite</a>
        <a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
    <br>
    <br>
      </div>
    </div>
    <div class="small-12  columns">
    <h4 style="width:100%;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;color:#789885">Your Friend(s)</h4>
          <div *ngIf="user.users && user.users.length > 0" class="container2" style="margin-left:4%;">
            <div style="text-align: center;width:90%;margin:10px auto;" *ngFor="let u of user.users; let i = index" (click)="selectFriend(u, i)"  (mouseenter)="browseIndex=i;">
              <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px; cursor:pointer;">
              <div style="display:table-cell;float:left;width:90%;text-align:left;">{{u}}
              </div>
              <div  *ngIf="theUser != u" style="display:table-cell;color:mediumseagreen; width:10%;text-align:right;">
                    <input style="border:0px;float:right;background-color:#ffffff00;color:orangered;height:19px;width:19px;font-weight:300;font-size:1em;" type="button" class="intLink" title="Remove friend" value="✘" (click)="remove(u)"/>
              </div>
              </div>
            </div>
          </div>
        </div>
</div>
</form>
    <br>
    <br>
    <br>
<dummy-footer></dummy-footer>
