import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import {Observable, throwError, forkJoin} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tag} from '../admin/tag/model/tag';
import {Topic} from '../admin/topic/model/topic';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TagService {
  constructor(private http: HttpClient) { }

  getTags() : Observable<Tag[]> {
            return this.http.get<Tag[]>(config.tagsUrl, { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  searchTags(s: string, k: string, d: string) : Observable<Tag[]> {
        return this.http.get<Tag[]>(config.tagsUrl + "SearchTag/?s=" + s + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  filterTags(h: string, s: string, v: string, k: string, d: string) : Observable<Tag[]> {
        return this.http.get<Tag[]>(config.tagsUrl + "FilterTag/?h=" + h + "&s=" + s + "&v=" + v + "&k=" + k + "&d=" + d)
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getTopicUserTags(h: string, d: string) : Observable<Tag[]> {
    let body = new Tag("", "", "", "", "");
    body.hashTag = h;
    body.email = d;
    let bodyString = JSON.stringify(body);
    return this.http.post<Tag[]>(config.tagsUrl + "/tags/topic/user", bodyString, { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  getTopicSourceTags(h: string, s: string) : Observable<Tag[]> {
    let body = new Tag("", "", "", "", "");
    body.hashTag = h;
    body.source = s;
    let bodyString = JSON.stringify(body);
    return this.http.post<Tag[]>(config.tagsUrl + "/tags/topic/source", bodyString, { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }

  addTag (body: Tag): Observable<Tag> {
    let bodyString = JSON.stringify(body);
    return this.http.post<Tag>(config.tagsUrl, {"topicid": body.topicId, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "tags": body.tags, "name": body.name, "value": body.value, "source":body.source}, { withCredentials: true })
  }

/*
  addMultiTags (body: Tag[]): Observable<Tag[]> {
    let bodyString = JSON.stringify(body);
    const requests = body.map(item => this.http.post<Tag>(config.tagsUrl, item, { withCredentials: true }));
    return forkJoin(requests);
  }
*/

  addTags (body: Tag[]): Observable<Topic> {
    let bodyString = JSON.stringify(body);
//     alert(bodyString);
    return this.http.post<Topic>(config.tagsUrl+"tags", bodyString, { withCredentials: true })
  }

  updateTag (body: Tag): Observable<Object> {
   let bodyString = JSON.stringify(body);
    return this.http.put<Object>(config.tagsUrl, bodyString, { withCredentials: true })
  }

  updateTagsTopicName(oName, nName): Observable<Object> {
     return this.http.put<Object>(config.tagsUrl+"/"+oName+"/"+nName, "", { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }

  deleteTagName (id: String): Observable<Object> {
    return this.http.delete<Object>(config.tagsUrl+"/name/"+id) // ...using delete request
  }

  deleteTag (id: String): Observable<Object> {
    return this.http.delete<Object>(config.tagsUrl+id) // ...using delete request
  }

  rankTag (body: Tag): Observable<Object> {
     let bodyString = JSON.stringify(body);
    return this.http.put<Object>(config.tagsUrl+"/rank/", bodyString, { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
  }
}
