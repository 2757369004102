<admin-header></admin-header>
    <div class="doNotPrint" style="margin-top:80px;display:table;width:100%; height:18px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 5px;">
      <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
      </div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;margin:0 auto; color:mediumseagreen;font-size:1em;font-weight:500;vertical-align:
                 middle;">CAREERS</div>
      <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; float:right;width:40%;margin-right:10px;text-align:right;padding:0px 5px 0px 12px;">
      </div>
    </div>
<admin-alert></admin-alert>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span

      style="color:dodgerblue;">{{taxonomy}}</span><span
      class="sup"
      style="color:mediumseagreen;">&trade;</span>
        Publish Instantly! </a>
    </div>
    <br>
    <br>
<div class="small-12 columns  siloqlogofont">
  <h3 class="block_container siloqlogofont" >Introduce a friend<a style="text-align:center;min-width:30%; min-height:30px; font-family: Arial, Helvetica, sans-serif;padding-top:10 auto;cursor:pointer;" (click)="refer()">&nbsp;🔗</a></h3>
  <br>
  <a href="/" target="_self">
    <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
      GoldiMocks™
    </button>
  </a>
  <br>
</div>
<br>
 <div class="container2" style="width:90%; margin:auto; padding-top: 10px; text-align:center;">
   <h2  style="color:mediumseagreen;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Welcome, Be True To Yourself&nbsp;&nbsp;</h2>
   <div>
     <input type="file" (change)="FileChangeEvent($event)" />

     <label for="file-upload" class="custom-file-upload">
       <i class="fa fa-cloud-upload"></i> Select Resume
     </label>
     <input id="file-upload" type="file" (change)="FileChangeEvent($event)"/>

   </div>
        <br>
  <div *ngIf="CurrentFile"  class="siloqbutton" style="padding: 2px; font-family:Helvetica,AppleGothic,sans-serif; color:cornsilk; display:table; width:90%;margin:0 auto;" >
        <br>
        <img [src]="imageSource" style="width:200px;height:{{renderImage(imageSource)}}px;">
        <br>
        <br>
        </div>
        <br>

 </div>
    <div  style="width:90%; margin:auto; padding-top: 20px; text-align:center;">
      <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='notify()' class="siloqbutton">Upload</a>
      <a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
    </div>

<br>
<br>
<br>
<br>
<admin-footer></admin-footer>
