<header></header>
<div class="content" style=" background-size:cover; width:100%;text-align:center; display:table;padding-top:80px;">
  <div class="content-inside" style="min-width:300px;width:25%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">
	<br>
	<form>
    <div class="small-12 columns">
      <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Sign In</h3>
      <br>
        <a href="/" target="_self">
        <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
          GoldiMocks™
        </button>
      </a>
    </div>
    <alert></alert>
    <br>
		<div class="small-12 columns">
			<label>
			<input type="text" autocomplete="username" class="siloqinput" placeholder="Email" id="email" [(ngModel)]="email" name="email" required #em="ngModel" style="min-width:300px;">
      <div [hidden]="em.valid || em.pristine" style="margin:auto;text-align:left;width:300px;color:orangered;padding-top:5px;" class="siloqfont">
			  Email required
			</div>
			</label>
		</div>
		<br>
		<div class="small-12 columns">
			<label>
			<input [type]="passwordType" autocomplete="current-password" class="siloqinput" placeholder="Password" id="passwd"  [(ngModel)]="passwd" name="passwd" required #pwd1="ngModel" style="min-width:300px;margin-left:20px;">&nbsp;<a (click)="togglePasswordVisibility()" class="fa fa-eye" style="cursor:pointer;color: orangered;"></a>
      <div [hidden]="pwd1.valid || pwd1.pristine" style="margin:auto;text-align:left;width:300px;color:orangered;padding-top:5px;" class="siloqfont">
			  Password required
			</div>
			</label>
		</div>
		<br>
		<div class="small-12 columns">
			<div style="min-width:300px;width:25%;text-align:left;margin: auto;font-weight:500;" class="siloqfont">
				<a [routerLink]="['/forgot']">Forgot password?</a>
			</div>
		</div>
		<div class="small-12 columns">
      <button [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" style="width:120px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;" (click)='login()' class="siloqbutton">Sign In</button>
		</div>
	<br>
		<hr style="min-width:300px;width:25%;">
		<br>
    <div class="small-12 columns">
      <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Welcome</h3>
      <br>
      <a href="/" target="_self">
        <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
          GoldiMocks™
        </button>
      </a>
    </div>
		<br>
    <div class="small-12 columns">
      <button [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = '#617CB5'" (mouseout)="backgroundColor2 = 'dodgerblue'" style="width:120px;background-color:gold;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;" (click)='signup()' class="siloqbutton">Sign Up</button>
    </div>
	<br>
	<br>
	<br>
	<br>
    <footer class="footer"></footer>
