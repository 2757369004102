<admin-header></admin-header>
  <div  style="text-align:center; width:100%; padding-top:80px;">
  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" >GoldiMembers™<a (click)="refer()" style="margin:20 auto;cursor:pointer;color:orangered;">🔗</a>
    </h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
  </div>
  <admin-alert></admin-alert>
  <div class="container" style="padding:0px 0px 0px 0px;font-family:Helvetica,AppleGothic,sans-serif;margin:0 auto;width:100%;">
  <div class="row" style="min-width:330px;width:330px;margin:0 auto;">
    <div class="col-sm"></div>
    <div class="col-sm" style="text-align:center;padding:0px;">
      <br>



    <div class="container2" style="padding:0px;width:90%;text-align:center;margin:0 auto;">
      <div class="small-12  columns" style="width:256px;text-align:center;margin:0px  auto;border-width:0px;padding-bottom:0px;">
        <div style="text-align:center;margin:auto;" class="siloqfont">
          <button type="button" class="siloqbutton" style="background-color:whitesmoke;border:4px solid gold;padding:4px 8px 6px 8px;font-weight:500;"><div class="ms-text-center" style="font-weight:600;font-size:1.4em;"><img src="assets/sweat.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo" style="margin-bottom:16px;width:24px; height:24px;" /><span style="color:dodgerblue;">S</span><span style="color:mediumseagreen;">i</span><span style="color:orangered;">l</span><span style="color:orange;">o</span><span style="color:dodgerblue;">q</span><span  style="color:#617CB5;">Pay</span><span class="sup" style="color:orangered;">&reg;</span></div></button>
        </div>
      </div>
      <div  style="background-color:dodgerblue; border:4px solid gold;border-radius: 8px; -moz-border-radius: 8px;padding:10px;">
        <div  style="color:gold;padding-bottom:5px;">{{d.toUpperCase()}}</div>
        <div  style="padding:4px;margin:0 auto;color:whitesmoke;"><i><b>${{a}}</b> USD </i><span *ngIf="p==='gold'"><i>/<b>{{l}}</b> Day(s)</i></span></div>
        <br>
        <img class="intLink" title="{{taxonomy}}" style="width:100px; height:100px" src="{{qrcode}}" />
        <br>
        <br>
      </div>
      </div>
    </div>
    <br>
    <div class="container2" style="padding:0px;width:90%;text-align:center;margin:0 auto;">
    <div class="col-sm">
    <div id="content" class="hide">
      <div class="ms-card ms-fill">
        <div class="ms-card-content">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
      </div>
      <div id="payment_options"></div>
    </div>
      </div>
  </div>
    <br>
    <div class="small-12  columns">
      <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
        <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
        <br>
      </div>
    </div>
  </div>
</div>
    <br>
    <br>
    <br>
<admin-footer></admin-footer>
