
<body style="height:100%;width:100%; margin:0px;">
<div class="doNotPrint Row" style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;padding-top:0px;height:42px; border-bottom:1px solid #ffffff00;left:0; top:0; position:fixed;background-color:#f0f0f0;height:38px;">
  <div style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif; display:table-cell;font-size:1em;font-weight:600px;height:38px; width:100%; padding:0px 5px 0px 5px; display:table;text-align:left;margin:0 auto;" >
    <div  style="height:1px;width:100%;background-color:whitesmoke;">
    </div>
    <div  style="display:table;text-align:center;padding:10px 1% 0px 0px;width:92%;font-size:1em;font-weight:600;margin:0 auto;" >
      <a (click)="home()" ><img src="assets/admin-GoldiMocks-gold.png" align="middle" title="HOME" alt="HOME" style="cursor:pointer;margin-bottom:11px;width:28px; height:28px;" /></a>
      <a title="New LOQ" (click)="newLoq();"><img src="assets/GoldiMocks-new.png" align="middle" title="HOME" alt="HOME" style="cursor:pointer;margin-bottom:11px;width:28px; height:28px;" /></a>
      &nbsp;
      <!-- glean -->
      <a (click)="navisloq()"><img src="assets/{{isloqlogo}}.png" align="middle" title="LOQ" alt="LOQ"  style="cursor:pointer;margin-bottom:12px;width:30px; height:30px;" /></a>
      <!-- transform -->
      <a (click)="navsisloq()"><img src="assets/{{sisloqlogo}}.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
      <!-- retrieve -->
      <a (click)="navsiloq()"><img src="assets/{{siloqlogo}}.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:12px;width:28px; height:28px;" /></a>
      &nbsp;
      <span  style="position:relative;width:28px; height:28px;cursor:pointer;">
          <img src="../../assets/gmz-gold-userX.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="goldimembers();">
          <a style="position:absolute;left:33%; top:12%;color:orangered;font-size:10px;font-weight:500;" (click)="goldimembers();"></a>
      </span>
        <span style="position:relative;width:28px; height:28px;cursor:pointer;">
          <img *ngIf="theGoldiUser && theGoldiUser !== 'null'" src="../../assets/gmz5.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="account();">
          <a *ngIf="theGoldiUser && theGoldiUser !== 'null'" style="position:absolute;left:39%; top:12%;color:orange;font-size:10px;font-weight:500;" (click)="account();">{{theUserInitials}}</a>
          <img  *ngIf="(!theGoldiUser || theGoldiUser === 'null')" src="../../assets/gmz5-not.png" style="width:28px; height:28px;vertical-align:middle;margin-bottom:3px;" (click)="account();">
          <a  *ngIf="(!theGoldiUser || theGoldiUser === 'null')" style="position:absolute;left:39%; top:12%;color:dodgerblue;font-size:10px;font-weight:500;" (click)="account();">{{theUserInitials}}</a>
      </span>
    </div>
  </div>
  <div style="height:3px;padding:0px;margin:0px auto;border:0px;background-color:gold">
  </div>
</div>

