<div class="modal {{ size }}">
<div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:0px 0px 18px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:.85em;font-weight:200;">
  <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-we-belong-here'}" style="text-decoration:none;color:teal;">
    <button  type="button" class="siloqbutton2" style="background-color:orangered;color:whitesmoke;border:4px solid gold;padding:10px 12px 10px 12px;font-size:1.4em;font-weight:600;">
      {{taxonomy}}™
    </button>
  </a>
</div>
<br>

  <div class="modal-header">
    {{ title }}
  </div>
  <div class="modal-content" [innerHTML]="message">
  </div>
  <div class="modal-footer">
    <span class="siloqbutton" (click)="submit()">Yes</span>
    <span class="modal-close siloqbutton" style="background-color: orangered;" (click)="close()">No</span>
  </div>
</div>

<div class="modal-backdrop" (click)="close()"></div>
