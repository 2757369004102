<admin-header></admin-header>
<div style="text-align:center; height:100%; width:100%;padding-top:120px;">
  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" >Direct Feedback</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
  </div>
  <br>
  <admin-alert></admin-alert>
	<form>
		<div class="small-12 columns">
      <div maxlength="25"  class="siloqlogofont mydisable" [textContent]='sub'  id="subject" > </div>
		</div>
		<br>
		<div class="small-12 columns">
			<div contenteditable="true" class="siloqinput" data-text="Short Description *"  [textContent]='model.value' style="height:140px;font-family:arial;min-width:300px;min-height:100px;" id="value"> </div>
		</div>
		<br>
		<br>
    <div class="small-12 columns">
          <div style="width:25%;margin:auto;min-width:300px;" class="siloqfont">
            <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='submitTag()' class="siloqbutton">Submit</a>
            <a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
            <br>
          </div>
     </div>
		<br>
		<br>
		<br>
	</form>
</div>
<admin-footer></admin-footer>
