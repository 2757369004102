import { Component } from '@angular/core';
import {TagService} from '../services/tag-service.service';
import {Tag} from '../admin/tag/model/tag';
import {Observable, throwError} from 'rxjs';
import {UserService} from '../services/user.service';
import {Login} from '../services/user.service';
import {TopicService} from '../services/topic.service';
import {Topic} from '../admin/topic/model/topic';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert/services/alert.service';
import { AlertTypeEnum } from '../alert/types/alertType.enum';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { FormsModule } from '@angular/forms';

@Component({
host: {
      '(document:keydown)': 'keyDown($event)',
      },

  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  imports: [AlertComponent, FormsModule, RouterModule, FooterComponent, HeaderComponent],
  standalone: true
})

export class SignUpComponent {
  email: "";
  verifyEmail = false;
  public topic: Topic = new Topic("", "", "");
  public model = new Tag('', '', '', '', '');
  cookies = true;
  maxretry = 6;
  timeout = 1000;
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public backgroundColor  = "gold";
  public backgroundColor2  = "orangered";

  constructor(private alertService: AlertService, private topicService: TopicService, private activatedRoute: ActivatedRoute, private tagService: TagService, public userSVC: UserService, public router: Router, private loginInfo: Login, private _cookieService:CookieService){loginInfo = new Login();}

   setCookie(key: string, value: string){
      this._cookieService.set(key, value);
      key = this._cookieService.get(key);
      if(!key) {
        this.cookies = false;
      }  else {
        this.cookies = true;
      }
    }

  ngOnInit() {
    this.setCookie("ctest", "ctest");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
     if(!this.cookies){
        this.showAlert(AlertTypeEnum['danger'], "Please, enable cookies!");
     }
    }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  validateEmail(email: any) {
   const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(email);
  }

  forgot() {
    this.userSVC.forgot(this.loginInfo).subscribe(
      (data: any) => {
       this.showAlert(AlertTypeEnum['warning'], "Email has been sent with temporary password. Please check your email inbox and/or spam folders within 24 hours!");
       window.setTimeout(() => {
           this.router.navigate(['/login']);
       }, 3000);
      },
       (err: any) => {
        console.log(err);
      });
  }

  signUp() {
    let login = new Login();
    this.userSVC.signUp(this.loginInfo)
    .subscribe((json: Object) => {
      login.fromJSON(json);
      this.userSVC.authUser.email = login.email;
      this.userSVC.authUser.sessionId = login.sessionId;
      var s = login.userId.replace(/0/g,"");
      if(s != "") {
        this.userSVC.authUser.userId = login.userId;
        this.userSVC.loggedInUser.userId = login.userId;
      }
      //
      this.userSVC.loggedInUser.email = login.email;
      this.userSVC.loggedInUser.sessionId = login.sessionId;
      s = login.goldimembersSessionId.replace(/0/g,"");
      if(s != "") {
        this.userSVC.authUser.goldimembersSessionId = login.goldimembersSessionId;
        this.userSVC.loggedInUser.goldimembersSessionId = login.goldimembersSessionId;
      }
      if (this.userSVC.authUser.email === this.email) {
           this.submitTopic();
           this.submitTag();
           this.forgot();
       } else {
              this.showAlert(AlertTypeEnum['danger'], "User is already existing.");
       }
      },
      (err: any) => {
         alert("Unable to SignUp. Please, try again!");
         console.log(err);
      });
  }

  doSignUp() {
       if(!this.cookies){
          this.showAlert(AlertTypeEnum['danger'], "Please, enable cookies!");
       }

       this.loginInfo.email = btoa(this.email);
       if (!this.validateEmail(this.email)) {
         this.showAlert(AlertTypeEnum['danger'], "Please, enter valid email and try again!");
         this.verifyEmail = false;
         return;
       }

       this.signUp();
   }

  public keyDown(event: any) {
      switch (event.keyCode) {
        case 13:
        this.signUp()
          event.preventDefault();
          break;
      }
      return;
    }

     submitTopic(){
      let commentOperation:Observable<Topic[]>;
      this.topic.hashTag = "message"
      this.topic.value = "Hello " + this.loginInfo.email + ", welcome to {{taxonomy}}!! Please, click 'Support' menu on the header to get started for free."
      this.topic.email = this.loginInfo.email;
      this.topicService.addTopic(this.topic).subscribe(
            () => {
              this.topic = new Topic('', '', '');
            },
             (err: any) => {
              console.log(err);
            });
    }

    submitTag(){
      let commentOperation:Observable<Tag[]>;
      this.model.hashTag = "message"
      this.topic.name = "Welcome"
      this.model.value = "Hello " + this.loginInfo.email + ", welcome to {{taxonomy}}!! Please, click 'Support' menu on the header to get started for free."
      this.model.email = this.loginInfo.email;
      this.model.source = this.loginInfo.email;
      this.tagService.addTag(this.model).subscribe(
            () => {
                      this.model = new Tag(this.model.hashTag, '', '', '', '');
            },
             (err: any) => {
              console.log(err);
            });
      }

    done() {
        this.router.navigate(['/login']);
      }
  }

