<header></header>
<div style="text-align:center; height:100%; width:100%; padding-top:70px;">
  <br>

  <div class="small-12 columns">
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Join</h3>
    <br>
    <a href="/" target="_self">
      <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
        GoldiMocks™
      </button>
    </a>
    <br>
    <br>
    <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Today</h3>
    <br>
  </div>

  <alert></alert>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
          <input type="text" class="slqinput" placeholder="Email"  id="email" [(ngModel)]="email" name="email" required #em="ngModel"/>
        </label>
 	  <div [hidden]="em.valid || em.pristine" style="margin:auto;text-align:left;width:300px;color:orangered;padding-top:5px;" class="siloqfont">
           Email is required
          </div>
    </div>
    <br>
    <div class="small-12  columns" style="width:300px;text-align:left;
    margin:auto;">
	<div style="text-align:center;margin:auto;width:300px;" class="siloqfont">
		<button [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'" [disabled]="verifyEmail === true" (click)="verifyEmail = true; doSignUp();" type="button" class="slqok">Verify Email</button>
    &nbsp;<a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  (click)='done()' class="slqcancel">Cancel</a>
 	  <div [hidden]="verifyEmail !== true" style="margin:auto;text-align:left;width:300px;color:mediumseagreen;padding-top:5px;" class="siloqfont">
           Verification email has been sent!
    </div>
 		<br>
 		<br>
    Please, check for an email verification link from <i style="color:mediumseagreen;">contact&#64;siloq.com</i> with login details. FYI: This email is not monitored.  <br>
                <br>
                <hr style="width:300px;">
                <br>
                <br>
		<div style="width:300px;text-align:left;margin: auto;"> By signing up, you agree to the <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-terms-and-conditions'}" style="text-decoration:none;color:dodgerblue;">Terms of Service</a>  and <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-privacy'}" style="text-decoration:none;color:dodgerblue;">Privacy Policy</a>, including <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-cookie-use'}" style="text-decoration:none;color:dodgerblue;">Cookie Use</a>.
    </div>
		<br>
		<br>
		<br>
	</div>
    </div>
</div>
</form>
</div>
<footer></footer>

