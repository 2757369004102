<header></header>
<div style="text-align:center; height:100%; width:100%;padding-top:70px;">
<div class="small-12 columns">
  <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Direct Message</h3>
  <br>
  <a href="/" target="_self">
    <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
      GoldiMocks™
    </button>
  </a>
</div>
  <br>
  <alert></alert>
	<form>
		<div class="small-12 columns">
      <input type="text" class="siloqinput" placeholder="Email"  id="email" [(ngModel)]="email" name="email" required #em="ngModel" style="min-width:300px;"/>
		</div>
		<br>
    <div class="small-12 columns">
      <button class="siloqbutton" style="padding:4px;height:42px;min-width:196px;font-family:Helvetica,AppleGothic,sans-serif; background-color:mediumseagreen;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;" [style.backgroundColor]="backgroundColor3"  (mouseover)="backgroundColor3 = '#789885'" (mouseout)="backgroundColor3 = 'mediumseagreen'" [disabled]="emailVerified === true" (click)="doRegister();" type="button">Email OTP Code</button>
    </div>
    <br>
    <br>
    <div class="small-12 columns">
      <input [type]="passwordType" autocomplete="current-password" class="siloqinput" placeholder="Password" id="passwd"  [(ngModel)]="passwd" name="passwd" required #pwd1="ngModel" style="min-width:300px;margin-left:20px;">&nbsp;<a (click)="togglePasswordVisibility()" class="fa fa-eye"></a>
    </div>
    <br>
		<div class="small-12 columns">
			<div contenteditable="true" style="height:140px;min-height:100px;min-width:300px;" class="siloqinput" data-text="Short Description *" id="value"> </div>
    </div>
		<br>
    <div class="small-12 columns">
      <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = 'orange'" (mouseout)="backgroundColor = 'gold'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:gold;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='submitOtp()' class="siloqbutton">Submit</a>
      <a  [style.backgroundColor]="backgroundColor2"  (mouseover)="backgroundColor2 = 'orange'" (mouseout)="backgroundColor2 = 'orangered'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:orangered;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;color:whitesmoke;"(click)='done()' class="siloqbutton">Cancel</a>
     </div>
     <br>
    <br>
    <br>
    <br>
    <br>
    <br>
	</form>
</div>
<footer></footer>
