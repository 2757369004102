import { Injectable } from '@angular/core';

export class Serializable {
    fromJSON(json: any){
        for (var propName in json)
            this[propName] = json[propName];
        return this;
    }
}

export class Taxon extends Serializable {
  key="";
  value: any;
  constructor(){
		 super();
  }
}

export class Tag extends Serializable {
  public id="";
  public key="";
  public name="";
  public users: string[];
  public tags: string[];
  public taxonomy: Taxon[];
  public rank: number = 0;
  public slqId: number = 0;
  public position: number = 0;
  public insertDate="";
  public loc: Loc;
  public isDisabled: boolean = false;
  public ping: boolean = false;
  public pong: boolean = false;
  public base="";
  //
  public email="";
  public show: boolean = false;
  public edit: boolean = false;
  public checked: boolean = false;

  constructor(
    public hashTag: string,
    public userId: string,
    public source: string,
    public value: string,
    public topicId: string) {
		 super();
  }
}

export class Loc{
  public x: number;
  public y: number;
}
