import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Taxon} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { Subscription } from 'rxjs';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { LoqFooterComponent } from '../loqFooter/loq-footer.component';
import { FormsModule } from '@angular/forms';
import {User} from '../user/model/user';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../tag/tag.safehtml.pipe';
import { TruncatePipe } from '../tag/tag.truncate.pipe';

declare function escape(s:string): string;
declare function unescape(s:string): string;
declare function testJs(): any;

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, LoqFooterComponent, AdminHeaderComponent, CommonModule, SafeHtmlPipe, TruncatePipe],
  standalone: true
})
export class ExamComponent implements OnInit {

  tags: Tag[] = [];
  public topic  = new Topic('', '', '');
  //public examTopic  = new Topic('', '', '');
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public ttopic = new Topic("","","");
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public selectedSlide;
  public selectedTag;
  public classTag = "Not Selected Yet";
  selectedTagIndex = -1;
  selectedSlideIndex = -1;
  browseTagIndex = -1;
  browseSlideIndex = -1;
  selectAll = false;
  editable = false;
  public theUser = "";
  public theGoldiUser = "";
  currentPage: number = 1;
  pageSize = 1;
  totalCount: number = 0;
  pageCount: number = 1;
  host = window.location.protocol+"//"+window.location.hostname;
  public slides = [];
  public subtitles: string[] = [];
  public selectedSubtitles: string[] = [];
  public printable: boolean = false;
  loq = "";
  subscription: Subscription;
  random: number = 5;
  public scheduled: boolean = false;
  public clicked: boolean = false;
  title = "EXAM";
  user = "exam.imoqr@siloq.com";
  timer = "";
  aidstyle = "aidt";
  tooltip = "Hold on!";
  public taxonomy = "";
  public pageTitle = "";
  public class = "";
  public subclass = "";
  public responsePageSize = 0;
  public goldimemberPrice = 0;
  public startTime = "";
  public timeDuration = 0;
  public rightQuestionReward = 4;
  public wrongQuestionPenalty = 1;
  public audioEnabled  = false;
  public playing: boolean = false;
  public intervalId: any;
  public timeoutId: any;
  public timeoutIdQ: any;
  public goldimembersEnabled: boolean = false;
  public rentPrice = 0.0;
  public buyPrice = 0.0;
  public currency = "USD";
  public cbid = "";
  public ready = false;

  constructor(private titleService: Title, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService, private alertService: AdminAlertService, private modalService: ModalService){}

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback();});
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
      this.model.topicId = unescape(params['i']) || "";
      this.topic.id = this.model.topicId;
      var u = params['d'] || "";
      var e = params['e'] || "";
      this.title = params['t'] || "";
      this.user = unescape(params['u']) || "";
      this.cbid = unescape(params['cbid']) || "";
      this.currentPage = parseInt(params['p']) || 1;
      this.class = config.class;
      this.subclass = config.subclass;
      //TODO: from config
      this.startTime = new Date().toLocaleString();
      this.buyPrice = +config.buyPrice;
      this.rentPrice = +config.rentPrice;
      this.timeDuration = +config.timeDuration;
      this.responsePageSize = +config.responsePageSize;
      this.random = +config.randomQuestions;
      this.rightQuestionReward = +config.rightQuestionReward;
      this.wrongQuestionPenalty = +config.wrongQuestionPenalty;
      this.audioEnabled = (config.audioEnabled === "true");
      this.currency = config.currency;

      if (u !== "" && this.theUser !== window.atob(u)) {
        this.showAlert(AlertTypeEnum['danger'], "It looks like that you do not have access to this page!");
        this.router.navigate(['']);
      }

      this.subscription = this.data.currentClassTag.subscribe(classTag => this.classTag = classTag)
      if (e) {
        this.editable = true;
        this.classTag = e;
      }
    });

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.selectedSlideIndex = Number(fragment);
      if (this.selectedSlideIndex === 0) {
        this.selectedSlideIndex = -1;
      }
    })

    if(this.selectedTagIndex > 0) {
      window.location.replace(window.location.href);
    }

    if(this.model.hashTag) {
      this.slides = [];
      this.subtitles = [];
      this.tags = [];
      this.getTopicById(this.topic.id, (t: any)=>{
        if (t.id) {
          this.topic = t;
          this.topic.value = unescape(t.value);
          this.topic.hashTag = unescape(t.hashTag);
          this.model.hashTag = unescape(t.hashTag);
          this.data.changeMessage(unescape(t.hashTag));
          this.titleService.setTitle(unescape(t.hashTag));
          //TODO: timer if needed
//           if(!this.topic.value) {
//              this.topic.value = "3";
//           }

         if(this.topic.users) {
             if(this.topic.users.indexOf('conduct.imoqr@siloq.com') >= 0){
                this.editable = true;
                this.showAlert(AlertTypeEnum['success'], "Edit the <b>#Random Questions</b> and conduct the exam!");
              }

//               if(this.topic.users.indexOf('request.imoqr@siloq.com') >= 0){
//                 this.showAlert(AlertTypeEnum['success'], "Exam is ready whenever you're ready!");
//               }

              if(this.topic.users.indexOf('hold.imoqr@siloq.com') >= 0){
                this.showAlert(AlertTypeEnum['info'], "Please hold on for approval of the this exam request within 72 hours.");
              }

              if(!(this.topic.users.indexOf('conduct.imoqr@siloq.com') >= 0 /* || this.topic.users.indexOf('request.imoqr@siloq.com') >= 0*/)) {
                this.getTopicTags(() => {});
              }
          }

        //
        if(this.topic && this.topic.taxonomy) {
          for (let i = 0; i < this.topic.taxonomy.length; i++) {
            switch (this.topic.taxonomy[i].key) {
              case "random":
                this.random = +this.topic.taxonomy[i].value[0];
                break;
              case "timeDuration":
                this.timeDuration = +this.topic.taxonomy[i].value[0];
                break;
              case "startTime":
                this.startTime = this.topic.taxonomy[i].value[0];
                break;
              case "pageSize":
                this.responsePageSize = +this.topic.taxonomy[i].value[0];
                break;
              case "rightQuestionReward":
                this.rightQuestionReward = +this.topic.taxonomy[i].value[0];
                break;
              case "wrongQuestionPenalty":
                this.wrongQuestionPenalty = +this.topic.taxonomy[i].value[0];
                break;
              case "audioEnabled":
                this.audioEnabled = (this.topic.taxonomy[i].value[0] === "true");
                break;
              case "goldimembersEnabled":
                this.goldimembersEnabled = (this.topic.taxonomy[i].value[0] === "true");
                break;
              case "rentPrice":
                this.rentPrice = +this.topic.taxonomy[i].value[0];
                break;
              case "buyPrice":
                this.buyPrice = +this.topic.taxonomy[i].value[0];
                break;
            }
          }
        }
        //
//           if(this.topic.email) {
//               this.getTopicByUsers(escape(unescape(this.topic.hashTag)), this.topic.email, ["exam.imoqr@siloq.com"], (t: any) => {
//                   if (t) {
//                     this.examTopic = t;
//                   }
//               });
//           }
          //
          this.ready = true;
          if(this.audioEnabled) {
            this.responsePageSize = 1;
          }
      }
      });
    }

    this.examScheduled();
  }

examScheduled() {
  setTimeout(()=>{
    if(this.topic.users) {
     this.scheduled = this.topic.users.indexOf('exam.imoqr@siloq.com') && !this.topic.hashTag;
     this.tags = [];
     }
  }, 1000);
}

  getTopicTags(callback: () => any) {
    this.topicService.getTopicTags(this.topic)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags) {
            if(callback) {
              callback();
            }

            this.slides = [];
            this.subtitles = [];
            for (let i = 0; i < this.tags.length; i++) {
                if(!this.tags[i].name) {
                 this.tags.splice(i, 1);
                 continue;
                }

                this.tags[i].hashTag = unescape(this.tags[i].hashTag);
                if(!this.tags[i].name) {
                  this.tags[i].name = this.tags[i].hashTag;
                }

                if(this.tags[i].value.length > 0 && this.tags[i].name.length > 0) {
                  this.tags[i].name = unescape(this.tags[i].name);
                  this.tags[i].value = unescape(this.tags[i].value);
                  this.mapTags(this.tags[i]);
                }
            }

            for (let i = 0; this.subtitles && i < this.subtitles.length; i++) {
              var clone = Object.assign({}, this.slides[this.subtitles[i]][0]);
              clone.value = "";
              this.slides[this.subtitles[i]].unshift(clone);
            }

            this.totalCount = this.subtitles.length;
            this.pageSize = this.totalCount;
            if(+this.responsePageSize > 0 && this.topic.users.indexOf('response.imoqr@siloq.com') >= 0) {
              this.pageSize = +this.responsePageSize;
            }
            this.pageCount = Math.ceil(this.totalCount / this.pageSize);

            this.setPageVisibleEx();

           //TODO: Set Taxonomy from current Topic.

           if(this.topic.users) {
//               if(this.topic.users.indexOf('exam.imoqr@siloq.com') >= 0){
//                 this.showAlert(AlertTypeEnum['success'], "Your exam is ready!");
//               }

              if(this.topic.users.indexOf('answer.imoqr@siloq.com') >= 0 || this.topic.users.indexOf('response.imoqr@siloq.com') >= 0){
                this.editable = true;
                this.showAlert(AlertTypeEnum['success'], "Click and choose your answer!");
              }

//              if(this.topic.users.indexOf('result.imoqr@siloq.com') >= 0){
//                 this.showAlert(AlertTypeEnum['success'], "Congratulations!");
//               }

//              if(this.topic.users.indexOf('scheduled.imoqr@siloq.com') >= 0){
//                 this.showAlert(AlertTypeEnum['success'], "Exam scheduled successfully!");
//               }

              if(this.topic.users.indexOf('response.imoqr@siloq.com') >= 0){
                this.setTimer();
              }
            }
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

  getTopicById(id: string, callback: (t: any) => any) {
    this.topicService.getTopicById(id).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  getTopicByUsers(h: string, e: string, u: string[], callback: (t: any) => any) {
    this.topicService.getTopicByUsers(h, e, u).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  mapTags(tag: Tag){
      this.subtitles.indexOf(tag.name.toLowerCase()) === -1 && this.subtitles.push(tag.name.toLowerCase());
      this.addValueToName(tag.name.toLowerCase(), tag);
  }

  addValueToName(name: any, value: any) {
    // Shorcut || returns left side if it is "truthy," or the right otherwise.
    // This means that we only assign a new Array to the Object's property
    // if it has not previously been used.
    this.slides[name] = this.slides[name] || [];
    // Adds a value to the end of the Array
    this.slides[name].push(value);
  }

  setSearchText() {
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedSlide.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split   (' ').slice(0,6).join(' '));
  }

  public selectSlide( slide: any, i: number) {
    this.selectedSlideIndex = i;
    this.selectedSlide =  slide;
    this.setSearchText();
  }

 public selectTag( tag: any, j: number) {
    tag.rank = j + 1;
    this.selectedTagIndex = j;
    this.selectedTag =  tag;
    this.setSearchText();
  }

 @HostListener('keydown', ['$event'])
 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        if(event.shiftKey) {
          this.onTagUp();
        } else {
          this.onUp();
        }
        break;
      case 40:
        if(event.shiftKey) {
          this.onTagDown();
        } else {
          this.onDown();
        }
        break;
      case 13:
        this.goto();
        event.stopPropagation();
        if(event.shiftKey) {
        } else {
        }
        break;
      case 27:
        this.escapeIndex();
        break;
    }
    return;
  }

  escapeIndex() {
    this.selectedSlideIndex = -1;
    this.selectedTagIndex = -1;
    this.browseTagIndex = -1;
    this.browseSlideIndex = -1;
  }

  public onDown() {
    if(this.selectedSlideIndex < this.subtitles.length - 1)
    {
      this.selectedSlide = this.slides[++this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onUp() {
    if(this.selectedSlideIndex > 0)
    {
      this.selectedSlide = this.slides[--this.selectedSlideIndex];
      this.selectTag(this.slides[this.selectedSlide][0], 0);
    }
  }

  public onTagDown() {
    if(this.selectedTagIndex < this.tags.length - 1)
    {
      this.selectedTag = this.slides[++this.selectedTagIndex];
      this.setSearchText();
    }
  }

  public onTagUp() {
    if(this.selectedTagIndex > 0)
    {
      this.selectedTag = this.slides[--this.selectedTagIndex];
      this.setSearchText();
    }
  }

  setPageVisible() {
    var selectedSubtitleIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedSubtitles = [];
    for (let i = 0; i < this.subtitles.length; i++) {
      if(i >= selectedSubtitleIndex && i < end) {
        this.selectedSubtitles.push(this.subtitles[i]);
      }
    }
    this.selectedSlide = this.subtitles[selectedSubtitleIndex];
    this.speakSubtitle(this.slides[this.selectedSlide]);
    /*
    if (this.selectedTag) {
      this.data.changeMessage(this.selectedTag.hashTag.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split(' ').slice(0,6).join(' ') + " " + this.curbemail(this.selectedTag.email));
      this.selectedTag.rank = this.selectedTagIndex + 1;
    }
    */
  }

  setPageVisibleEx() {
    var selectedSubtitleIndex = (this.currentPage - 1) * this.pageSize;
    var end = this.currentPage * this.pageSize
    this.selectedSubtitles = [];
    for (let i = 0; i < this.subtitles.length; i++) {
      if(i >= selectedSubtitleIndex && i < end) {
        this.selectedSubtitles.push(this.subtitles[i]);
      }
    }
    this.selectedSlide = this.subtitles[selectedSubtitleIndex];
  }

  renderTag(tag: Tag){
    if (tag.value == "" && tag.position > 1) {
      tag.name = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.name + '</div>';
    }
    if (tag.value != "" && tag.position > 1) {
      tag.value = '<div style="margin-left:' + (tag.position - 1) * 40 + 'px;">' + tag.value + '</div>';
    }
  }

  updateTag(tag: Tag) {
    var t = Object.assign({}, tag);

    t.hashTag = escape(t.hashTag);
    t.name = escape(t.name);
    t.value = escape(t.value);
    let commentOperation:Observable<Tag[]>;
    this.tagService.updateTag(t).subscribe(
      () => {
      },
       (err: any) => {
        console.log(err);
      });
  }

  updateTagName(tag: Tag){
    for (let i = 0; i < this.slides[this.selectedSlide].length; i++) {
      this.slides[this.selectedSlide][i].name = tag.name;
    }
    this.updateTag(tag);
  }

  addClass(tag: any){
    let commentOperation:Observable<Tag[]>;
    if(this.editable) {
      document.getElementById("loq").focus();
      this.classTag = unescape(tag.name);
    }
  }

////////////// below belongs to doc //////////

  editTag(tag: any) {
    this.umodel = new Tag(tag.hashTag, tag.userId, tag.source, tag.value, tag.topicId);
    tag.edit = !tag.edit;
  }

  shareLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can share!");
      return;
    }

    this.router.navigate(['/admin/share'],  { queryParams: { h : this.topic.hashTag}});
  }

  conductExam() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can conduct exam!");
      return;
    }

    if(this.topic.users.indexOf('conduct.imoqr@siloq.com') < 0) {
      this.showAlert(AlertTypeEnum['danger'], "This Exam Can't be conducted!");
      return;
    }

    //topic clone to exam > submit exam > request clone to response > submit response > response clone to result > submit result
    if(this.theUser === this.topic.email) {
      this.examRequest();
    }

      return;
  }

  mockExam() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can mock the exam!");
      return;
    }

    if(this.topic.users.indexOf('mock@siloq.com') >= 0) {
      this.showAlert(AlertTypeEnum['danger'], "Mock Exams can not be published again! Please clone to you and proceed.");
      return;
    }

    //topic clone to exam > submit exam > request clone to response > submit response > response clone to result > submit result
    if(this.theUser === this.topic.email) {
      this.examMock();
      return;
    }
  }

  branchLoq() {
    if(this.topic.hashTag === undefined) {
      this.showAlert(AlertTypeEnum['warning'], "Only owners of the topic can clone!");
      return;
    }
    this.router.navigate(['/admin/branch'],  { queryParams: { h : this.topic.hashTag}});
  }

  examRequest() {
        this.openModal(this.modalTemplate, "md", "Exam: Schedule", "Do you want to schedule <b>" + this.topic.hashTag.replace("_", " ") + "</b> with <b>" + this.random + "</b> random questions each?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please hold on while we're scheduling the exam to each members with <b>" + this.random + "</b> random questions.");
            this.ready = false;
            document.getElementById("loading").style.display = "inline";
            this.topic.hashTag = escape(unescape(this.topic.hashTag));
            this.topic.value = this.random.toString();
            this.atomicExamRequest(this.topic, (b: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/exams'], { queryParams: { t : "Request", u: "request.imoqr@siloq.com"}});
              //this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, i : t.id, t : "Response", u : t.users[1]}});
              //TODO: Email notification
              //Timed anytime offline exams
              //document.getElementById("loading").style.display = "none";
              //this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag}});
            });

/*
            setTimeout(()=>{
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/exams'], { queryParams: {t : "Exams: Request", u: "request.imoqr@siloq.com"}});
            }, 9000);
            */
      });
    }

    examMock() {
        this.openModal(this.modalTemplate, "md", "Mock: Publish", "Do you want to publish <b>" + this.topic.hashTag + "</b> with <b>" + this.random + "</b> random questions?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please hold on while we're publishing the mock to each members with <b>" + this.random + "</b> random questions.");
            this.ready = false;
            document.getElementById("loading").style.display = "inline";
            this.topic.hashTag = escape(unescape(this.topic.hashTag));
            //TODO: Use Taxonomy
            this.topic.taxonomy = [];
            var taxon: Taxon;
            //
            taxon = new Taxon();
            taxon.key = "random";
            taxon.value = [this.random.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "timeDuration";
            taxon.value = [this.timeDuration.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "startTime";
            taxon.value = [this.startTime];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "pageSize";
            taxon.value = [this.pageSize.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "rightQuestionReward";
            taxon.value = [this.rightQuestionReward.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "wrongQuestionPenalty";
            taxon.value = [this.wrongQuestionPenalty.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "audioEnabled";
            taxon.value = [this.audioEnabled.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "goldimembersEnabled";
            taxon.value = [this.goldimembersEnabled.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "rentPrice";
            taxon.value = [this.rentPrice.toString()];
            this.topic.taxonomy.push(taxon)
            //
            taxon = new Taxon();
            taxon.key = "buyPrice";
            taxon.value = [this.buyPrice.toString()];
            this.topic.taxonomy.push(taxon)
            //
            this.atomicExamMock(this.topic, (b: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/mocks']);
            });
      });
    }


  convertToId(source: string): string {
    return source.replace(/ /g, "-");
  }

  routeToPageSection(i: number): string {
    return '/admin/exam?h=' + this.topic.hashTag + '#' + i;
  }

  cutUntil(tag: Tag): string {
    var index = tag.source.indexOf("#");
    if(index > 1) {
      var url = tag.source.substring(0, index);
      return url+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    } else {
      return tag.source+"#:~:text="+encodeURIComponent(tag.value).replace(/-/g, "%2D");
    }
  }

/*
  cloneTopic(t: Topic, callback: () => any) {
    this.topicService.cloneTopic(t).subscribe(
      () => {
        callback();
      },
       (err: any) => {
        console.log(err);
      });
  }
  */

 atomicPublishTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.publishTopic(h, e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

    atomicExamRequest(t: Topic, callback: (t: any) => any) {
        this.topicService.examRequest(t).subscribe(
          (data: any) => {
            callback(data);
          },
           (err: any) => {
            console.log(err);
          });
      }

    atomicExamMock(t: Topic, callback: (t: any) => any) {
      this.topicService.examMock(t).subscribe(
        (data: any) => {
          callback(data);
        },
         (err: any) => {
          console.log(err);
        });
    }

     examResponse(t: Topic, callback: (t: any) => any) {
              this.topicService.examResponse(t).subscribe(
                (data: any) => {
                  callback(data);
                },
                 (err: any) => {
                  console.log(err);
                });
            }

     examFinalize(t: Topic, callback: (t: any) => any) {
                  this.topicService.examFinalize(t).subscribe(
                    (data: any) => {
                      callback(data);
                    },
                     (err: any) => {
                      console.log(err);
                    });
                }

      examResult(t: Topic, callback: (t: any) => any) {
              this.topicService.examResult(t).subscribe(
                (data: any) => {
                  callback(data);
                },
                 (err: any) => {
                  console.log(err);
                });
            }

/*
      examScore(t: Topic, callback: (t: any) => any) {
              this.topicService.examScore(t).subscribe(
                (data: any) => {
                  callback(data);
                },
                 (err: any) => {
                  console.log(err);
                });
            }
*/

  updateTopic(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopic(topic).subscribe(
             (data: any) => {
                  callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  updateTopicValue(topic: Topic,  callback: (t: any) => any){
    let commentOperation:Observable<Topic[]>;
    this.topicService.updateTopicValue(topic).subscribe(
             (data: any) => {
                  //callback(data);
              },
       (err: any) => {
        console.log(err);
      });
  }

  onPrint() {
    this.escapeIndex();
    this.printable = false;
    setTimeout(()=>{
      window.print();
      this.printable = true;
        }, 1000);
  }

  curbemail(email: string): string {
       if(!email) {
         return email;
       }

       var index = email.indexOf("@");
       if(index > 0) {
         return email.substring(0, index);
       }
    return email;
     }

   profileEx(h: any, d: any) {
    this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
    return;
  }

  getContent(innerHTML){
    this.model.value = innerHTML.trim();
    this.data.changeMessage(this.model.hashTag.split(' ').slice(0,6).join(' ') + " " + this.classTag.replace('&gt;&gt;&gt;','').split(' ').slice(0,6).join(' ') + " " + this.model.value.split(' ').slice(0,6).join(' '));
  }

  scrollToBottom() {
    const element = document.getElementById('loq');
    //element.scrollTop = element.scrollHeight;
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  scrollToTop() {
    const element = document.getElementById('top');
    element.scrollTop = 0;
  }

renderImage(t: any) {
  if(!t){
    return t;
  }

  var s = unescape(t);
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("Ans - ")) {
      var v = t;
     t = v.replace("Ans - ", "");
   }

   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'

       if (s.startsWith("Ans - ")) {
        t = "Ans - " + t;
       }
       return t;
    }
       return s;
  }

  cloneTopic(t: Topic, callback: (t: any) => any){
         this.topicService.cloneTopic(t).subscribe(
          (data: any) => {
                 callback(data);
               },
            (err: any) => {
             console.log(err);
           });
       }

       cloneTopicToUser(t: Topic, callback: (t: any) => any){
       this.topicService.cloneTopicToUser(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

    addTags(tags: Tag[]) {
        this.tagService.addTags(tags).subscribe(
         (t: any) => {
             this.model.value = "";
             document.getElementById("loading").style.display = "none";
             //alert(JSON.stringify(t));
             if(t.users.length > 1) {
               this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, i : t.id, t : "Conduct",  u : t.users[1]}});
             }
          },
           (err: any) => {
            document.getElementById("loading").style.display = "none";
            console.log(err);
          });
      }

  branchTopic(suffix: string) {
       if (this.topic.hashTag === undefined) {
         return;
       }

/*
      if(suffix === "exam") {
          this.openModal(this.modalTemplate, "md", "Exam: Prepare Answer(s)", "Do you want to prepare the answer(s) for <b>" + this.topic.hashTag.replace("_", " ") + "</b>?", () => {
           this.showAlert(AlertTypeEnum['info'], "Please hold on and get ready to prepare answers for the exam.");
            var tans = Object.assign({}, this.topic);
            tans.hashTag = escape(unescape(tans.hashTag));
            tans.users = [];
            tans.users.push(this.topic.email);
            tans.users.push('answer.imoqr@siloq.com');
            this.cloneTopicToUser(tans, (t: any) => {
              document.getElementById("loading").style.display = "none";
              this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, i : t.id, t : "Answer", u : t.users[1]}});
            });
          });
       }
*/
       var pinged = false;
       var msg = "";
       if(suffix === "finalized") {
          for (let i = 0; i < this.subtitles.length; i++) {
            pinged = false;
            for (let j = 1; j < this.slides[this.subtitles[i]].length; j++) {
                 var t = this.slides[this.subtitles[i]][j];
                 if (t.ping) {
                   pinged = t.ping;
                   break;
                 }
             }

             if(!pinged) {
              break;
             }
         }

         if(!pinged) {
           msg = " [Missing answer(s)!]";
           this.showAlert(AlertTypeEnum['info'], "Missing answer(s)!");
         }

         this.openModal(this.modalTemplate, "md", "Exam: Finalize & Conduct"+msg, "Do you want to finalize the answer(s) and conduct exam for '<b>" + this.topic.hashTag.replace("_", " ") + "</b>'?", () => {
             this.showAlert(AlertTypeEnum['info'], msg+" Get ready to publish this Mock");
             this.ready = false;
             document.getElementById("loading").style.display = "inline";
             var t = Object.assign({}, this.topic);
             t.id = "";
             t.hashTag = escape(unescape(t.hashTag));

            this.getTopicByUsers(escape(unescape(this.topic.hashTag)), this.topic.email, ["conduct.imoqr@siloq.com"], (t1: any) => {
              if(t1) {
                  this.topicService.archiveTopic(t1).subscribe(
                    () => {
                       t.email = this.theUser;
                       t.users = [];
                       t.users.push(this.topic.email);
                       t.users.push('conduct.imoqr@siloq.com');
                       this.topicService.addTopic(t)
                         .subscribe( (tt: any) => {
                           var ctags = Object.assign([], this.tags);
                           for (let i = 0; i < ctags.length; i++) {
                              ctags[i].ping = false;
                              ctags[i].pong = false;
                              ctags[i].base = ctags[i].id;
                              ctags[i].hashTag = tt.hashTag;
                              ctags[i].topicId = tt.id;
                              ctags[i].email = tt.email;
                              ctags[i].users = tt.users;
                           }
                           this.topic.hashTag = escape(unescape(this.topic.hashTag));
                           this.topic.topics = [];
                           this.topic.topics.push(tt.id);
                           this.updateTopic(this.topic, (temp: any) => {
                             this.addTags(ctags);
                           });
                       });
                    },
                     (err: any) => {
                      console.log(err);
                  });
              }
            });
         });
       }

//        if(suffix === "request") {
//          this.openModal(this.modalTemplate, "md", "Exam: Request", "Are you prepared to attend the <b>" + this.topic.hashTag.replace("_request", "").replace("_", " ") + "</b>?", () => {
//                this.showAlert(AlertTypeEnum['info'], "Please hold on and get ready.");
//                document.getElementById("loading").style.display = "inline";
//                this.topic.hashTag = escape(unescape(this.topic.hashTag));
//                this.topic.email = this.theUser;
//                var u = this.topic.users[0];
//                this.topic.users = [];
//                this.topic.users.push(u);
//                this.topic.users.push('response.imoqr@siloq.com');
//                this.topic.users.push(this.theUser);
//                this.examResponse(this.topic, (t: any) => {
//                  document.getElementById("loading").style.display = "none";
//                  this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, i : t.id, t : "Response", u : t.users[1]}});
//                });
//              });
//            }

      if(suffix === "response") {
           this.openModal(this.modalTemplate, "md", "Exam: Submit Response", "Do you want to submit the response for <b>" + this.topic.hashTag.replace("_", " ") + "</b>?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please hold on while we evaluate your answers");
            this.ready = false;
            document.getElementById("loading").style.display = "inline";
            this.topic.hashTag = escape(unescape(this.topic.hashTag));
             var u = this.topic.users[0];
             this.topic.users = [];
             this.topic.users.push(u);
             this.topic.users.push('mock.imoqr@result.com');
             this.topic.users.push(this.theUser);
               this.examResult(this.topic, (t: any) => {
                  document.getElementById("loading").style.display = "none";
                  if(t && t.users) {
                    this.router.navigate(['/admin/exam'], { queryParams: { h : t.hashTag, i : t.id, t : "Result", u : t.users[1]}});
                  }
               });
           });
          }

       if(suffix === "scores") {
           this.router.navigate(['/admin/scores'], { queryParams: {  h : this.topic.hashTag, i : this.cbid}});
        }

        if(suffix === "results") {
           this.router.navigate(['/admin/results'], { queryParams: {h : this.topic.hashTag, cbid : this.topic.id}});
        }

/*
       if(suffix === "finalized") {
          this.router.navigate(['/admin/mock'], { queryParams: {h : this.topic.hashTag, i : this.cbid}});
        }
*/
       if(suffix === "upload") {
          this.router.navigate(['/admin/loq'],  { queryParams: { h : "newLoq", d : window.btoa(this.userSVC.authUser.email), i : this.cbid}});
       }

        if(suffix === "download") {
           this.showAlert(AlertTypeEnum['info'], "File will be automatically downloaded!");
           var sqb = "";
            for (let i = 1; i < this.subtitles.length; i++) {
             for (let j = 0; j < this.slides[this.subtitles[i]].length; j++) {

              if(j === 0) {
                if(this.slides[this.subtitles[i]].length > 1) {
                  sqb += this.slides[this.subtitles[i]][j].name+"\n";
                }
              }

              if(j > 0) {
                sqb += this.slides[this.subtitles[i]][j].value+"\n";
              }
             }
            }

           if(sqb.length > 0) {
             this.download(this.topic.hashTag+".slq", sqb);
           }
        }

       if(suffix === "mock") {
          this.router.navigate(['/admin/mock'], { queryParams: { h : this.topic.hashTag}});
       }

       if(suffix === "scores") {
         this.router.navigate(['/admin/scores'], { queryParams: { h :  this.topic.hashTag, t : 'Scores', u : t.users[1], i : this.topic.id, cbid : this.topic.id}});
        }

       if(suffix === "reexam") {
           this.router.navigate(['/admin/reexam'], { queryParams: { h :  this.topic.hashTag, t : 'Reexam', u : t.users[1], i : this.topic.id, cbid : this.topic.id}});
        }

       if(suffix === "scheduled") {
           this.router.navigate(['/admin/exam'], { queryParams: { h : this.topic.id, t : 'Finalized', u: "finalized.imoqr@siloq.com"}});
        }

       if(suffix === "exams") {
           this.router.navigate(['/admin/exams'], { queryParams: {t : "Scheduled", u: "scheduled.imoqr@siloq.com"}});
       }
}

download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

addAnswer(event: any, tag: Tag) {
          if(!tag) {
            return;
          }
/*
         if(!this.editable) {
          return;
         }
*/
         if (!unescape(tag.value).trim()) {
          return;
         }

         var ix = this.subtitles.indexOf(unescape(tag.name).toLowerCase());
          if (ix < 0) {
            return;
          }

         var l = this.slides[this.subtitles[ix]].length;
         if (l < 2) {
           return;
         }

         for (let i = 1; i < this.slides[this.subtitles[ix]].length; i++) {
           var t = this.slides[this.subtitles[ix]][i];
           if(this.topic.users.indexOf('finalized.imoqr@siloq.com') >= 0 && tag.id !== t.id && t.ping !== false) {
                   t.ping = false;
                   t.hashTag = escape(unescape(t.hashTag));
                   t.name = escape(unescape(t.name));
                   t.value = escape(unescape(t.value));
                   this.tagService.updateTag(t).subscribe( (t1: Tag) => { });
             }
         }

         for (let i = 1; i < this.slides[this.subtitles[ix]].length; i++) {
           var t = this.slides[this.subtitles[ix]][i];
           if(this.topic.users.indexOf('response.imoqr@siloq.com') >= 0 && tag.id !== t.id && t.pong !== false) {
                   t.pong = false;
                   t.hashTag = escape(unescape(t.hashTag));
                   t.name = escape(unescape(t.name));
                   t.value = escape(unescape(t.value));
                   this.tagService.updateTag(t).subscribe( (t1: Tag) => { });
           }
         }

         tag.hashTag = escape(unescape(tag.hashTag));
         tag.name = escape(unescape(tag.name));
         tag.value = escape(unescape(tag.value));
         if(tag.ping !== true && this.topic.users.indexOf('finalized.imoqr@siloq.com') >= 0) {
           tag.ping = true;
           this.tagService.updateTag(tag).subscribe( (t1: Tag) => {
             this.showAlert(AlertTypeEnum['info'], "Your response is Accepted!");
           });
         }

         if(tag.pong !== true && this.topic.users.indexOf('response.imoqr@siloq.com') >= 0) {
           tag.pong = true;
           this.tagService.updateTag(tag).subscribe( (t1: Tag) => {
             this.showAlert(AlertTypeEnum['info'], "Your response is Accepted!");
           });
         }

     }

    ignore(event: any) {
       this.clicked = true;
       setTimeout(function(){
             this.clicked = false;
           }, 100);
      }

     parseFloat64(n: any): any {
      return parseFloat(n);
     }

    setTimer() {
     var countDownDate = this.addMinutes();

     // Update the count down every 1 second
     var x = setInterval(() => {

       // Get today's date and time
       var now = new Date().getTime();

       // Find the distance between now and the count down date
       var distance = countDownDate - now;

       // Time calculations for days, hours, minutes and seconds
       //var days = Math.floor(distance / (1000 * 60 * 60 * 24));
       var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
       var seconds = Math.floor((distance % (1000 * 60)) / 1000);

       // Output the result in an element with id="demo"
       //this.timer = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
       this.timer = hours + ":" + minutes + ":" + seconds;
       this.topic.value = (hours + minutes/60 + seconds/(60*60)).toString();

       // If the count down is over, write some text
       if (distance < 0) {
         clearInterval(x);
         this.timer = "EXPIRED";
       }
     }, 1000);
   }

     addMinutes() {
       return new Date().getTime() + (+this.timeDuration*60*1000);
     }

     addHours(h) {
       return new Date().getTime() + (+this.topic.value*60*60*1000);
     }
      mocks() {
               this.router.navigate(['/admin/mocks']);
      }

      exams() {
               this.router.navigate(['/admin/exams']);
      }

          selfNote(event: any, tag: Tag) {
                   if(!(this.topic.users.indexOf('answer.imoqr@siloq.com') < 0 || this.topic.users.indexOf('response.imoqr@siloq.com') < 0)){
                      return;
                   }

                   var ix = this.subtitles.indexOf(unescape(tag.name).toLowerCase());
                    if (ix < 0) {
                      return;
                    }

                   var l = this.slides[this.subtitles[ix]].length;
                   if (l < 2) {
                     return;
                   }

                   var iy = 1; //next tag
                   //this.data.changeMessage(t.hashTag.split(' ').slice(0,6).join(' ') + " " + t.name.split(' ').slice(0,6).join(' ') + " " + t.value.split(' ').slice(0,6).join(' '));
                   var tu = Object.assign({}, this.slides[this.subtitles[ix]][iy]);
                   if(!tu.tags) {
                      tu.tags = [];
                   }

                   var iz = tu.tags.indexOf("self-note");
                   if( iz >= 0) {
                     tu.tags.splice(iz, 1);
                   } else {
                     tu.tags.push("self-note");
                   }
                   tu.hashTag = escape(unescape(tu.hashTag));
                   tu.name = escape(unescape(tu.name));
                   tu.value = escape(unescape(tu.value));
                   this.tagService.updateTag(tu).subscribe( (t: any) => {
                       t.hashTag = unescape(t.hashTag);
                       t.name = unescape(t.name);
                       t.value = unescape(t.value);
                       this.slides[this.subtitles[ix]][iy] = t;
                     });
             }

             store(event: any, tag: Tag) {
              if (this.aidstyle === 'aid') {
                this.aidstyle = 'aidt';
              } else {
               this.aidstyle = 'aid';
              }
             }

             isNumber(value: number) {
               return value >= 0 && value <= 9;
             }

            first() {
              this.clearSpeech();
              if(this.currentPage>1){
                this.currentPage=1;
                this.setPageVisibleEx();
              }
            }

            previous() {
              this.clearSpeech();
              if(this.currentPage>1){
                this.currentPage-=1;
                this.setPageVisibleEx();
              }
            }

            next() {
              this.clearSpeech();
              if(this.currentPage<this.pageCount){
                this.currentPage+=1;
                this.setPageVisibleEx();
              }
            }

            last() {
              this.clearSpeech();
              if(this.currentPage<this.pageCount){
                this.currentPage=this.pageCount;
                this.setPageVisibleEx();
              }
            }

            goto() {
              if(this.topic.users.indexOf('response.imoqr@siloq.com') >= 0) {
                this.clearSpeech();
                if(this.currentPage>0 && this.currentPage<this.pageCount+1){
                  this.setPageVisibleEx();
                }
              }
            }

/*
            async speakTag(t: any) {
             this.timeoutIdQ = setTimeout(async () => {
               await this.speak(t.name);
             }, 2000);

             this.timeoutId = setTimeout(async () => {
                await this.speak(t.value);
             }, 5000);
            }
*/
            filter(t: any): any {
              t = t.replace(/<[^>]*>/g, '');
              let ix = t.indexOf("http");
              if(ix == 0) {
                  t = "Apologize, I cannot read these images or urls. I'm in the learning process!"
              } else if (ix > 0) {
                  t = t.substring(0, ix) + " Apologize, I cannot read these images or urls. I'm in the learning process!"
              }

              ix = t.indexOf("<img ");
              if (ix >= 0) {
                  t = "Apologize, I cannot read these images. I'm in the learning process!"
              } else if (ix > 0){
                t = t.substring(0, ix) + " Apologize, I cannot read these images. I'm in the learning process!"
              }

              return t;
            }

            speakSubtitle(st: any) {
              this.speak(this.filter(st[0].name), () => {});
              var i = 1;
              for (; i < st.length - 1; i++) {
                  this.speak(this.filter(st[i].value), () => {});
              }
              this.speak(this.filter(st[i].value), () => {
                if (this.currentPage < this.pageCount) {
                   this.currentPage += 1;
                   this.setPageVisible();
                 }
              });
            }

            waitforme(millisec) {
                return new Promise(resolve => {
                    setTimeout(() => { resolve('') }, millisec);
                })
            }

            speak(t: any, callback: () => any) {
                 var utterance = new SpeechSynthesisUtterance();
                 utterance.text = t;
                 var voices = window.speechSynthesis.getVoices();
                 utterance.voice = voices[1];

                 utterance.onend = () => {
                  callback();
                 };

                 speechSynthesis.speak(utterance);
            }

           speakRepeatedly() {
              this.setPageVisible();

              this.intervalId = setInterval(async () => {
                if (this.currentPage <= this.totalCount-1) {
                  this.currentPage++;
                  await this.setPageVisible();
                } else {
                  this.clearSpeech();
                }
              }, 12000);

            }

            clearSpeech() {
              speechSynthesis.cancel()
            }

            fastBackward() {
              this.clearSpeech();
              if(this.currentPage-10 < 1) {
                this.currentPage=1;
              } else {
                this.currentPage=this.currentPage-10;
              }
              this.setPageVisible();
            }

            backward() {
              this.clearSpeech();
              if(this.currentPage-1 < 1) {
                this.currentPage=1;
              } else {
                this.currentPage=this.currentPage-1;
              }
              this.setPageVisible();
            }

           stop() {
              this.clearSpeech();
              this.currentPage = 1;
            }

            play() {
              this.clearSpeech();
              this.setPageVisible();
              this.playing = !this.playing;
            }

            pause() {
              this.clearSpeech();
              this.playing = !this.playing;
            }

            forward() {
              this.clearSpeech();
              if(this.currentPage+1 > this.totalCount) {
                this.currentPage=this.totalCount;
              } else {
                this.currentPage=this.currentPage+1;
              }
              this.setPageVisible();
            }

            fastForward() {
              this.clearSpeech();
              if(this.currentPage+10 > this.totalCount) {
                this.currentPage=this.totalCount;
              } else {
                this.currentPage=this.currentPage+10;
              }
              this.setPageVisible();
            }
}
