<header></header>
<div class="content" style=" background-size:cover;
            width:100%;text-align:center;
            display:table;margin-top:10px;border-top:1px solid
            darkgrey;padding-top:80px;">
  <div class="content-inside" style="margin-left:0 auto; min-width:300px;width:25%;text-align: center; display:table-cell; padding:0px 0px 0px 0px;">

    <div class="small-12 columns">
      <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:300px;width:25%;">Logout</h3>
      <br>
      <a href="/" target="_self">
        <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
          GoldiMocks™
        </button>
      </a>
    </div>
    <br>
    <div class="container2">
      <div class="small-12 columns">
        <h4 class="block_container siloqlogofont" style="font-size:1em;text-align:center;margin: auto;color:orangered;mineght:600;width:300px;width:60%;padding-bottom:6px;">Successfully logged out</h4>
        <a href="/"  style="padding-bottom:20px;" target="_self">
          <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
            GoldiMocks™
          </button>
        </a>
      </div>
      <br>
      <div class="small-12 columns">
        <label>
          <div class="siloqlogofont" style="margin:auto;text-align:center;width:300px;color:dodgerblue;padding-top:5px;" >
            Thank you for visiting
          </div>
          <div
              style="margin:auto;text-align:center;width:300px;color:mediumseagreen;padding-top:5px;font-weight:200;font-size:1em;" class="siloqfont">
            You've securely signed off.
          </div>
        </label>
      </div>
    </div>
      <br>
      <div *ngIf="!userSVC.authUser.email" class="row expanded align-center" style="text-align: center; margin:0 auto;width:25%;">
          <br>
          <button *ngIf="!userSVC.authUser.email" (click)='signin()' class="siloqbutton" style="width:120px;">Sign In</button>
          <br>
        </div>

  </div>
</div>
<br>
<br>
<br>
<br>
<footer class="footer"></footer>
