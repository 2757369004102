<div style="width:100%;position: fixed; padding:0px; margin:0 auto; text-align:center;background-color:ffffff00" *ngIf="alert">
  <div  class="alert">
    <div style="width:100%; text-align:right;height:16px">
      <a class="siloqclose" (click)='alert = undefined;' title="Close">❎</a>
    </div>
    <div style="display:table; width:100%;">
      <div style="display:table-cell; width:15%;">&nbsp;
      </div>
      <div  style="padding:0px 8px 6px 8px; text-align:center;display:table-cell;">
        <a [routerLink]="['/publish']" [queryParams]="{h:taxonomy+'-we-belong-here'}" style="text-decoration:none;">
          <button  type="button"  class="siloqbutton">
            {{taxonomy}}™
          </button>
        </a>
      </div>
      <div style="display:table-cell; width:15%;">
      </div>
    </div>

    <div style="color:dodgerblue;padding:10px 5px 0px 0px; font-weight:500" [innerHTML]="alert.text"></div>
  </div>
</div>
