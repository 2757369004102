import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { Subscription } from 'rxjs';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, AdminHeaderComponent, CommonModule],
  standalone: true
})

export class BranchComponent implements OnInit {

  topics: Topic[];
  public model = new Topic('', '', '');
  public topic = new Topic('', '', '');
  public umodel = new Topic('', '', '');
  private branch = new Topic('', '', '');
  public selectedTopic;
  selectedIndex = 0;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
  public ready = false;

  constructor(private alertService: AdminAlertService, private modalService: ModalService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
    });

    this.setFirstPage();
    this.ready = true;
  }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(escape(unescape(h)), e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  public selectTopic( topic: any, i: number) {
    topic.rank = i + 1;
    this.selectedIndex = i;
    this.selectedTopic =  topic;
  }

 @HostListener('keydown', ['$event'])
 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        event.preventDefault();
        break;
      case 40:
        this.onDown()
        event.preventDefault();
        break;
      case 13:
        this.branchTopic();
        event.preventDefault();
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.topics.length - 1)
    {
      this.selectedTopic = this.topics[++this.selectedIndex];
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTopic = this.topics[--this.selectedIndex];
    }
  }

  loqTopic(t: any) {
    this.router.navigate(['/admin/loq'],  { queryParams: { h : t, i : this.topic.id}});
  }

  //pagination for table
  public setFirstPage() {
    this.getTopic(escape(unescape(this.model.hashTag)), this.theUser, (t: any) => {
      t.hashTag = unescape(t.hashTag);
      this.topic = t;
      this.topic.hashTag = unescape(this.topic.hashTag);
      this.showAlert(AlertTypeEnum['success'], "Start cloning into a new name whenever you're ready!");
    });
  }

  cloneTopic(t: Topic, callback: (t: any) => any){
    this.topicService.cloneTopic(t).subscribe(
      () => {
        callback({});
        this.model = new Topic('', '', '');
        this.model.hashTag = unescape(this.topic.hashTag);
        this.setFirstPage();
      },
       (err: any) => {
        console.log(err);
      });
  }

cloneTopicToUser(t: Topic, callback: (t: any) => any){
       this.topicService.cloneTopicToUser(t).subscribe(
        (data: any) => {
               callback(data);
             },
          (err: any) => {
           console.log(err);
         });
     }

  branchTopic(){
    this.ready = false;
      if (this.topic.hashTag === undefined) {
        return;
      }

      if (this.model.hashTag == "" || this.model.hashTag == this.topic.hashTag) {
        this.showAlert(AlertTypeEnum['warning'], "Please enter the new name to clone the original.");
        return;
      }

      this.openModal(this.modalTemplate, "md", "Clone: Original", "Want to clone?", () => {
            this.showAlert(AlertTypeEnum['info'], "Please hold on while we're cloning original.");
            document.getElementById("loading").style.display = "inline";
            this.topic.hashTag = escape(this.topic.hashTag);
            //TODO: Do Not Use topic.value
            this.topic.value = escape(this.model.hashTag);
            this.cloneTopic(this.topic, (t: any) => {
              document.getElementById("loading").style.display = "none";
           });
      });
    }

  branchTopicToUser(){
    this.ready = false;
    if (this.topic.hashTag === undefined) {
      return;
    }

    if (this.model.hashTag == "" || this.model.hashTag == this.topic.hashTag) {
      this.showAlert(AlertTypeEnum['warning'], "Please enter the new name to clone you.");
      return;
    }

  this.openModal(this.modalTemplate, "md", "Clone: To You", "Want to clone?", () => {
    this.showAlert(AlertTypeEnum['info'], "Please hold on while we're cloning to you.");
    document.getElementById("loading").style.display = "inline";
    this.topic.hashTag = escape(this.model.hashTag);
    this.topic.users = [this.theUser];
    this.topic.value = this.topic.email;
    this.topic.email = this.theUser;
     this.cloneTopicToUser(this.topic, (t: any) => {
        this.router.navigate(['/admin/loq'], { queryParams: { h : t.hashTag, i : t.id}});
        document.getElementById("loading").style.display = "none";
     });
   });
  }

  jsunescape(t: string) {
    return unescape(t)
  }
}
