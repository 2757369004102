import { Component, OnInit, NgZone } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TagService} from '../../services/tag-service.service';
import {TopicService} from '../../services/topic.service';
import {Tag} from '../tag/model/tag';
import {Topic} from '../topic/model/topic';
import {Taxon} from '../topic/model/topic';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import {DataService } from "../../services/data.service";
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { LoqFooterComponent } from '../loqFooter/loq-footer.component';
import { FormsModule } from '@angular/forms';
import {User} from '../user/model/user';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../tag/tag.truncate.pipe';
import { SafeHtmlPipe } from '../tag/tag.safehtml.pipe';

declare function escape(s:string): string;
declare function unescape(s:string): string;

declare function testJs(): any;

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.css'],
  imports: [FormsModule, RouterModule, LoqFooterComponent, AdminHeaderComponent, CommonModule, SafeHtmlPipe, TruncatePipe],
  standalone: true
})

export class RawComponent implements OnInit {

  tags: Tag[];
  topics: Topic[];
  public topic = new Topic('', '', '');
  utags: any;
  public model = new Tag('', '', '', '', '');
  public umodel = new Tag('', '', '', '', '');
  public selectedTag;
  public classTag = "";
  selectedIndex = 0;
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";

  constructor(private titleService: Title, private ngZone: NgZone, private tagService: TagService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService, private data: DataService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.topicId = unescape(params['i']) || "";
      this.topic.id = this.model.topicId;
    });

    this.getTopicById(this.topic.id, (t: any)=>{
      this.topic = Object.assign({}, t);
      if(this.topic.id) {
        this.titleService.setTitle(this.topic.hashTag);
        this.data.changeMessage(this.topic.value);
        this.setFirstPage();
      }
    });
  }

  loadTags(callback: () => any) {
    this.getTopicTags(callback);
  }

  getTopicTags(callback: () => any) {
  var t = Object.assign({}, this.topic);
//       var taxon = new Taxon();
//       t.taxonomy = [];
//       taxon.key = "currentPage";
//       taxon.value = ["1"];
//       t.taxonomy.push(taxon)
//       //
//       taxon = new Taxon();
//       taxon.key = "pageSize";
//       taxon.value = ["11"];
//       t.taxonomy.push(taxon)
//       //
      t.hashTag = escape(unescape(t.hashTag));
    this.topic.hashTag = escape(unescape(this.topic.hashTag));
    this.topicService.getTopicTags(t)
      .subscribe(
       (tags: any) => {
          this.tags = tags; //Bind to view
          if(this.tags !== undefined) {
            if(callback !== undefined) {
              callback();
            }

            for (let i = 0; i < this.tags.length; i++) {
              this.tags[i].hashTag = unescape(this.tags[i].hashTag);
              this.tags[i].name = unescape(this.tags[i].name);
              this.tags[i].value = unescape(this.tags[i].value);
            }

            this.setPageVisible();
          }
        },
         (err: any) => {
          console.log(err);
        });
  }


  getTopicById(id: string, callback: (t: any) => any) {
    this.topicService.getTopicById(id).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

   setSearchText() {
    this.data.changeMessage(this.selectedTag.hashTag.split(' ').slice(0,6).join(' ')  + " " + this.selectedTag.name.split(' ').slice(0,6).join(' ') + " " + this.selectedTag.value.split(' ').slice(0,6).join(' '));
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 38:
        this.onUp();
        break;
      case 40:
        this.onDown()
        break;
      case 13:
        break;
      case 9:
        if(event.shiftKey) {
          this.selectedTag.position = this.selectedTag.position - 1;
        } else {
          this.selectedTag.position = this.selectedTag.position + 1;
        }
        break;
    }
    return;
  }

  public onDown() {
    if(this.selectedIndex < this.tags.length - 1)
    {
      this.selectedTag = this.tags[++this.selectedIndex];
      //this.setSearchText();
    }
  }

  public onUp() {
    if(this.selectedIndex > 0)
    {
      this.selectedTag = this.tags[--this.selectedIndex];
      //this.setSearchText();
    }
  }

  refIndex(source: string): number {
    return this.utags.indexOf(source) + 1;
  }

  //pagination for table
  public setFirstPage() {
    this.loadTags(() => {
      if(this.tags.length > 0) {
        this.utags = Array.from(new Set(this.tags.map((item) => item.source)));
      }
    });
  }

  setPageVisible() {
    this.selectedTag = this.tags[this.selectedIndex];
    if (this.selectedTag) {
      this.selectedTag.rank = this.selectedIndex + 1;
    }
  }

renderImage(t: any) {
  var s = t;
  t = t.replace(/<(?:.|\n)*?>/gm, '');
   if (t.startsWith("http://") || t.startsWith("https://")) {
       const img = new Image();
       img.src = t;
       var w = 300;
       var h = (w/img.width)*img.height;
       t = '<img src="' + t + '" style="width:' + w + 'px; height:' + h + 'px;" />'
       return t;
    }
       return s;
  }

  docTopic(topic: any) {
      this.router.navigate(['/admin/loq'],  { queryParams: { h : topic.hashTag, i : topic.id}});
    }

    profileEx(h: any, d: any) {
        this.router.navigate(['profile'],  { queryParams: { h : h, d : window.btoa(d)}});
        return;
      }

      stringify(s: any) {
        return JSON.stringify(s);
      }
}


