<admin-header></admin-header>
<body style="height:100%;width:100%;">
 <div style="margin:80px auto;">
   <div class="container2" style="margin:20px auto; auto;width:276px; padding: 20px;">
   <div class="small-12 columns">
     <h3 class="block_container siloqlogofont" style="text-align:center;margin: auto;color:#617CB5;min-width:256px;width:30%;">Thank you</h3>
     <br>
     <a href="/" target="_self">
       <button  type="button" class="siloqbutton" style="background-color:orangered;color:whitesmoke;border:3px solid gold;padding: 6px 8px 6px 8px;font-size:16px;font-weight:600; border-radius: 8px; -moz-border-radius: 8px;">
         GoldiMocks™
       </button>
     </a>
   </div>
 </div>
   <div style="min-width:256px;text-align:center;margin:0 auto;padding:0px;" class="siloqfont">
     <a  [style.backgroundColor]="backgroundColor"  (mouseover)="backgroundColor = '#789885'" (mouseout)="backgroundColor = 'mediumseagreen'"  style="font-family:Helvetica,AppleGothic,sans-serif; width:100px;background-color:mediumseagreen;color:whitesmoke;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;"(click)='done()' class="siloqbutton">OK</a>
   </div>
 </div>
 <admin-footer></admin-footer>

